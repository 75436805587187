import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

// components
import { IconButton, iconBtnType } from '../../../../../../packages/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '../../../../../../packages/ui/atoms/badge';
import { Heading, Text } from '../../../../../../packages/ui/atoms/typography';
import { ProfileInfo } from '../../../../../../packages/ui/templates/profile-info';

import { profileActionCreators } from '../../../../../../redux/modules/profile';
import { priorityActionCreators } from '../../../../../../redux/modules/priority';
import { getPriority } from '../../../../../../redux/modules/priority/selectors';

import { AddDiagnosis } from '../add-diagnosis';

// styles
import { useStyles } from './ReviewDiagnoses.styles';

const ReviewDiagnoses = ({ appointment, relatedElements, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDomainElement, setSelectedDomainElement] = useState();
  const [openAddDiagnosis, setOpenAddDiagnosis] = useState(false);

  const {
    typesPayload: { domainTypes },
    elementsPayload: { domainElements },
  } = useSelector(getPriority);

  const domainType = useMemo(() => domainTypes?.find(type => type.name === 'Diagnoses'), [domainTypes]);
  const domainTypeId = domainType?.Id;

  const onClickAdd = () => {
    setSelectedDomainElement(null);
    setOpenAddDiagnosis(true);
  };

  const onClickUpdate = item => {
    setSelectedDomainElement(item);
    setOpenAddDiagnosis(true);
  };

  const onSubmit = payload => {
    const data = {
      patientId: appointment.participantId,
      tagItems: [
        {
          assignedBy: appointment.participantName,
          domainElementId: payload.diagnosis,
          selectedIct10code: payload.icd10Code,
          metaData: {
            notes: payload.additionalNotes,
            selectedImportance: payload.status,
          },
        },
      ],
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: errorMsg => {
          if (!errorMsg) {
            setOpenAddDiagnosis(false);
          }
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(priorityActionCreators.fetchPriorityDomainTypes());
  }, []);

  useEffect(() => {
    dispatch(priorityActionCreators.fetchDomainElementsByTypeId(domainTypeId));
  }, [domainTypeId]);

  const renderCard = item => (
    <Box key={item.id} className={classes.card}>
      <Box className={classes.topSection}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Box className={classes.topContentWrapper}>
            <Heading className={classes.title}>{item.name}</Heading>
            <Badge style={badgeStyle.HIGH} variant={badgeType.FILLED} className={classes.badge}>
              {item.priority.name}
            </Badge>
          </Box>
          <Text className={classes.subTitle}>{item.description}</Text>
        </Box>
        <IconButton
          variant={iconBtnType.SECONDARY}
          className={classes.action}
          onClick={() => onClickUpdate(item)}
        >
          Update
        </IconButton>
      </Box>
      <Box className={classes.bottomSection}>
        <ProfileInfo
          type="member"
          photo={item.assignedBy}
          nickName={item.assignedBy}
          fullName={dayjs(item.assignedAt).format('MMMM D, YYYY')}
          isProvider
        />
        <Text className={classes.description}>{item.notes}</Text>
      </Box>
    </Box>
  );

  return (
    <>
      {openAddDiagnosis ? (
        <AddDiagnosis
          selectedDomainElement={selectedDomainElement}
          domainElements={domainElements}
          onSubmit={onSubmit}
          onClose={() => setOpenAddDiagnosis(false)}
        />
      ) : (
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Box>
              <Text className={classes.cardTitle}>Review diagnoses</Text>
              <Text className={classes.cardSubTitle}>{relatedElements?.length} diagnoses total</Text>
            </Box>
            <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
          </Box>
          <Box className={classes.subHeader}>
            <ProfileInfo
              type="member"
              photo={appointment.participantImage}
              nickName={appointment.participantName}
              fullName={`${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`}
              isProvider
            />
            <IconButton
              className={classes.addBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onClickAdd}
            >
              Add diagnosis
            </IconButton>
          </Box>
          <Box className={classes.formContent}>{relatedElements?.map(item => renderCard(item))}</Box>
        </Box>
      )}
    </>
  );
};

export { ReviewDiagnoses };
