import React, { FC, useEffect, useRef, useState } from 'react';
import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../../../packages/ui/atoms/typography';
import { Box } from '@mui/material';
import { btnType, Button } from '../../../../packages/ui/atoms/button';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { Menu } from '../../../../packages/ui/molecules/menu';
import cx from 'clsx';
import { useStyles } from './InPersonSession.styles';
import { CollapsedComponent } from './CollapsedComponent';
import { CbStatus } from '../../../../redux/modules/conversation/types';
import useEvaluationHead from '../../../../hooks/useEvaluationHead';
import { Question } from './Question';

export const InProgressEvaluation = ({
  showAllEvaluations,
  listHelper,
  currentBlock,
  setCurrentBlock,
  activeBox,
  setActiveBox,
  currentEvaluation,
}) => {
  const classes = useStyles();
  const [checkedStates, setCheckedStates] = useState(Array(5).fill(false));
  // const [dcts, setDcts] = useState<EvaluationItemNode[]>([]);
  const [questions, setQuestions] = useState([]);
  const scrollerRef = useRef(null);
  const { head } = useEvaluationHead();

  useEffect(() => {
    if (head !== null && currentBlock) {
      listHelper.setHead(head);
      // setDcts(listHelper.getDcts());
      setQuestions(listHelper.getQuestions());
    }
  }, [currentBlock, head, currentEvaluation]);

  const totalQuestions = questions.reduce((acc, dct) => {
    return acc + dct.children.length;
  }, 0);
  const answeredQuestions = questions.reduce((acc, dct) => {
    return acc + dct.children.filter(child => child.status === CbStatus.ANSWERED).length;
  }, 0);

  const handleCheckboxChange = index => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);
  };

  return (
    <Box
      className={cx({
        [classes.questionBoxRoot]: activeBox,
        [classes.questionBoxRootInactive]: !activeBox,
      })}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Box className={classes.questionBoxHeader}>
          <Box
            className={cx({
              [classes.toggleIconWrapper]: !activeBox,
            })}
          >
            <Button
              variant={btnType.TEXT}
              className={classes.sideBarToggler}
              onClick={() => {
                setActiveBox(!activeBox);
              }}
            >
              <Icons
                className={cx({
                  [classes.togglerIcon]: activeBox,
                  [classes.togglerIconRight]: !activeBox,
                })}
                glyph="caret-left"
                color={colors.neutral700}
              />
            </Button>
            {!activeBox && (
              <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
                {answeredQuestions}/{totalQuestions}
              </Text>
            )}
          </Box>
          <Box className={activeBox ? classes.titleContainer : classes.hiddenContainer}>
            <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.heading}>
              Questions
            </Heading>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
              {totalQuestions} total
            </Text>
          </Box>
          {activeBox && (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              disabled={false}
              items={[
                {
                  label: 'All evaluations',
                  icon: <Icons glyph="arrow-left" className={cx(classes.icon, classes.pauseIcon)} />,
                  onClick: () => {
                    showAllEvaluations();
                  },
                },
              ]}
            />
          )}
        </Box>
        <Box
          className={cx({
            [classes.scroll]: activeBox,
            [classes.inActiveBoxScroll]: !activeBox,
            [classes.collapsedFlex]: !activeBox,
          })}
          ref={scrollerRef}
        >
          {questions
            // .filter(item => item.text !== '')
            .map((item, index) => {
              return item.cbType === 'dct' ? (
                <CollapsedComponent
                  listHelper={listHelper}
                  index={index}
                  handleChange={handleCheckboxChange}
                  key={item.cbId}
                  item={item}
                  parentExpanded={activeBox}
                  scrollRef={scrollerRef}
                  currentBlock={currentBlock}
                  setCurrentBlock={setCurrentBlock}
                />
              ) : (
                <Question
                  item={item}
                  parentExpanded={activeBox}
                  currentBlock={currentBlock}
                  scrollRef={scrollerRef}
                  setCurrentBlock={setCurrentBlock}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};
