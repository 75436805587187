import { useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { Avatar, avatarType, btnType, Button, fontWeight, Icons, Text, textLevel } from '../../../packages';
import Loader, { LoaderEnums } from '../../../packages/ui/templates/loader';
import { isUserOnline } from '../../../utils/CommonUtils';
import avatarIcon from '../../../assets/images/avatar.png';
import groupAvatarIcon from '../../../assets/images/group-avatar.png';
import history from '../../../utils/history';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { useSelector } from 'react-redux';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { CONNECTIONS_TYPES } from '../../../constants/CommonConstants';
import { useStyles } from './Chats.styles';
import { ChatsMenu } from './ChatsMenu';

const ChatHeader = ({
  getConnectionDetailsByUserId,
  selectedContact,
  isLoading,
  chatType,
  onClickProfile,
  setOpenCareTeamDrawer,
  careTeamDetail,
  getSelectedContactFullName,
  isAdmin,
  getProfilePictureFromChannel,
  onlineUsers,
  demographicDetails,
  hideDOB,
  hideVisitFrequency,
  hideNextAppointment,
  nextAppointment,
  params,
  getDesignation,
  setShowCareTeamJoinCall,
  channelUrl,
  setOpenInstantAppointmentDrawer,
  setIsOpenAddNote,
  providerAvailability,
  setOpenVisitFrequencyDrawer,
  setIsCreateConnection,
}) => {
  const classes = useStyles({});
  const { meta } = useSelector(getAuth);
  const { providers } = useSelector(getProfile);
  const [localTime, setLocalTime] = useState(dayjs().format('hh:mma'));
  const matchingProvider = providers?.find(provider => selectedContact?.connectionId === provider?.id);

  useEffect(() => {
    const interval = setInterval(() => {
      setLocalTime(dayjs().format('hh:mma'));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const renderPatientHeader = () => {
    return (
      <>
        <div className={classes.headContainer}>
          {/* {!hideDOB && ( */}
          <div className={`${classes.divPadding} ${classes.colLabel2} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Date of Birth
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {demographicDetails?.dateOfBirth !== null && demographicDetails?.dateOfBirth !== ''
                ? dayjs(demographicDetails?.dateOfBirth).format('MM/DD/YYYY')
                : '-'}
            </Text>
          </div>
          {/* )} */}

          {/* {!hideVisitFrequency && ( */}
          <div className={`${classes.divPadding} ${classes.colLabel2} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Phone Number
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {demographicDetails?.mobilePhone || '-'}
            </Text>
          </div>
          {/* )} */}

          {/* {!hideNextAppointment && !isAdmin && ( */}
          <div className={`${classes.colLabel2} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Next Appointment
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {nextAppointment ? dayjs(nextAppointment.startTime).format('MM/DD hh:mma') : 'Not Scheduled'}
            </Text>
          </div>
          {/* )} */}

          <div>
            <ChatsMenu
              // onClose={() => setOpen(false)}
              onOpenInstantAppointment={() => setOpenInstantAppointmentDrawer(true)}
              onOpenNotes={() => setIsOpenAddNote(true)}
              openProfile={onClickProfile}
              openVisitFrequencyDrawer={() => setOpenVisitFrequencyDrawer(true)}
              openIsCreateConnection={() => setIsCreateConnection(true)}
              isAdmin={isAdmin}
            />
          </div>
        </div>
      </>
    );
  };

  const renderProviderHeader = () => {
    const start = matchingProvider?.schedule?.slots ? matchingProvider?.schedule?.slots?.[0]?.start : null;
    return (
      <>
        <div className={classes.headContainer}>
          <div className={`${classes.divPadding} ${classes.colLabel2} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Availability
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {providerAvailability.noOfDays > 0
                ? `${providerAvailability.noOfDays} ${providerAvailability.noOfDays === 1 ? 'day' : 'days'}`
                : '-'}
            </Text>
          </div>
          <div className={`${classes.divPadding} ${classes.colLabel2} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Next available
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {dayjs(providerAvailability.nextAvailability).isValid()
                ? dayjs(providerAvailability.nextAvailability).format('MM/DD – h:mma')
                : '-'}
            </Text>
          </div>
          <div className={`${classes.divPadding} ${classes.colLabel3} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Phone Number
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {selectedContact?.phone ?? '-'}
            </Text>
          </div>
        </div>
      </>
    );
  };

  const renderCareNavigatorHeader = () => {
    return (
      <>
        <div className={classes.headContainer}>
          <div className={`${classes.divPadding} ${classes.colLabel2} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Availability
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              7 days Sun-Sat
            </Text>
          </div>
          <div className={`${classes.divPadding} ${classes.colLabel2} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Time of day
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              Workday EST
            </Text>
          </div>
          <div className={`${classes.divPadding} ${classes.colLabel3} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Local time
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {localTime}
            </Text>
          </div>
        </div>
      </>
    );
  };

  const renderGroupHeader = () => {
    return (
      <>
        <div className={classes.headContainer}>
          <div className={`${classes.divPadding} ${classes.colLabel3} userNameWrapper`}>
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} className={classes.colLabel}>
              Membership
            </Text>
            <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S} color="#0D0F11">
              {careTeamDetail?.length ?? 0} {careTeamDetail?.length > 1 ? 'members' : 'member'}
            </Text>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {selectedContact && (
        <div className={classes.header}>
          {isLoading ? (
            <Loader type={LoaderEnums.CHAT_HEADER} />
          ) : (
            <>
              <div
                className={`
            ${chatType === 'members' ? 'headerLeftSidePointer' : 'headerLeftSide'}`}
                // onClick={onClickProfile}
              >
                <Box display="flex" gap="12px">
                  <Box>
                    {getProfilePictureFromChannel(params.get('userId')) ||
                    getConnectionDetailsByUserId(selectedContact?.id)?.profilePicture ? (
                      <Avatar
                        variant={avatarType.CIRCLE}
                        size={40}
                        src={
                          getProfilePictureFromChannel(params.get('userId')) ||
                          getConnectionDetailsByUserId(selectedContact?.id)?.profilePicture
                        }
                        name={getSelectedContactFullName()}
                      />
                    ) : (
                      <Box maxWidth={40} maxHeight={40} width={40} height={40}>
                        <img
                          src={
                            selectedContact?.type !== CONNECTIONS_TYPES.CHAT_GROUP
                              ? avatarIcon
                              : groupAvatarIcon
                          }
                          alt="avatar empty img"
                          width="40px"
                          height="40px"
                        />
                      </Box>
                    )}
                    {selectedContact?.type !== CONNECTIONS_TYPES.CHAT_GROUP && (
                      <Box
                        className={classes.headerOnlinePresenceIndicator}
                        style={{
                          backgroundColor: isUserOnline(onlineUsers, selectedContact?.connectionId)
                            ? '#51A365'
                            : '#7d7d7d',
                        }}
                      />
                    )}
                  </Box>
                  <Box>
                    <Box className={classes.headerNameText}>
                      <span>{getSelectedContactFullName()}</span>
                      {(selectedContact?.type === 'PATIENT' || selectedContact?.type === 'CARE_TEAM') && (
                        <span>{`• ${selectedContact.uuid}`}</span>
                      )}
                    </Box>
                    <Box>{getDesignation(selectedContact?.type)}</Box>
                  </Box>
                </Box>
                {Object.keys(demographicDetails).length !== 0 &&
                  (selectedContact?.type === CONNECTIONS_TYPES.CARE_TEAM ||
                    selectedContact?.type === CONNECTIONS_TYPES.PATIENT) &&
                  renderPatientHeader()}
                {selectedContact?.type === CONNECTIONS_TYPES.CHAT_GROUP && renderGroupHeader()}
                {selectedContact?.type === CONNECTIONS_TYPES.PRACTITIONER && renderProviderHeader()}
                {selectedContact?.type === CONNECTIONS_TYPES.CARE_NAVIGATOR && renderCareNavigatorHeader()}
              </div>
            </>
          )}
        </div>
      )}
      {selectedContact && (
        <Box className={classes.careTeambar}>
          <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            {selectedContact?.type !== CONNECTIONS_TYPES.CARE_NAVIGATOR && (
              <Tooltip title="Profile">
                <Box className={classes.iconBtn}>
                  <Button
                    variant={btnType.OUTLINE}
                    className={classes.headerBtnUser}
                    onClick={onClickProfile}
                  >
                    <Icons color="#0374DD" className={classes.secondaryHeaderIcon} glyph="user" />
                  </Button>
                </Box>
              </Tooltip>
            )}
            {selectedContact?.type !== CONNECTIONS_TYPES.CARE_NAVIGATOR &&
              selectedContact?.type !== CONNECTIONS_TYPES.CHAT_GROUP && (
                <Tooltip title="Schedule appointment">
                  <Box className={classes.iconBtn}>
                    <Button
                      variant={btnType.OUTLINE}
                      className={classes.headerBtnCalendar}
                      onClick={() => setOpenInstantAppointmentDrawer(true)}
                    >
                      <Icons
                        color="#DD0374"
                        className={classes.secondaryHeaderIcon}
                        glyph="calendar-primary-outlined-square"
                      />
                    </Button>
                  </Box>
                </Tooltip>
              )}

            {selectedContact?.type !== CONNECTIONS_TYPES.CARE_NAVIGATOR &&
              selectedContact?.type !== CONNECTIONS_TYPES.CHAT_GROUP && (
                <Tooltip title="Create case notes">
                  <Box className={classes.iconBtn}>
                    <Button
                      variant={btnType.OUTLINE}
                      className={classes.headerBtnCalendarChecked}
                      onClick={() => setIsOpenAddNote(true)}
                    >
                      <Icons
                        color="#16B364"
                        className={classes.secondaryHeaderIcon}
                        glyph="calendar-checked-outlined"
                      />
                    </Button>
                  </Box>
                </Tooltip>
              )}
            {/* {selectedContact?.type !== CONNECTIONS_TYPES.CHAT_GROUP && (
              <Box className={classes.iconBtn}>
                <Button variant={btnType.OUTLINE} className={classes.headerBtnPhone}>
                  <Icons color="#7A5AF8" className={classes.secondaryHeaderIcon} glyph="phone-outlined" />
                </Button>
              </Box>
            )} */}
            {meta.authority !== 'BILLING_SUPPORT' && meta.authority !== 'ADMIN' && (
              <Tooltip title="Video session">
                <Box className={classes.iconBtn}>
                  <Button
                    variant={btnType.OUTLINE}
                    className={classes.headerBtnCamera}
                    onClick={() => {
                      history.push(`
                    /${isAdmin ? 'admin' : 'provider'}/chats/video/${channelUrl}?isDirectCall=true
                    `);
                    }}
                  >
                    <Icons color="#06AED4" className={classes.secondaryHeaderIcon} glyph="camera-outlined" />
                  </Button>
                </Box>
              </Tooltip>
            )}
            {selectedContact?.type === CONNECTIONS_TYPES.CARE_TEAM && (
              <Tooltip title="Care team">
                <Box className={classes.iconBtn}>
                  <Button
                    className={classes.headerBtnMultipleUsers}
                    onClick={() => {
                      setShowCareTeamJoinCall(false);
                      setOpenCareTeamDrawer(true);
                    }}
                    variant={btnType.OUTLINE}
                  >
                    <Icons color="#F59E0B" className={classes.secondaryHeaderIcon} glyph="multiple-users" />
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
export default ChatHeader;
