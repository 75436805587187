import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Avatar, avatarType, fontWeight, Icons, Text, textLevel } from '../../../packages';
import { Box, Button, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { getConversation } from '../../../redux/modules/conversation/selectors';
import { formatTimeMessage, isUserOnline } from '../../../utils/CommonUtils';
import { getSocketState } from '../../../redux/modules/socket/selectors';
import { CONNECTIONS_TYPES } from '../../../constants/CommonConstants';
import avatarIcon from '../../../assets/images/avatar.png';
import { useStyles } from './Chats.styles';
import { ChatThreadMenu } from './ChatThreadMenu';
import { TodoDialog } from './TodoDialog';
import { convertToLinks, highlightMentionedUsers } from './ChatUtils';

const NewUserChatCard = ({
  nickName,
  createdDate,
  message,
  user,
  thumbnail,
  fileMeta,
  colorCode,
  messageId,
  mentionedUsers,
  recentMessageSend,
  selectedContact,
  setMessageThread,
  senderId,
  currentUserId,
  inputRef2,
  prevMsg,
  scrollMessageId,
  getDesignation,
  careTeamDetail,
}) => {
  const classes = useStyles({});
  const cardRef = useRef({});
  const location = useLocation();

  const { onlineUsers } = useSelector(getSocketState);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [highlighted, setHighlighted] = useState(false);
  const [scrolledMessageId, setScrolledMessageId] = useState(null);
  const { allTodos } = useSelector(getConversation);

  useEffect(() => {
    const messageIdFromUrl = window.location.hash.substring(1);
    if (scrollMessageId) {
      setScrolledMessageId(scrollMessageId);
    } else {
      setScrolledMessageId(messageIdFromUrl);
    }
  }, []);

  useEffect(() => {
    if (cardRef.current && scrolledMessageId) {
      const mId = Number(scrolledMessageId);
      if (cardRef.current[mId]) {
        setTimeout(() => {
          cardRef.current[mId].scrollIntoView({ behavior: 'smooth', block: 'center' });
          setTimeout(() => {
            setHighlighted(true);
            setTimeout(() => {
              setHighlighted(false);
            }, 2000);
          }, 200);
        }, 2000);
      }
    }
  }, [scrolledMessageId]);

  /* useEffect(() => {
    if (cardRef.current) {

      if (!!scrolledMessageId && Number(scrolledMessageId) === messageId) {
        setTimeout(() => {
          cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setTimeout(() => {
            setHighlighted(true);
            setTimeout(() => {
              setHighlighted(false);
            }, 2000);
          }, 200);
        }, 2000);
      }
    }
  }, [cardRef.current]); */

  // const handleCopyUrl = mId => {
  //   const currentUrl = window.location.href.split('#')[0];
  //   const newUrl = `${currentUrl}#${mId}`;
  //   navigator.clipboard
  //     .writeText(newUrl)
  //     .then(() => {
  //       console.log('URL with Message ID copied to clipboard!');
  //     })
  //     .catch(err => {
  //       console.error('Error copying URL: ', err);
  //     });
  // };

  const todoDate = id => {
    const matchedToDo = allTodos?.find(item => Number(item?.contentIdentifier) === id);
    if (matchedToDo?.dueDate !== undefined && matchedToDo?.dueDate !== null) {
      const matchedTodoDate = dayjs(matchedToDo?.dueDate).format('YYYY-MM-DD');
      const currentDate = dayjs().format('YYYY-MM-DD');
      const tomorrowDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
      if (currentDate === matchedTodoDate) {
        return ' - Today';
      } else if (tomorrowDate === matchedTodoDate) {
        return ' - Tomorrow';
      } else if (dayjs(matchedToDo?.dueDate).isBefore(currentDate)) {
        return ' - Past due';
      } else {
        return ` - ${dayjs(matchedToDo?.dueDate).format('MM-DD-YYYY')}`;
      }
    }
    return '';
  };

  const getTeamDesignation = userId => {
    if (careTeamDetail.length === 0) return getDesignation(selectedContact?.role);
    const filteredMember = careTeamDetail.filter(member => member.userId === userId);
    if (filteredMember[0]?.metaData?.type) {
      return getDesignation(filteredMember[0]?.metaData?.type);
    }
    if (filteredMember[0]?.designation === 'Care Navigator') {
      return getDesignation(CONNECTIONS_TYPES.CARE_NAVIGATOR);
    }
    return getDesignation('PATIENT');
  };

  const renderAvatar = (isLeft = false) => {
    return (
      <Box className={isLeft ? classes.leftAvt : ''}>
        <>
          {thumbnail ? (
            <Avatar
              variant={avatarType.CIRCLE}
              size={40}
              src={thumbnail}
              name={
                nickName === 'Super Admin' || nickName === 'Confidant Admin' ? 'Confidant Admin' : nickName
              }
              className={classes.avt}
              color={colorCode}
            />
          ) : (
            <Box maxWidth={40} maxHeight={40} width={40} height={40}>
              <img src={avatarIcon} alt="avatar empty img" width="40px" height="40px" />
            </Box>
          )}
        </>
        <Box
          className={classes.onlinePresenceIndicator}
          style={{
            backgroundColor: isUserOnline(onlineUsers, senderId) ? '#51A365' : '#7d7d7d',
            color: isUserOnline(onlineUsers, senderId) ? '#51A365' : '#7d7d7d',
            marginTop: isLeft ? 29 : -12,
            marginRight: isLeft ? -3 : 0,
          }}
        />
      </Box>
    );
  };

  const renderMenu = () => {
    return (
      <>
        {(location.pathname === '/provider/chats' || location.pathname === '/admin/chats') && (
          <>
            {messageId === hoveredIndex && (
              <div className={classes.leftChatMessageButtonConatiner}>
                <Tooltip title="Reply" disableInteractive>
                  <div>
                    <Button
                      variant="outlined"
                      className={classes.replyButton}
                      onClick={() => {
                        inputRef2?.current?.focus();
                        setMessageThread({
                          messageId,
                          message: message?.text,
                          nickName,
                          fileMeta,
                          colorCode,
                          userId: user?.userId,
                          name: user?.name,
                          avatar: user?.avatar,
                          thumbnail,
                          createdDate,
                        });
                      }}
                    >
                      <Icons glyph="reply-thread" color="#8EC8FD" className={classes.menuIcons} />
                    </Button>
                  </div>
                </Tooltip>
                {/* <Tooltip title="Mention">
                  <div>
                    <Button
                      variant="outlined"
                      className={classes.replyButton}
                      onClick={() => {
                        handleCopyUrl(messageId);
                      }}
                    >
                      <Icons glyph="mentions-icon" color="#0374DD" className={classes.menuIcons} />
                    </Button>
                  </div>
                </Tooltip> */}
                {mentionedUsers !== undefined && (
                  <>
                    {mentionedUsers?.length !== 0 && senderId === currentUserId && (
                      <ChatThreadMenu id={messageId} />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const renderMessageReply = () => {
    const isConfidantAdmin =
      prevMsg?.[0]?.nickName === 'Super Admin' || prevMsg?.[0]?.nickName === 'Confidant Admin';

    if (!['/provider/chats', '/admin/chats'].includes(location.pathname)) return null;
    if (!prevMsg?.length || !Object.keys(prevMsg[0])?.length) return null;

    return (
      <div key={messageId} className={classes.newReplyMessageWrapper}>
        <div className="userChatContentWrapper">
          <div className="newUserChatMessageWrapper">
            <div className="newUserChatNameWrapper">
              <Icons glyph="mentions-icon" color="#0374DD" className={classes.menuIcons} />
              {!!prevMsg[0]?.nickName && (
                <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                  Reply to {isConfidantAdmin ? 'Confidant Admin' : prevMsg[0]?.nickName}
                </Text>
              )}
            </div>

            <Text
              sx={{ marginLeft: '1.5rem' }}
              className={classes.messageText}
              weight={fontWeight.MEDIUM}
              level={textLevel.S}
            >
              {selectedContact?.type === 'PATIENT'
                ? convertToLinks(prevMsg[0]?.message || '')
                : highlightMentionedUsers(prevMsg[0]?.message, prevMsg[0]?.mentionedUsers)}
            </Text>

            {prevMsg[0]?.fileMeta &&
              (prevMsg[0]?.fileMeta?.type?.startsWith('image') ? (
                <img src={prevMsg[0]?.fileMeta.url} alt="confidant health" height={200} width={350} />
              ) : (
                <video controls src={prevMsg[0]?.fileMeta.url} height={200} width={350} />
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      key={messageId}
      ref={el => {
        cardRef.current[messageId] = el;
      }}
      id={messageId.toString()}
      className={clsx(classes.newUserChatCard, {
        [classes.highlighted]: highlighted,
      })}
      style={{ justifyContent: currentUserId !== user.userId ? 'start' : 'end' }}
      onMouseEnter={() => setHoveredIndex(messageId)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <div className="userChatRow">
        <div>{currentUserId !== user.userId ? <>{renderAvatar(true)}</> : <>{renderMenu()}</>}</div>
        <div className="userChatContentWrapper">
          <div className="newUserChatMessageWrapper">
            <div className="newUserChatNameWrapper">
              <div>
                {!!nickName && (
                  <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                    {nickName === 'Super Admin' || nickName === 'Confidant Admin'
                      ? 'Confidant Admin'
                      : nickName}
                  </Text>
                )}
                <Text className="time" weight={fontWeight.SEMI_BOLD} level={textLevel.XS}>
                  {formatTimeMessage(createdDate)}
                </Text>
              </div>
              <Text weight={fontWeight.SEMI_BOLD} level={textLevel.XS}>
                {currentUserId === user.userId
                  ? getDesignation('currentUser')
                  : getTeamDesignation(user.userId)}
              </Text>
            </div>
            <Text className={classes.messageText} weight={fontWeight.MEDIUM} level={textLevel.S}>
              {location.pathname === '/provider/chats' || location.pathname === '/admin/chats'
                ? highlightMentionedUsers(message?.text, mentionedUsers)
                : convertToLinks(message?.text || '')}
              {location.pathname === '/provider/chats' || location.pathname === '/admin/chats' ? (
                <span className={classes.todoDates}>{todoDate(messageId)}</span>
              ) : (
                ''
              )}
            </Text>
            {fileMeta && (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                {fileMeta?.type?.split('/')[0] === 'image' ? (
                  <img
                    src={fileMeta.url}
                    alt="confidant health"
                    className={classes.fileImage}
                    onClick={() => window.open(fileMeta.url, '_blank')}
                  />
                ) : (
                  <video controls src={fileMeta.url} height={200} width={350} />
                )}
              </Box>
            )}
          </div>
        </div>
        <div>{currentUserId === user.userId ? <>{renderAvatar()}</> : <>{renderMenu()}</>}</div>
      </div>
      {renderMessageReply()}

      {recentMessageSend?.includes(messageId) && (
        <Box mt={2}>
          <TodoDialog type="Parent" msg={message.text} id={messageId} currentUserId={currentUserId} />
        </Box>
      )}
    </div>
  );
};

export default NewUserChatCard;
