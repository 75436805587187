const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'staging';

const getConfig = () => {
  switch (ENV) {
    case 'prod':
      return {
        api: {
          elkUrl: 'https://ch-prod.es.us-east-2.aws.elastic-cloud.com/prod_',
          baseUrl: 'https://web.confidanthealth.com/api/rest',
          socketClientUrl: 'wss://prod2-chat.confidantdemos.com/',
          oneSignalAppId: '6b0fc72c-d9a7-41bc-8342-56a25e05b24c',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '437840869953-ae48s7f0nekp7ln4pdrd5ft1m947p99b.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://member.confidanthealth.com/',
        sendbird: {
          appId: '66F019F3-8A15-4988-AD9F-49376CFB9CEF', // Prod Sendbird App ID
        },
        opentok: {
          apiKey: '46695512',
        },
      };
    case 'staging':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/qa_',
          baseUrl: 'https://staging.confidantdemos.com/rest',
          socketClientUrl: 'wss://staging-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '165839181374-u4q36ps1a1prfda7fku8u60uepj5tvqo.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://staging.member.confidanthealth.com/',
        sendbird: {
          appId: '',
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'qa':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/qa_',
          baseUrl: 'https://qa.confidantdemos.com/rest',
          socketClientUrl: 'wss://qa-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '165839181374-v6ubh1t138cptthemoe12l31u663g6eq.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://member-m.qa.confidanthealth.com/',
        sendbird: {
          appId: '5107E113-620A-4449-BB78-81A731FEB602', // QA sendbird App Id
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'sit2':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/qa_',
          baseUrl: 'https://sit2.confidantdemos.com/rest',
          socketClientUrl: 'wss://sit2-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '165839181374-v6ubh1t138cptthemoe12l31u663g6eq.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://sit2.member.confidanthealth.com/',
        sendbird: {
          appId: '', // Isn't being used
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'local':
    case 'development':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/sit1',
          baseUrl: 'https://sit1.confidantdemos.com/rest', // 'https://sit1.confidantdemos.com/rest',
          socketClientUrl: 'ws://sit1.confidantdemos.com:8086/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          GC_WEBAPP_AUTH_ID: '165839181374-v6ubh1t138cptthemoe12l31u663g6eq.apps.googleusercontent.com',
          timeout: 60000,
        },
        memberAppUrl: 'https://sit1.member.confidanthealth.com/',
        sendbird: {
          appId: '41FCE9D5-9B0D-43F2-9B00-46D7F5F7C99C', // SIT1 Sendbird App ID
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'sit1':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/sit1',
          baseUrl: 'https://sit1.confidantdemos.com/rest', // 'https://sit1.confidantdemos.com/rest',
          socketClientUrl: 'wss://sit1-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          GC_WEBAPP_AUTH_ID: '165839181374-v6ubh1t138cptthemoe12l31u663g6eq.apps.googleusercontent.com',
          timeout: 60000,
        },
        memberAppUrl: 'https://member-m.sit1.confidanthealth.com/',
        sendbird: {
          appId: '41FCE9D5-9B0D-43F2-9B00-46D7F5F7C99C', // SIT1 Sendbird App ID
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    default:
      throw Error(`Unconfigured environment '${ENV}'. Can be configured in src/config.js.`);
  }
};

export default getConfig();
