import React, { FC, useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import cx from 'clsx';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { Text } from '../../../../packages/ui/atoms/typography';
import { useStyles } from './InPersonSession.styles';
import { Question } from './Question';

export const CollapsedComponent = ({ item, parentExpanded, currentBlock, setCurrentBlock, scrollRef }) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setCurrentBlock(item);
  };

  useEffect(() => {
    if (currentBlock) {
      const found = item.children.find(node => node.cbId === currentBlock.cbId);
      if (found) {
        setCollapsed(!!found);
      }
    }
  }, [currentBlock]);

  return (
    <Box
      className={cx({
        [classes.selectedQuestion]: currentBlock?.cbId === item.cbId && parentExpanded,
      })}
    >
      {parentExpanded && (
        <Box
          onClick={item?.isAttemptable ? toggleCollapsed : null}
          className={cx(classes.collapseHeaderBox, !item?.isAttemptable && classes.noPointer)}
        >
          {item.isAttemptable && (
            <Icons
              glyph="chevron-down"
              color={colors.neutral500}
              className={collapsed && classes.invertIcon}
            />
          )}
          <Text className={cx(classes.dctItem, !item.isAttemptable && classes.margin35)}>{item.text}</Text>
          {!item.isAttemptable && (
            <Icons glyph="horizontal-line" color={colors.destructive600} className={classes.circleIcon} />
          )}
        </Box>
      )}
      {item.cbType === 'dct' &&
        item.children.map((childItem, childIndex) => (
          <Collapse in={!item?.isAttemptable ? false : collapsed || !parentExpanded} key={childIndex}>
            <Question
              parentExpanded={parentExpanded}
              item={childItem}
              currentBlock={currentBlock}
              setCurrentBlock={setCurrentBlock}
              scrollRef={scrollRef}
            />
          </Collapse>
        ))}
    </Box>
  );
};
