import { channel as sagaChannel, eventChannel } from 'redux-saga';
import { call, cancel, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GroupChannelFilter,
  GroupChannelListOrder,
  GroupChannelModule,
  UnreadChannelFilter,
} from '@sendbird/chat/groupChannel';
import Sendbird from 'sendbird';
import SendbirdChat from '@sendbird/chat';
import * as ConversationService from '../../../services/conversation/conversation.service';
import * as profileService from '../../../services/profile/profile.service';
import * as schedulingService from '../../../services/schedule/schedule.service';
import * as AuthService from '../../../services/auth/auth.service';
import { MediaManager } from '../../../services/mediaManager/mediaManager';
import { showSnackbar } from '../snackbar';
import {
  ADD_ASSIGNED_EVALUATION,
  ADD_ASSIGNED_EVALUATION_FAILED,
  ADD_ASSIGNED_EVALUATION_SUCCESSFUL,
  ADD_ASSIGNED_PLAN_ITEMS,
  ADD_ASSIGNED_PLAN_ITEMS_FAILED,
  ADD_ASSIGNED_PLAN_ITEMS_SUCCESSFUL,
  ADD_CONTENT_BLOCK,
  ADD_CONTENT_BLOCK_FAILED,
  ADD_CONTENT_BLOCK_SUCCESSFUL,
  ADD_PROFILE_ELEMENT,
  ADD_PROFILE_ELEMENT_FAILED,
  ADD_PROFILE_ELEMENT_SUCCESSFUL,
  CHAT_ADD_MESSAGE,
  CHAT_EXIT,
  CHAT_INIT_FAILED,
  CHAT_INITIALIZE,
  CHAT_INITIALIZING,
  CHAT_MARK_AS_READ,
  CHAT_MEDIA_SENT,
  CHAT_MEDIA_UPLOAD_PROGRESS,
  CHAT_MEDIA_UPLOADED,
  CHAT_MESSAGE_RECEIVED,
  CHAT_READY,
  CHAT_SEND_ATTACHMENT,
  CHAT_SEND_MESSAGE,
  conversationActionCreators,
  CREATE_AUTOMATION_RULE,
  CREATE_AUTOMATION_RULE_FAILED,
  CREATE_AUTOMATION_RULE_SUCCESSFUL,
  CREATE_PLAN_ITEM,
  CREATE_PLAN_ITEM_FAILED,
  CREATE_PLAN_ITEM_SUCCESSFUL,
  CREATE_SESSION_TYPE,
  CREATE_SESSION_TYPE_FAILED,
  CREATE_SESSION_TYPE_SUCCESSFUL,
  CREATE_TODO,
  DELETE_AUTOMATION_RULE,
  DELETE_AUTOMATION_RULE_FAILED,
  DELETE_AUTOMATION_RULE_SUCCESSFUL,
  DELETE_EVALUATION,
  DELETE_EVALUATION_FAILED,
  DELETE_EVALUATION_SUCCESSFUL,
  DELETE_LIBRARY_CONTENT_BLOCKS,
  DELETE_LIBRARY_CONTENT_BLOCKS_FAILED,
  DELETE_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL,
  DELETE_MEMBER_CONNECTION,
  DELETE_PLAN_ITEM,
  DELETE_PLAN_ITEM_FAILED,
  DELETE_PLAN_ITEM_SUCCESSFUL,
  DELETE_SESSION_TYPE,
  DELETE_SESSION_TYPE_FAILED,
  DELETE_SESSION_TYPE_SUCCESSFUL,
  FETCH_AI_JOBS,
  FETCH_AI_JOBS_FAILED,
  FETCH_AI_JOBS_SUCCESSFUL,
  FETCH_ALL_TODO,
  FETCH_ALL_TODO_SUCCESSFUL,
  FETCH_ASSIGNED_EVALUATION,
  FETCH_ASSIGNED_EVALUATION_FAILED,
  FETCH_ASSIGNED_EVALUATION_SUCCESSFUL,
  FETCH_ASSIGNED_PLAN_ITEMS,
  FETCH_ASSIGNED_PLAN_ITEMS_FAILED,
  FETCH_ASSIGNED_PLAN_ITEMS_SUCCESSFUL,
  FETCH_ASSOCIATED_TAG,
  FETCH_ASSOCIATED_TAG_FAILED,
  FETCH_ASSOCIATED_TAG_SUCCESSFUL,
  FETCH_AUTOMATION_COUNT,
  FETCH_AUTOMATION_COUNT_FAILED,
  FETCH_AUTOMATION_COUNT_SUCCESSFUL,
  FETCH_AUTOMATION_RULE_LIST,
  FETCH_AUTOMATION_RULE_LIST_FAILED,
  FETCH_AUTOMATION_RULE_LIST_SUCCESSFUL,
  FETCH_CHATBOT_ATTEMPT_HISTORY,
  FETCH_CHATBOT_ATTEMPT_HISTORY_FAILED,
  FETCH_CHATBOT_ATTEMPT_HISTORY_SUCCESSFUL,
  FETCH_CHATBOT_CONTACTS,
  FETCH_CHATBOT_CONTACTS_FAILED,
  FETCH_CHATBOT_CONTACTS_SUCCESSFUL,
  FETCH_CHATS,
  FETCH_CHATS_SUCCESSFUL,
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_CONVERSATIONS_SUCCESSFUL,
  FETCH_EVALUATION_CONTEXT,
  FETCH_EVALUATION_CONTEXT_FAILED,
  FETCH_EVALUATION_CONTEXT_SUCCESSFUL,
  FETCH_EVALUATIONS,
  FETCH_EVALUATIONS_FAILED,
  FETCH_EVALUATIONS_SUCCESSFUL,
  FETCH_LIBRARY_CONTENT_BLOCKS,
  FETCH_LIBRARY_CONTENT_BLOCKS_FAILED,
  FETCH_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL,
  FETCH_PLAN_ITEMS,
  FETCH_PLAN_ITEMS_FAILED,
  FETCH_PLAN_ITEMS_SUCCESSFUL,
  FETCH_PROFILE_ELEMENTS,
  FETCH_PROFILE_ELEMENTS_FAILED,
  FETCH_PROFILE_ELEMENTS_SUCCESSFUL,
  FETCH_REVAMP_TYPES_LIST,
  FETCH_REVAMP_TYPES_LIST_FAILED,
  FETCH_REVAMP_TYPES_LIST_SUCCESSFUL,
  FETCH_SESSION_TYPES,
  FETCH_SESSION_TYPES_FAILED,
  FETCH_SESSION_TYPES_SUCCESSFUL,
  FETCH_TAGS,
  FETCH_TAGS_FAILED,
  FETCH_TAGS_SUCCESSFUL,
  GET_DATA_COLLECTION_TEMPLATES,
  GET_DATA_COLLECTION_TEMPLATES_FAILED,
  GET_DATA_COLLECTION_TEMPLATES_SUCCESSFUL,
  LOAD_MORE_MESSAGES,
  LOAD_MORE_MESSAGES_FAILED,
  LOAD_MORE_MESSAGES_SUCCESS,
  RE_RUN_AI_ANALYSIS,
  SENDBIRD_CHANNELS_FETCHED,
  SENDBIRD_CONNECT,
  SENDBIRD_CONNECT_FAILED,
  SENDBIRD_CONNECTED,
  SENDBIRD_CONNECTING,
  SENDBIRD_FETCH_UNREAD_CHANNELS,
  SENDBIRD_INIT,
  SENDBIRD_RECONNECT,
  SENDBIRD_RECONNECTING,
  SENDBIRD_UNREAD_CHANNELS_FETCHED,
  UPDATE_AUTOMATION_RULE,
  UPDATE_AUTOMATION_RULE_FAILED,
  UPDATE_AUTOMATION_RULE_SUCCESSFUL,
  UPDATE_LIBRARY_CONTENT_BLOCKS,
  UPDATE_LIBRARY_CONTENT_BLOCKS_FAILED,
  UPDATE_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL,
  UPDATE_PLAN_ITEM,
  UPDATE_PLAN_ITEM_FAILED,
  UPDATE_PLAN_ITEM_SUCCESSFUL,
  UPDATE_PROFILE_ELEMENT,
  UPDATE_PROFILE_ELEMENT_FAILED,
  UPDATE_PROFILE_ELEMENT_SUCCESSFUL,
  UPDATE_SESSION_TYPE,
  UPDATE_SESSION_TYPE_FAILED,
  UPDATE_SESSION_TYPE_SUCCESSFUL,
} from './actions';
import { getSendbirdAppId, SendBirdAction, SendBirdChatEvent, uuid4 } from '../../../lib/sendbird';
import { selectPlanItems, selectPlanItemState } from './selectors';
import { USER_LOGOUT } from '../auth/actions';
import { CONNECTIONS_TYPES } from '../../../constants/CommonConstants';
import { profileActionCreators } from '../profile';
import {
  ALL_CONNECTIONS_FETCHED,
  CARE_NAVIGATOR_CONNECTIONS_FETCHED,
  CARETEAM_CONNECTIONS_FETCHED,
  FETCH_USER_GROUPS_SUCCESSFUL,
  MEMBER_CONNECTIONS_FETCHED,
  PROVIDER_CONNECTIONS_FETCHED,
  SEARCH_CHATS_SUCCESS,
} from '../profile/actions';
import { getPaginatedConnections } from '../../../services/member/member.service';

const connectionStatus = {
  connecting: 0,
  connected: 1,
  fetchingMessages: 2,
  promptRequired: 3,
  readyToChat: 4,
  failedToConnect: 5,
  closed: 6,
};
let sbChat;
let channelActionPlaceholder;
const callbackChannel = sagaChannel();

/**
 * @Name getSBChannel
 * @param channelUrl , userId
 * @description This method is used to get sendBird channel
 */
function* getSBChannel(channelUrl, userId) {
  const sendBirdAction = yield call(SendBirdAction.getInstance);
  let sendBirdChannel = null;
  if (channelUrl) {
    sendBirdChannel = yield call(sendBirdAction.getChannel.bind(sendBirdAction), channelUrl, false);
  } else {
    console.log('No Channel URL present in cache. Getting Channel URL from Backend');
    const pathParams = { connectionId: userId.connectionId ?? channelUrl };
    const channelIdResponse = yield call(ConversationService.getChannelUrl, pathParams);
    if (channelIdResponse.errors || channelIdResponse.data.channelUrl === null) {
      console.log(channelIdResponse.errors);
      // AlertUtil.showErrorMessage("Chat service is unavailable at the moment, please try again later");
      yield put({
        type: CHAT_INITIALIZING,
        payload: {
          connectionStatus: connectionStatus.failedToConnect,
        },
      });
      yield put({
        type: CHAT_EXIT,
      });
    } else {
      sendBirdChannel = yield call(
        sendBirdAction.getChannel.bind(sendBirdAction),
        channelIdResponse.data.channelUrl,
        false,
      );
    }
  }

  return sendBirdChannel;
}

const createChatEventChannel = channelEvent => {
  return eventChannel(emit => {
    const handleMessageReceived = (sbChannel, message) => {
      emit({
        message,
        channel: sbChannel,
        channelUrl: sbChannel.url,
        type: 'MessageReceived',
        isDistinct: sbChannel.isDistinct,
      });
    };
    const handleMessageUpdated = (channel, message) => {
      console.log('onMessageUpdated fired:', { channel, message });
      // if (channel.url) {
      //   emit({ message, type: 'MessageUpdated' });
      // }
    };

    // Attaching event handlers
    channelEvent.onMessageReceived = handleMessageReceived;
    channelEvent.onMessageUpdated = handleMessageUpdated;

    // Returning an unsubscribe function
    return () => {
      channelEvent.onMessageReceived = () => {
        console.log('onMessageReceived unsubscribed');
      };
      channelEvent.onMessageUpdated = () => {
        console.log('onMessageUpdated unsubscribed');
      };
    };
  });
};

function* sendBirdFileSender(sbAction, payload, channel, connection, newLocation, dispatch) {
  const handlerCB = (message, error) => {
    if (message) {
      dispatch({
        type: CHAT_MEDIA_SENT,
        payload: {
          /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
          _id: payload._id,
          type: payload.type,
          channelUrl: channel.url,
          location: newLocation,
          messageId: message.messageId,
          prevMsg: payload.data,
          msgCreatedAt: message?.createdAt,
          message,
        },
        meta: {
          nickName: payload.nickName,
          url: message?.sender?.plainProfileUrl,
          contact: {
            provider: connection,
          },
        },
      });
    } else {
      console.log('Sendbird error sending media');
      console.log(error);
    }
  };
  try {
    const sb = Sendbird.getInstance();
    const params = new sb.FileMessageParams();
    params.parentMessageId = payload.parentMessageId;
    params.fileUrl = payload.location;
    params.mimeType = payload.type;
    params.data =
      payload?.data?.messageId === undefined
        ? JSON.stringify({})
        : payload.data.messageId !== 0
        ? JSON.stringify(payload.data)
        : JSON.stringify(payload.data);
    const requestPayload = {
      channel,
      file: params,
      handler: handlerCB,
    };
    yield call(sbAction.sendFileMessage, requestPayload);
  } catch (e) {
    console.log('Sendbird error sending media');
    console.log(e);
  }
}

function* mediaSenderTask(sendBirdAction, connectedChannel, dispatch, connection) {
  while (true) {
    const { payload } = yield take(CHAT_MEDIA_UPLOADED);
    const transformedData = [
      {
        fileMeta: { url: payload.location },
        prevMsg: [{}],
      },
    ];
    const { data } = yield call(ConversationService.getSignedUrl, { messages: transformedData });
    const newLocation = data[0].fileMeta.url;
    yield fork(
      sendBirdFileSender,
      sendBirdAction,
      payload,
      connectedChannel,
      connection,
      newLocation,
      dispatch,
    );
  }
}

function* awsMediaUploader(payload, dispatch) {
  try {
    const response = yield call(MediaManager.uploadChatMedia, payload.file, e => {
      const progress = e.percent * 100;
      dispatch({
        type: CHAT_MEDIA_UPLOAD_PROGRESS,
        payload: {
          ...payload,
          progress,
        },
      });
    });
    if (response.success) {
      yield put({
        type: CHAT_MEDIA_UPLOADED,
        payload: {
          nickName: payload.nickName,
          parentMessageId: payload.parentMessageId,
          channelUrl: payload.channel.channelUrl,
          _id: payload._id,
          type: payload.file.type,
          location: response.response.location,
          data: payload.data,
        },
      });
    } else {
      console.log('Media storage service failed to upload attachment');
    }
  } catch (e) {
    console.log(e);
  }
}

function* attachmentSender(dispatch) {
  while (true) {
    const { payload } = yield take(CHAT_SEND_ATTACHMENT);
    yield fork(awsMediaUploader, payload, dispatch);
  }
}

function* messageSenderTask(sendBirdAction, connectedChannel, dispatch, connection) {
  try {
    while (true) {
      const action = yield take(CHAT_SEND_MESSAGE);

      if (action.payload.message.hasFile) {
        let attachment = {
          parentMessageId: 0,
          channel: connectedChannel,
          file: action.payload.message.file,
          nickName: action.payload.message.nickName,
          data: {
            ...action.payload.message.data,
          },
        };
        if (action?.payload?.message?.textMessage) {
          attachment = {
            ...attachment,
            textMessage: action?.payload?.message?.textMessage,
            textMessage: action?.payload?.message?.textMessage ?? '',
            data: {
              ...action.payload.message.data,
              textMessage: action?.payload?.message?.textMessage ?? '',
            },
          };
        }
        const meta = yield select(state => state.auth.meta);
        yield put({
          type: CHAT_SEND_ATTACHMENT,
          payload: {
            ...attachment,
            _id: uuid4(),
            meta,
          },
        });
      } else {
        const { connectionsFetchedFor } = yield select(state => state.profile);
        const originalMessage = action.payload.message;
        const requestPayload = {
          channel: connectedChannel,
          message: originalMessage.message.text,
          parentMessageId: 0,
          mentionedUserIds: originalMessage.mentionedProvidersIds,
          data:
            originalMessage?.data?.messageId === undefined
              ? {}
              : originalMessage?.data?.messageId !== 0
              ? originalMessage.data
              : {},
          handler(sentMessage, error) {
            if (sentMessage) {
              originalMessage._id = sentMessage.messageId;
              originalMessage.mentionedUsers = sentMessage.mentionedUsers;
              originalMessage.prevMsg = sentMessage.data !== '' ? [JSON.parse(sentMessage.data)] : [];
              originalMessage.createdAt = sentMessage?.createdAt;
              originalMessage.user.avatar = sentMessage?.sender?.plainProfileUrl;
              dispatch({
                type: CHAT_ADD_MESSAGE,
                payload: {
                  message: originalMessage,
                  channelUrl: connectedChannel.url,
                },
                meta: {
                  contact: {
                    provider: connection,
                  },
                },
              });
              if (
                originalMessage?.mentionedProvidersIds !== undefined &&
                originalMessage?.mentionedProvidersIds?.length !== 0
              ) {
                const bodyRequest = {
                  message: originalMessage.message.text,
                  recipients: originalMessage.mentionedProvidersIds,
                  createdBy: connectionsFetchedFor,
                  contentIdentifier: originalMessage._id,
                  channelId: connectedChannel.url,
                  status: false,
                  type: 'CHAT',
                  notifyBySms: false,
                  dueDate: '',
                };
                dispatch({
                  type: CREATE_TODO,
                  payload: {
                    message: originalMessage?.memberId
                      ? { ...bodyRequest, memberId: originalMessage?.memberId }
                      : bodyRequest,
                    channelUrl: connectedChannel.url,
                  },
                  meta: {
                    contact: {
                      provider: connection,
                    },
                  },
                });
              }
            } else {
              console.log(error);
            }
          },
        };
        yield call(sendBirdAction.sendUserMessage.bind(sendBirdAction), requestPayload);
      }
    }
  } catch (e) {
    console.log('Message Sender Task threw an error');
    console.log(e);
  }
}

function* createToDoHandler(action) {
  try {
    const bodyRequest = action.payload.message;
    const { status, data } = yield call(profileService.createToDo, bodyRequest);
    if (status === 200) {
      if (action.payload.onSuccess) {
        action.payload.onSuccess(data.ids);
      }
      yield put(showSnackbar({ snackType: 'success', snackMessage: 'Todos created successfully' }));
      yield fork(fetchAllToDoHandler);
    }
  } catch (error) {
    console.warn(error);
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
  }
}

function* fetchAllToDoHandler() {
  try {
    const { userId } = yield select(state => state.auth.meta);
    const bodyRequest = {
      assignedBy: userId,
      pageNumber: 0,
      type: 'CHAT',
      status: false,
      refresh: true,
    };
    const bodyRequest2 = {
      assignedTo: userId,
      pageNumber: 0,
      type: 'CHAT',
      status: false,
      refresh: true,
    };
    const { data } = yield call(profileService.listTodos, bodyRequest);
    const responses = yield call(profileService.listTodos, bodyRequest2);
    const allData = [...data?.results, ...responses?.data?.results];
    yield put({
      type: FETCH_ALL_TODO_SUCCESSFUL,
      payload: allData,
    });
  } catch (e) {
    console.log({ e });
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: message }));
  }
}

function* dispatchMarkAsReadAction(channelUrl) {
  yield put({
    type: CHAT_MARK_AS_READ,
    payload: {
      channelUrl,
    },
  });
}

function* markMessagesAsRead(channelUrl, sendBirdAction, sendBirdChannel) {
  let tempSendBirdAction = sendBirdAction;
  // let tempSendBirdChannel = sendBirdChannel;
  if (!sendBirdAction) {
    tempSendBirdAction = SendBirdAction.getInstance();
    // tempSendBirdChannel = yield call(sendBirdAction.getChannel, channelUrl, false);
  }
  yield call(tempSendBirdAction.markAsRead.bind(tempSendBirdAction), sendBirdChannel);
  yield call(dispatchMarkAsReadAction, channelUrl);
}

export const mapMessageToGiftedChat = message => {
  let msgPrevious = [];
  let textMessage = '';
  if (message.data) {
    msgPrevious = JSON.parse(message.data);
    if (Object.keys(msgPrevious).length !== 0) {
      textMessage = msgPrevious?.textMessage ?? '';
      msgPrevious.messageId === 0 ? (msgPrevious = [{}]) : (msgPrevious = [msgPrevious]);
    } else {
      msgPrevious = [{}];
    }
  }
  let giftedMessage = {};
  giftedMessage = {
    _id: message.messageId || message.message_id,
    parentMessageId: message.parentMessageId || null,
    mentionedUsers: message.mentionedUsers || [],
    prevMsg: message.data ? msgPrevious : [],
    message: {
      text: message.messageType !== 'file' ? message.message || '' : textMessage,
    },
    nickName: giftedMessage.system
      ? 'system'
      : message.sender?.nickname || message.user?.nickname || 'Unknown',
    createdAt: message.createdAt || message.created_at || Date.now(),
    type: message.messageType || message.type,
    fileMeta:
      message.messageType === 'file'
        ? {
            url: message.url || message.file?.url || '',
            type: message.type || message.file?.type,
            loading: false,
            progress: 0,
          }
        : null,
    system: message.messageType && message.messageType === 'admin',
    user: null,
  };
  if (giftedMessage.system) {
    giftedMessage.user = {
      userId: message.messageId || message.user?.user_id,
      name: 'System',
      avatar: null,
    };
  } else {
    giftedMessage.user = {
      userId: message.sender?.userId || message.user?.user_id,
      name: message.sender?.nickname || message.user?.nickname,
      avatar: message.sender?.profileUrl || message.user?.profile_url,
    };
  }
  return giftedMessage;
};

function* readMarkerOnActiveChat(channelUrl, sendBirdAction, sendBirdChannel) {
  // eslint-disable-next-line func-names
  yield takeEvery(CHAT_MESSAGE_RECEIVED, function* (action) {
    if (action.payload.channelUrl === channelUrl) {
      yield call(markMessagesAsRead, channelUrl, sendBirdAction, sendBirdChannel);
    }
  });
}

function* silentChatRefresher(action, dispatch) {
  const { channelUrl, connection } = action.payload;
  const sendBirdAction = yield call(() => SendBirdAction.getInstance);
  const sendBirdChannel = yield call(getSBChannel, channelUrl, connection.connectionId);
  let chatMessages = yield call(sendBirdAction.getMessageList.bind(sendBirdAction), sendBirdChannel, true);
  const channelMembers = yield call(sendBirdAction.getMemberInChannel.bind(sendBirdAction), channelUrl);
  // const filteredChat = chatMessages?.filter(item => item.threadInfo.replyCount !== 0);
  // let messageThreads = yield call(sendBirdAction.getThreadMessageList, filteredChat);
  if (chatMessages) {
    chatMessages = chatMessages.map(mapMessageToGiftedChat);
    // console.log(chatMessages);
    // messageThreads = messageThreads.map(mapMessageToGiftedChat);
    /* const newChatMessages = chatMessages.map(chat => {
      const thread = messageThreads?.filter(item => item.parentMessageId === chat._id);
      chat.thread = thread;
      return chat;
    }); */
    yield call(markMessagesAsRead, channelUrl, sendBirdAction, sendBirdChannel);
    const fileMetaMessages = chatMessages.filter(item => item?.fileMeta || item?.prevMsg[0]?.fileMeta);
    const transformedData = fileMetaMessages.map(item => ({
      _id: item?._id,
      fileMeta: item?.fileMeta
        ? {
            url: item?.fileMeta?.url,
          }
        : null,
      prevMsg: item?.prevMsg[0]?.fileMeta?.url
        ? item?.prevMsg.map(prev => ({
            fileMeta: {
              url: prev?.fileMeta?.url,
            },
            messageId: prev?.messageId,
          }))
        : [{}],
    }));
    const { data } = yield call(ConversationService.getSignedUrl, { messages: transformedData });
    const urlMap = new Map();
    data.forEach(item => {
      urlMap.set(item._id, item.fileMeta.url);
      item.prevMsg.forEach(msg => {
        if (msg.fileMeta && msg.fileMeta.url) {
          urlMap.set(msg.messageId, msg.fileMeta.url);
        }
      });
    });
    chatMessages.forEach(item => {
      if (urlMap.has(item._id)) {
        item.fileMeta.url = urlMap.get(item._id);
      }
      item.prevMsg.forEach(msg => {
        if (msg.messageId && urlMap.has(msg.messageId)) {
          msg.fileMeta.url = urlMap.get(msg.messageId);
        }
      });
    });
    yield put({
      type: CHAT_READY,
      payload: {
        connectionStatus: connectionStatus.readyToChat,
        channelMembers,
        chatMessages,
        channelUrl,
      },
    });
  }
  yield fork(messageSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
  yield fork(mediaSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
  yield fork(attachmentSender, dispatch);
  yield fork(readMarkerOnActiveChat, channelUrl, sendBirdAction, sendBirdChannel);
}

export function* incomingMessageHandler() {
  const chatEvent = yield call(SendBirdChatEvent.getInstance);
  const chatEventChannel = yield call(createChatEventChannel, chatEvent);
  const { userId } = yield select(state => state.auth.meta);
  try {
    while (true) {
      const { message, channelUrl, isDistinct, channel } = yield take(chatEventChannel);

      const oneHour = 60 * 60 * 1000;
      const currentTimestamp = new Date().getTime();
      const messageCreatedAt = message.createdAt;

      // Check if the message is within last hour
      if (currentTimestamp - messageCreatedAt < oneHour) {
        const newMsg = mapMessageToGiftedChat(message);

        if (newMsg?.fileMeta?.url) {
          const transformedData = [
            {
              fileMeta: { url: message.plainUrl },
              prevMsg: [{}],
            },
          ];
          const response = yield call(ConversationService.getSignedUrl, { messages: transformedData });
          const newLocation = response.data[0]?.fileMeta.url;
          newMsg.fileMeta.url = newLocation;
        }
        yield put({
          type: CHAT_MESSAGE_RECEIVED,
          payload: {
            message: newMsg,
            channelUrl,
            isDistinct,
            senderId: message.sender.userId,
            isIncoming: message.sender.userId !== userId,
            sbMessage: message,
            sbChannel: channel,
          },
        });
      }
    }
  } catch (e) {
    console.log('ChatEventChannel threw an error');
    console.log(e);
  }
}

export function* loadMoreMessagesHandler(action) {
  const sendBirdAction = yield call(SendBirdAction.getInstance);
  const { userId } = yield select(state => state.auth.meta);
  let sendBirdChannel;
  try {
    sendBirdChannel = yield call(getSBChannel, action.payload.channelUrl, { connectionId: userId });
    let chatMessages = yield call(
      sendBirdAction.getMessageList.bind(sendBirdAction),
      sendBirdChannel,
      false,
      action.payload.limit,
      action.payload.message_ts,
    );
    const channelMembers = yield call(
      sendBirdAction.getMemberInChannel.bind(sendBirdAction),
      action.payload.channelUrl,
    );
    if (chatMessages) {
      chatMessages = chatMessages.map(mapMessageToGiftedChat);

      yield call(markMessagesAsRead, action.payload.channelUrl, sendBirdAction, sendBirdChannel);

      const newLoadedMessages = chatMessages?.filter(
        message => message.createdAt < action.payload.message_ts,
      );
      const isLoadedAllMessages = newLoadedMessages.length < 200;

      yield put({
        type: LOAD_MORE_MESSAGES_SUCCESS,
        payload: {
          connectionStatus: connectionStatus.readyToChat,
          channelMembers,
          chatMessages: newLoadedMessages,
          channelUrl: action.payload.channelUrl,
          isLoadedAllMessages,
        },
      });
    }
  } catch (e) {
    console.log('ChatEventChannel threw an error');
    console.log(e);
    yield put({
      type: LOAD_MORE_MESSAGES_FAILED,
      payload: {
        message: e?.data?.errors[0]?.endUserMessage || 'Unable to fetch old messages',
      },
    });
  }
}
/**
 * @Name liveChatFlowHandler
 * @param action , dispatch
 * @description This method is used to run chat flow & get all messages
 */
function* liveChatFlowHandler(action, dispatch) {
  yield put({
    type: CHAT_INITIALIZING,
    payload: {
      connectionStatus: connectionStatus.connecting,
    },
  });
  const { channelUrl, connection, currentUser } = action.payload;
  const sendBirdAction = yield call(SendBirdAction.getInstance);
  let sendBirdChannel = null;
  let newChannel = false;
  const channelName = `${currentUser.nickname}-${connection.nickname}`;
  try {
    const userId = {
      connectionId: connection.connectionId,
    };
    sendBirdChannel = yield call(getSBChannel, channelUrl, userId);
  } catch (error) {
    console.log(error);
    if (error?.code === 400201) {
      console.log('Channel not found So creating a new channel');
      sendBirdChannel = yield call(
        sendBirdAction.createGroupChannel.bind(sendBirdAction),
        channelName,
        channelUrl,
        connection.userId,
        currentUser.userId,
      );
      newChannel = true;
    } else {
      yield put({
        type: CHAT_INIT_FAILED,
        payload: {
          message:
            error?.data?.errors[0]?.endUserMessage ||
            'Can not init chat. Please reload to connect chat again!',
        },
      });
    }
  }
  if (sendBirdChannel) {
    if (newChannel) {
      yield put({
        type: CHAT_READY,
        payload: {
          connectionStatus: connectionStatus.readyToChat,
          chatMessages: [],
          channelUrl,
        },
      });
    } else {
      yield put({
        type: CHAT_INITIALIZING,
        payload: {
          connectionStatus: connectionStatus.fetchingMessages,
        },
      });
      let chatMessages = yield call(
        sendBirdAction.getMessageList.bind(sendBirdAction),
        sendBirdChannel,
        true,
      );
      // const filteredChat = chatMessages?.filter(item => item.threadInfo.replyCount !== 0);
      // let messageThreads = yield call(sendBirdAction.getThreadMessageList, filteredChat);
      const channelMembers = yield call(sendBirdAction.getMemberInChannel.bind(sendBirdAction), channelUrl);
      if (chatMessages) {
        chatMessages = chatMessages.map(mapMessageToGiftedChat);
        // messageThreads = messageThreads.map(mapMessageToGiftedChat);
        /* const newChatMessages = chatMessages.map(chat => {
          const thread = messageThreads?.filter(item => item.parentMessageId === chat._id);
          chat.thread = thread;
          return chat;
        }); */
        yield call(markMessagesAsRead, channelUrl, sendBirdAction, sendBirdChannel);
        const fileMetaMessages = chatMessages.filter(item => item?.fileMeta || item?.prevMsg[0]?.fileMeta);
        const transformedData = fileMetaMessages.map(item => ({
          _id: item?._id,
          fileMeta: item?.fileMeta
            ? {
                url: item?.fileMeta?.url,
              }
            : null,
          prevMsg: item?.prevMsg[0]?.fileMeta?.url
            ? item?.prevMsg.map(prev => ({
                fileMeta: {
                  url: prev?.fileMeta?.url,
                },
                messageId: prev?.messageId,
              }))
            : [{}],
        }));
        const { data } = yield call(ConversationService.getSignedUrl, { messages: transformedData });
        const urlMap = new Map();
        data.forEach(item => {
          if (item.fileMeta && item.fileMeta.url) {
            urlMap.set(item._id, item.fileMeta.url);
          }
          item.prevMsg.forEach(msg => {
            if (msg.fileMeta && msg.fileMeta.url) {
              urlMap.set(msg.messageId, msg.fileMeta.url);
            }
          });
        });
        chatMessages.forEach(item => {
          if (urlMap.has(item._id) && item.fileMeta) {
            item.fileMeta.url = urlMap.get(item._id);
          }
          item.prevMsg.forEach(msg => {
            if (msg.messageId && urlMap.has(msg.messageId) && msg.fileMeta) {
              msg.fileMeta.url = urlMap.get(msg.messageId);
            }
          });
        });
        yield put({
          type: CHAT_READY,
          payload: {
            connectionStatus: connectionStatus.readyToChat,
            channelMembers,
            chatMessages,
            channelUrl,
          },
        });
      }
    }
    yield fork(messageSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
    yield fork(mediaSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
    yield fork(attachmentSender, dispatch);
    yield fork(readMarkerOnActiveChat, channelUrl, sendBirdAction, sendBirdChannel);
  }
}

function chatConnectionStatusChannel() {
  console.log('Watching Sendbird Connection');
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      emitter(SendBirdAction.getInstance().sb.getConnectionState());
    }, 2000);
    return () => {
      clearInterval(iv);
    };
  });
}

function* chatConnectionWatcher() {
  const statusChannel = yield call(chatConnectionStatusChannel);
  while (true) {
    const status = yield take(statusChannel);
    const sendbirdStatus = yield select(state => state.conversation.sendbirdStatus);
    switch (status) {
      case 'CLOSED': {
        yield put({
          type: SENDBIRD_CONNECT_FAILED,
        });
        SendBirdAction.getInstance().sb.reconnect();
        break;
      }
      case 'CONNECTING': {
        if (sendbirdStatus !== 3) {
          yield put({
            type: SENDBIRD_RECONNECTING,
          });
        }
        break;
      }
      case 'OPEN': {
        if (sendbirdStatus !== 2) {
          yield put({
            type: SENDBIRD_CONNECTED,
          });
          yield put({
            type: SENDBIRD_FETCH_UNREAD_CHANNELS,
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  }
}
function* updateConnections(channel) {
  try {
    const channelUrl = channel.url;
    const {
      members: { connections: members },
      providers: { connections: providers },
      careTeam: { connections: careTeam },
    } = yield select(state => state.profile.chats);
    const groups = yield select(state => state.profile.userGroups);
    const auth = yield select(state => state.auth);
    const found = (
      careTeam?.length > 0
        ? [...groups, ...members, ...providers, ...careTeam]
        : [...groups, ...members, ...providers]
    ).some(item => item.channelUrl === channelUrl);
    if (!found) {
      const {
        data: { results: connections },
      } = yield call(getPaginatedConnections, { searchByChannelUrl: channelUrl });
      const connection = connections[0];
      if (connection) {
        if (connection.type === 'CHAT_GROUP') {
          yield put(profileActionCreators.fetchUserGroups(auth.meta.userId));
        } else {
          yield put(
            profileActionCreators.updateChatConnections({
              updatedConnection: connection,
              type: connection.type,
            }),
          );
        }
      }
    }
  } catch (error) {
    console.warn(error);
  }
}

function* fetchUnreadChannels() {
  if (!sbChat) {
    return;
  }
  const groupChannelFilter = new GroupChannelFilter();
  groupChannelFilter.unreadChannelFilter = UnreadChannelFilter.UNREAD_MESSAGE;

  const collection = sbChat.groupChannel.createGroupChannelCollection({
    filter: groupChannelFilter,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
  });
  let channels = yield call(collection.loadMore.bind(collection));
  yield put({
    type: SENDBIRD_UNREAD_CHANNELS_FETCHED,
    payload: {
      channels,
    },
  });
  while (collection.hasMore) {
    const newChannels = yield call(collection.loadMore.bind(collection));
    channels = channels.concat(newChannels);
    yield put({
      type: SENDBIRD_UNREAD_CHANNELS_FETCHED,
      payload: {
        channels: newChannels,
      },
    });
  }
  const openChatChannelUrl = yield select(state => state.conversation.channelUrl);
  const openChatChannel = channels.find(channel => channel.url === openChatChannelUrl);
  if (openChatChannel) {
    // Unread messages in a channel that's current open in UI
    const { chats, userGroups } = yield select(state => state.profile);
    const connections = [
      ...chats.members.connections,
      ...chats.providers.connections,
      ...chats.careTeam.connections,
    ];
    const selectedConnection = connections.find(conn => conn.channelUrl === openChatChannelUrl);
    let contact = null;
    if (selectedConnection) {
      contact = {
        ...selectedConnection,
        id: selectedConnection?.connectionId,
        type: selectedConnection?.type,
        role: selectedConnection?.type,
        messages: [],
        lastMessage: selectedConnection?.lastMessage,
        lastUpdated: selectedConnection?.lastMessageTimestampUnix,
        nickName: selectedConnection?.nickName,
        fullName: selectedConnection?.name,
        thumbnail: selectedConnection?.profilePicture,
        channelUrl: selectedConnection?.channelUrl,
        colorCode: selectedConnection?.colorCode,
      };
    } else {
      const selectedUserGroup = userGroups.find(group => group.channelUrl === openChatChannelUrl);
      if (selectedUserGroup) {
        contact = {
          ...selectedUserGroup,
          id: selectedUserGroup?.channelUrl,
          connectionId: selectedUserGroup?.channelUrl,
          type: CONNECTIONS_TYPES.CHAT_GROUP,
          messages: [],
          lastUpdated: '-',
          nickName: selectedUserGroup?.name,
          fullName: selectedUserGroup?.name,
          thumbnail: selectedUserGroup?.groupImage,
          channelUrl: selectedUserGroup?.channelUrl,
        };
      }
    }
    if (contact) {
      const { meta } = yield select(state => state.auth);
      yield put(
        conversationActionCreators.initChat({
          payload: {
            channelUrl: openChatChannelUrl,
            connection: {
              ...contact,
              messages: [],
              nickname: contact?.nickName,
            },
            currentUser: {
              userId: meta.userId,
              nickname: meta.nickname,
            },
          },
        }),
      );
    }
  }
  if (channels.length > 0) {
    yield put({
      type: SENDBIRD_CHANNELS_FETCHED,
      payload: {
        channels,
      },
    });
    for (let i = 0; i < channels.length; i++) {
      yield call(updateConnections, channels[i]);
    }
  }
}

let watcher = null;

/**
 * @Name connectSendBird
 * @param dispatch
 * @description This method is used to connect to sendbird
 */
function* connectSendBird(action) {
  const auth = yield select(state => state.auth);
  const profile = yield select(state => state.profile);
  try {
    yield put({
      type: SENDBIRD_CONNECTING,
    });
    if (watcher) {
      yield cancel(watcher);
    }
    const { meta, isAdmin } = auth;
    const sendBird = yield call(SendBirdAction.getInstance);
    let profileImage = 'https://i.imgur.com/Tgbdv8K.png';
    if (!isAdmin) {
      profileImage = profile.profile?.profileImage;
    }
    yield call(sendBird.connect, meta.userId, meta.nickName, profileImage, action.payload.sessionToken);
    yield fork(incomingMessageHandler);
    yield put({
      type: SENDBIRD_CONNECTED,
    });
    watcher = yield fork(chatConnectionWatcher);
  } catch (e) {
    console.log(e);
    setTimeout(() => {
      callbackChannel.put({
        type: SENDBIRD_RECONNECTING,
      });
      callbackChannel.put({
        type: SENDBIRD_RECONNECT,
      });
    }, 1000);
  }
}

function* clearSbConnectionWatcher() {
  if (watcher) {
    yield cancel(watcher);
  }
}

function* dispatchToStore(action) {
  yield put(action);
}

/**
 * @Name chatSaga
 * @param store
 * @description This method is used to initialize chat flow
 */
export default function* chatSaga(store) {
  let chatFlowHandle;
  while (true) {
    const action = yield take([CHAT_INITIALIZE, CHAT_EXIT]);
    const sendBird = yield call(SendBirdAction.getInstance);
    if (chatFlowHandle) {
      yield cancel(chatFlowHandle);
    }
    if (action.type === CHAT_INITIALIZE) {
      if (!sendBird.sb.isSessionOpened) {
        yield take(SENDBIRD_CONNECTED);
      }
      if (action.payload.channelUrl) {
        if (action.payload.connection.messages?.length > 0) {
          yield put({
            type: CHAT_READY,
            payload: {
              connectionStatus: connectionStatus.readyToChat,
              chatMessages: action.payload.connection.messages,
              channelUrl: action.payload.connection.channelUrl,
            },
          });
          chatFlowHandle = yield fork(silentChatRefresher, action, store.dispatch);
        } else {
          chatFlowHandle = yield fork(liveChatFlowHandler, action, store.dispatch);
        }
      } else {
        yield put({
          type: CHAT_INITIALIZING,
          payload: {
            connectionStatus: false,
            chatMessages: [],
          },
        });
        yield put({
          type: CHAT_READY,
          payload: {
            connectionStatus: connectionStatus.failedToConnect,
            chatMessages: [],
            channelUrl: '',
          },
        });
      }
    }
  }
}

function* fetchSessionTypeHandler() {
  try {
    const { data } = yield call(ConversationService.getSessionTypes);
    yield put({
      type: FETCH_SESSION_TYPES_SUCCESSFUL,
      payload: {
        sessionTypes: data.map(serviceType => ({
          ...serviceType,
          id: serviceType?.name,
        })),
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_SESSION_TYPES_FAILED,
      payload: {
        message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong! Please try again later ',
      },
    });
  }
}

function* createSessionTypeHandler(action) {
  try {
    yield call(ConversationService.createSessionType, action.payload);

    yield put({
      type: CREATE_SESSION_TYPE_SUCCESSFUL,
      payload: {
        sessionType: { ...action.payload, id: action.payload.name, serviceByTypeCount: 0 },
      },
    });
  } catch (e) {
    yield put({
      type: CREATE_SESSION_TYPE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updateSessionTypeHandler(action) {
  try {
    yield call(ConversationService.updateSessionType, action.payload);

    yield put({
      type: UPDATE_SESSION_TYPE_SUCCESSFUL,
      payload: {
        sessionType: action.payload,
      },
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Session type updated successfully',
      }),
    );
  } catch (e) {
    const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!';
    yield put({
      type: UPDATE_SESSION_TYPE_FAILED,
      payload: { message },
    });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
  }
}

function* deleteSessionTypeHandler(action) {
  try {
    yield call(ConversationService.deleteSessionType, action.payload);

    yield put({
      type: DELETE_SESSION_TYPE_SUCCESSFUL,
      payload: {
        sessionTypeId: action.payload,
      },
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Session type deleted successfully',
      }),
    );
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: e.data?.errors?.[0]?.endUserMessage,
      }),
    );
    yield put({
      type: DELETE_SESSION_TYPE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

export function* sessionTypeSaga() {
  yield takeLatest(FETCH_SESSION_TYPES, fetchSessionTypeHandler);
  yield takeLatest(CREATE_SESSION_TYPE, createSessionTypeHandler);
  yield takeLatest(UPDATE_SESSION_TYPE, updateSessionTypeHandler);
  yield takeLatest(DELETE_SESSION_TYPE, deleteSessionTypeHandler);
}

/**
 * Plan Items
 */
function* fetchPlanItemHandler(action) {
  const { searchQuery, pageNumber, pageSize, isLoadMore, type } = action.payload || {
    searchQuery: '',
    pageNumber: 1,
    pageSize: 10,
    type: '',
    isLoadMore: false,
  };

  try {
    const planItems = yield select(selectPlanItems);
    const { data } = yield call(ConversationService.getPlanItems, {
      searchQuery: searchQuery ?? '',
      pageNumber: pageNumber - 1,
      pageSize,
      type: type?.length > 0 ? type.toString() : '',
    });

    const list = isLoadMore ? [...planItems, ...data.records] : data.records;

    yield put({
      type: FETCH_PLAN_ITEMS_SUCCESSFUL,
      payload: {
        currentPage: pageNumber,
        pageSize,
        totalPages: data.totalPages,
        totalRecords: data.totalRecords,
        planItems: list?.sort((a, b) => (a.id > b.id ? 1 : -1)),
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_PLAN_ITEMS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* createPlanItemHandler(action) {
  try {
    const { filters } = action.payload;
    delete action.payload.filters;
    const { data } = yield call(ConversationService.addPlanItem, action.payload);

    yield put({
      type: CREATE_PLAN_ITEM_SUCCESSFUL,
      payload: {
        planItem: data,
      },
    });

    const { currentPage, pageSize } = yield select(selectPlanItemState);
    yield put({
      type: FETCH_PLAN_ITEMS,
      payload: {
        pageNumber: currentPage,
        pageSize,
        type: filters,
      },
    });
  } catch (e) {
    yield put({
      type: CREATE_PLAN_ITEM_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updatePlanItemHandler(action) {
  try {
    const { filters } = action.payload;
    delete action.payload.filters;
    const { data } = yield call(ConversationService.updatePlanItem, action.payload);

    yield put({
      type: UPDATE_PLAN_ITEM_SUCCESSFUL,
      payload: {
        planItem: data,
      },
    });

    const { currentPage, pageSize } = yield select(selectPlanItemState);
    yield put({
      type: FETCH_PLAN_ITEMS,
      payload: {
        pageNumber: currentPage,
        pageSize,
        type: filters,
      },
    });
  } catch (e) {
    yield put({
      type: UPDATE_PLAN_ITEM_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* deletePlanItemHandler(action) {
  try {
    const { data } = yield call(ConversationService.deletePlanItem, action.payload);

    yield put({
      type: DELETE_PLAN_ITEM_SUCCESSFUL,
      payload: {
        planItem: data,
      },
    });

    const { currentPage, pageSize } = yield select(selectPlanItemState);
    yield put({
      type: FETCH_PLAN_ITEMS,
      payload: {
        pageNumber: currentPage,
        pageSize,
      },
    });
  } catch (e) {
    yield put({
      type: DELETE_PLAN_ITEM_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

/**
 * Assigned Plan Items
 */
function* fetchAssignedPlanItemHandler(action) {
  const { memberId } = action.payload;

  try {
    const { data } = yield call(ConversationService.getAssignedPlanItems, {
      userId: memberId,
    });

    yield put({
      type: FETCH_ASSIGNED_PLAN_ITEMS_SUCCESSFUL,
      payload: {
        userId: memberId,
        items: data.planItemsContexts,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_ASSIGNED_PLAN_ITEMS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* addAssignedPlanItemHandler(action) {
  const { memberId, planItemIds } = action.payload;

  try {
    const { data } = yield call(ConversationService.addAssignedPlanItems, {
      userId: memberId,
      planItemIds,
    });

    yield put({
      type: ADD_ASSIGNED_PLAN_ITEMS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: ADD_ASSIGNED_PLAN_ITEMS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchConversationHandler(action) {
  const { pageNumber, pageSize, status, reportingView, ...rest } = action.payload || {
    pageNumber: 1,
    pageSize: 10000,
    status: 'ACTIVE',
    reportingView: false,
    orderBy: 'asc',
    searchQuery: '',
    sortBy: [''],
  };
  try {
    const { data } = yield call(ConversationService.getConversations, {
      pageNumber: pageNumber - 1,
      pageSize,
      status,
      reportingView,
      ...rest,
    });
    yield put({
      type: FETCH_CONVERSATIONS_SUCCESSFUL,
      payload: {
        totalRecords: data.totalRecords,
        conversations: data.conversationsList.sort((a, b) => (a.conversationId > b.conversationId ? -1 : 1)),
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_CONVERSATIONS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchEvaluationsHandler(action) {
  const { pageNumber, pageSize, status, reportingView, ...rest } = action.payload || {
    pageNumber: 1,
    pageSize: 10000,
    status: 'ACTIVE',
    reportingView: false,
  };
  try {
    const { data } = yield call(ConversationService.getEvaluationList, {
      pageNumber: pageNumber - 1,
      pageSize,
      status,
      reportingView,
      ...rest,
    });
    yield put({
      type: FETCH_EVALUATIONS_SUCCESSFUL,
      payload: {
        evaluations: data.EvaluationSummaryList.sort((a, b) => (a.evaluationId > b.evaluationId ? -1 : 1)),
        totalRecords: data.totalRecords,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_EVALUATIONS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchRevampTypesHandler() {
  try {
    const { data } = yield call(ConversationService.getRevampTypes);

    yield put({
      type: FETCH_REVAMP_TYPES_LIST_SUCCESSFUL,
      payload: {
        revampTypes: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_REVAMP_TYPES_LIST_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchEvaluationContextHandler(action) {
  try {
    const { data } = yield call(ConversationService.getEvaluationContext, {
      appointmentId: action.payload.appointmentId,
      evaluationId: action.payload.evaluationId,
    });
    yield put({
      type: FETCH_EVALUATION_CONTEXT_SUCCESSFUL,
      payload: {
        evaluationContext: data,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FETCH_EVALUATION_CONTEXT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchAssociatedTagHandler(action) {
  try {
    const { data } = yield call(ConversationService.getAssociatedTagData, {
      associatedTagId: action.payload.associatedTagId,
      patientId: action.payload.patientId,
    });

    yield put({
      type: FETCH_ASSOCIATED_TAG_SUCCESSFUL,
      payload: { domainType: data },
    });
  } catch (e) {
    yield put({
      type: FETCH_ASSOCIATED_TAG_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchTagsHandler(action) {
  const { pageNumber = 1, pageSize = 10 } = action.payload || {};

  try {
    const { data } = yield call(ConversationService.getTagsList, {
      pageNumber: pageNumber - 1,
      pageSize,
    });

    yield put({
      type: FETCH_TAGS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_TAGS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchAutomationRulesHandler(action) {
  const {
    pageNumber = 1,
    pageSize = 10,
    eventType = '',
    category = '',
    ruleName = '',
    actionType = '',
    planType = '',
  } = action.payload || {};

  try {
    const { data } = yield call(ConversationService.getAutomationRules, {
      pageNumber: pageNumber - 1,
      pageSize,
      eventType,
      category,
      ruleName,
      actionType,
      planType,
    });

    yield put({
      type: FETCH_AUTOMATION_RULE_LIST_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_AUTOMATION_RULE_LIST_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchAIJobsHandler() {
  try {
    const { data } = yield call(schedulingService.getAIJobs);
    yield put({
      type: FETCH_AI_JOBS_SUCCESSFUL,
      payload: data
        .map(item => item.job)
        .map(job => {
          return {
            ...job,
            jobId: job._id,
          };
        }),
    });
  } catch (e) {
    yield put({
      type: FETCH_AI_JOBS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* createAutomationRuleHandler(action) {
  const { category, ...request } = action.payload || {};
  try {
    const { data } = yield call(ConversationService.addAutomationRule, request);

    yield put({
      type: CREATE_AUTOMATION_RULE_SUCCESSFUL,
      payload: data,
    });

    yield put({
      type: FETCH_AUTOMATION_RULE_LIST,
      payload: {
        pageNumber: 1,
        pageSize: 10,
        category,
        eventType: '',
      },
    });
    yield put({
      type: FETCH_AUTOMATION_COUNT,
    });
  } catch (e) {
    yield put({
      type: CREATE_AUTOMATION_RULE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updateAutomationRuleHandler(action) {
  const { category, id, ...request } = action.payload || {};
  try {
    const { data } = yield call(ConversationService.updateAutomationRule, request, id);

    yield put({
      type: UPDATE_AUTOMATION_RULE_SUCCESSFUL,
      payload: data,
    });

    yield put({
      type: FETCH_AUTOMATION_RULE_LIST,
      payload: {
        pageNumber: 1,
        pageSize: 10,
        category,
        eventType: '',
      },
    });
    yield put({
      type: FETCH_AUTOMATION_COUNT,
    });
  } catch (e) {
    yield put({
      type: UPDATE_AUTOMATION_RULE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* deleteAutomationRuleHandler(action) {
  const { id, category } = action?.payload || {};
  if (!id) return;
  try {
    const { data } = yield call(ConversationService.deleteAutomationRule, id);

    yield put({
      type: DELETE_AUTOMATION_RULE_SUCCESSFUL,
      payload: data,
    });

    yield put({
      type: FETCH_AUTOMATION_RULE_LIST,
      payload: {
        pageNumber: 1,
        pageSize: 10,
        category,
        eventType: '',
      },
    });
    yield put({
      type: FETCH_AUTOMATION_COUNT,
    });
  } catch (e) {
    yield put({
      type: DELETE_AUTOMATION_RULE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchAutomationCountHandler() {
  try {
    const { data } = yield call(ConversationService.getAutomationCount);
    yield put({
      type: FETCH_AUTOMATION_COUNT_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_AUTOMATION_COUNT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* reRunAIAnalysisHandler(action) {
  const { payload } = action;
  const { appointmentId, evaluationId, mode, cbId } = payload;
  try {
    yield call(ConversationService.reRunAIAnalysis, appointmentId, evaluationId, mode, cbId);
    console.log('Re-run AI analysis successful');
  } catch (e) {
    console.warn('Error occurred while re-running AI analysis', e);
  }
}

/**
 * @Name chatbotContactsFetcher
 * @param action as IAction
 * @description This method is used to get a list a chatbot contacts for a specific user alongwith their percentage completions
 */
function* chatbotContactsFetcher(action) {
  try {
    const { data } = yield call(ConversationService.getChatbotContacts, action.payload);

    yield put({
      type: FETCH_CHATBOT_CONTACTS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_CHATBOT_CONTACTS_FAILED,
      payload: { message },
    });
  }
}

/**
 * @Name chatbotAttemptHistoryFetcher
 * @param action as IAction
 * @description This method is used to get user chatbot attempt history summary
 */
function* chatbotAttemptHistoryFetcher(action) {
  try {
    const { data } = yield call(ConversationService.getChatbotAttemptHistory, action.payload);
    yield put({
      type: FETCH_CHATBOT_ATTEMPT_HISTORY_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_CHATBOT_ATTEMPT_HISTORY_FAILED,
      payload: { message },
    });
  }
}

/**
 * @Name profileElementsFetcher
 * @param action as IAction
 * @description This method is used to get all profile elements list
 */
function* profileElementsFetcher(action) {
  try {
    const { data } = yield call(ConversationService.getProfileElements, action.payload);

    yield put({
      type: FETCH_PROFILE_ELEMENTS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PROFILE_ELEMENTS_FAILED,
      payload: { message },
    });
  }
}

/**
 * @Name addProfileElementHandler
 * @param action as IAction
 * @description This method is used to add new profile element
 */
function* addProfileElementHandler(action) {
  const { payload, callback } = action.payload;
  try {
    yield call(ConversationService.addProfileElement, payload);
    yield put({ type: ADD_PROFILE_ELEMENT_SUCCESSFUL });
    yield callback();
  } catch (e) {
    yield put({
      type: ADD_PROFILE_ELEMENT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

/**
 * @Name updateProfileElementHandler
 * @param action as IAction
 * @description This method is used to update a profile element
 */
function* updateProfileElementHandler(action) {
  const { payload, profileElementId, callback } = action.payload;
  try {
    yield call(ConversationService.updateProfileElement, payload, profileElementId);
    yield put({ type: UPDATE_PROFILE_ELEMENT_SUCCESSFUL });
    yield callback();
  } catch (e) {
    yield put({
      type: UPDATE_PROFILE_ELEMENT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchDctsListHandler(action) {
  try {
    const { data } = yield call(ConversationService.getDctsList, action.payload);

    yield put({
      type: GET_DATA_COLLECTION_TEMPLATES_SUCCESSFUL,
      payload: { dcts: data.dcts, totalRecords: data.totalRecords },
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: GET_DATA_COLLECTION_TEMPLATES_FAILED,
      payload: { message },
    });
  }
}

function* fetchAssignedEvaluationsHandler(action) {
  try {
    const { data } = yield call(ConversationService.getAppointmentEvaluationProgress, action.payload);

    yield put({
      type: FETCH_ASSIGNED_EVALUATION_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_ASSIGNED_EVALUATION_FAILED,
      payload: { message },
    });
  }
}

function* createAssignedEvaluationHandler(action) {
  const { evaluationIds, appointmentId } = action.payload;
  try {
    const { data } = yield call(
      ConversationService.updateAppointmentAddMultipleEvaluations,
      { evaluationIds },
      { appointmentId },
    );

    yield put({
      type: ADD_ASSIGNED_EVALUATION_SUCCESSFUL,
      payload: {
        planItem: data,
      },
    });

    yield put({
      type: FETCH_ASSIGNED_EVALUATION,
      payload: {
        appointmentId,
      },
    });
  } catch (e) {
    yield put({
      type: ADD_ASSIGNED_EVALUATION_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* connectSbChat(token) {
  const auth = yield select(state => state.auth);
  const { userId } = auth.meta;
  const sendbirdChat = yield call(SendbirdChat.init.bind(SendbirdChat), {
    appId: getSendbirdAppId(),
    localCacheEnabled: true,
    modules: [new GroupChannelModule()],
  });
  console.log('Sendbird chat connecting');
  yield call(sendbirdChat.connect.bind(sendbirdChat), userId, token);
  sbChat = sendbirdChat;
}

function* initializeSendbird() {
  const { data } = yield call(AuthService.getSendbirdSessionToken);

  if (!sbChat) {
    yield call(connectSbChat, data.token);
  }

  fetchUnreadChannels();

  if (channelActionPlaceholder) {
    fetchSbChannelsByUrl(channelActionPlaceholder);
  }

  yield call(remainingInitialization, data.token);
}

function* remainingInitialization(token) {
  yield put({
    type: SENDBIRD_CONNECT,
    payload: {
      sessionToken: token,
    },
  });
  const auth = yield select(state => state.auth);
  yield put({
    type: SENDBIRD_CHANNELS_FETCHED,
    payload: {
      channels: [],
      initialFetch: true,
    },
  });
  yield put({
    type: FETCH_CHATS,
    payload: {
      userId: auth.meta.userId,
    },
  });
  yield take(FETCH_CHATS_SUCCESSFUL);
  yield fork(fetchUnreadChannels);
}

function* fetchSbChannelsByUrl(action) {
  if (!sbChat) {
    channelActionPlaceholder = action;
    return;
  }
  channelActionPlaceholder = null;
  const { type } = action;
  let fetchedData;
  if (type === FETCH_USER_GROUPS_SUCCESSFUL) {
    fetchedData = action.payload;
  } else {
    fetchedData = action.payload.connections;
  }
  const channelUrls = fetchedData.map(channel => channel.channelUrl);
  if (channelUrls.length > 0) {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;
    groupChannelFilter.channelUrlsFilter = channelUrls;

    const collection = sbChat.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = yield call(collection.loadMore.bind(collection));
    yield put({
      type: SENDBIRD_CHANNELS_FETCHED,
      payload: {
        channels,
      },
    });
    while (collection.hasMore) {
      const newChannels = yield call(collection.loadMore.bind(collection));
      yield put({
        type: SENDBIRD_CHANNELS_FETCHED,
        payload: {
          channels: newChannels,
        },
      });
    }
  }
}

function* deleteMemberConnection(action) {
  try {
    const meta = yield select(state => state.auth.meta);
    const { status } = yield call(ConversationService.deleteMemberConnection, { userId: action.payload });
    if (status === 200) {
      yield put({
        type: FETCH_CHATS,
        payload: {
          userId: meta?.userId,
        },
      });
      yield put(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Connection with member deleted successfully',
        }),
      );
    }
  } catch (e) {
    const message = e?.data?.errors[0].endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
  }
}

function* addContentBlockHandler(action) {
  try {
    const { data } = yield call(ConversationService.addContentBlockInLibrary, action.payload);

    yield put({
      type: ADD_CONTENT_BLOCK_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: ADD_CONTENT_BLOCK_FAILED,
      payload: { message },
    });
  }
}

function* getLibraryContentBlockHandler(action) {
  try {
    const queryParams = action.payload;
    const queryParams2 = {
      ...queryParams,
      types: queryParams?.types !== undefined ? queryParams.types.toLowerCase().replaceAll(' ', '-') : '',
    };
    const { data } = yield call(ConversationService.getContentBlockInLibrary, queryParams2);
    yield put({
      type: FETCH_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_LIBRARY_CONTENT_BLOCKS_FAILED,
      payload: { message },
    });
  }
}

function* updateLibraryContentBlockHandler(action) {
  try {
    const { data, contentBlockId } = action.payload;
    const { response } = yield call(ConversationService.updateContentBlockInLibrary, data, contentBlockId);
    yield put({
      type: UPDATE_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL,
      payload: response,
    });
    // yield put({ type: FETCH_LIBRARY_CONTENT_BLOCKS });
  } catch (e) {
    console.warn(e);
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_LIBRARY_CONTENT_BLOCKS_FAILED,
      payload: { message },
    });
  }
}

function* deleteLibraryContentBlockHandler(action) {
  try {
    const contentBlockId = action.payload;
    const { response } = yield call(ConversationService.deleteContentBlockInLibrary, contentBlockId);
    yield put({
      type: DELETE_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL,
      payload: response,
    });
    // yield put({ type: FETCH_LIBRARY_CONTENT_BLOCKS });
  } catch (e) {
    console.warn(e);
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: DELETE_LIBRARY_CONTENT_BLOCKS_FAILED,
      payload: { message },
    });
  }
}

function* deleteEvaluationHandler(action) {
  try {
    const { appointmentId, evaluationId } = action.payload;
    const { response } = yield call(ConversationService.deleteEvaluation, appointmentId, evaluationId);
    yield put({
      type: DELETE_EVALUATION_SUCCESSFUL,
      payload: response,
    });
    yield put(showSnackbar({ snackType: 'success', snackMessage: 'Evaluations deleted successfully' }));
    yield put({ type: FETCH_ASSIGNED_EVALUATION, payload: { appointmentId } });
  } catch (e) {
    console.warn(e);
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: DELETE_EVALUATION_FAILED,
      payload: { message },
    });
  }
}

export function* conversationSaga() {
  yield takeLatest(FETCH_PLAN_ITEMS, fetchPlanItemHandler);
  yield takeLatest(CREATE_PLAN_ITEM, createPlanItemHandler);
  yield takeLatest(UPDATE_PLAN_ITEM, updatePlanItemHandler);
  yield takeLatest(DELETE_PLAN_ITEM, deletePlanItemHandler);
  yield takeLatest(FETCH_ASSIGNED_PLAN_ITEMS, fetchAssignedPlanItemHandler);
  yield takeLatest(ADD_ASSIGNED_PLAN_ITEMS, addAssignedPlanItemHandler);
  yield takeLatest(FETCH_CONVERSATIONS, fetchConversationHandler);
  yield takeLatest(FETCH_EVALUATIONS, fetchEvaluationsHandler);
  yield takeLatest(FETCH_REVAMP_TYPES_LIST, fetchRevampTypesHandler);
  yield takeLatest(FETCH_EVALUATION_CONTEXT, fetchEvaluationContextHandler);
  yield takeLatest(FETCH_ASSOCIATED_TAG, fetchAssociatedTagHandler);
  yield takeLatest(FETCH_CHATBOT_CONTACTS, chatbotContactsFetcher);
  yield takeLatest(FETCH_CHATBOT_ATTEMPT_HISTORY, chatbotAttemptHistoryFetcher);
  yield takeLatest(FETCH_PROFILE_ELEMENTS, profileElementsFetcher);
  yield takeLatest(ADD_PROFILE_ELEMENT, addProfileElementHandler);
  yield takeLatest(UPDATE_PROFILE_ELEMENT, updateProfileElementHandler);
  yield takeLatest(GET_DATA_COLLECTION_TEMPLATES, fetchDctsListHandler);
  yield takeLatest(FETCH_ASSIGNED_EVALUATION, fetchAssignedEvaluationsHandler);
  yield takeLatest(ADD_ASSIGNED_EVALUATION, createAssignedEvaluationHandler);
  yield takeLatest(FETCH_TAGS, fetchTagsHandler);
  yield takeLatest(FETCH_AUTOMATION_RULE_LIST, fetchAutomationRulesHandler);
  yield takeLatest(CREATE_AUTOMATION_RULE, createAutomationRuleHandler);
  yield takeLatest(UPDATE_AUTOMATION_RULE, updateAutomationRuleHandler);
  yield takeLatest(DELETE_AUTOMATION_RULE, deleteAutomationRuleHandler);
  yield takeLatest(FETCH_AUTOMATION_COUNT, fetchAutomationCountHandler);
  yield takeLatest(RE_RUN_AI_ANALYSIS, reRunAIAnalysisHandler);
  yield takeLatest(SENDBIRD_INIT, initializeSendbird);
  yield takeLatest(SENDBIRD_FETCH_UNREAD_CHANNELS, fetchUnreadChannels);
  yield takeLatest(USER_LOGOUT, clearSbConnectionWatcher);
  yield takeLatest([SENDBIRD_CONNECT, SENDBIRD_RECONNECT], connectSendBird);
  yield takeEvery(
    [
      MEMBER_CONNECTIONS_FETCHED,
      PROVIDER_CONNECTIONS_FETCHED,
      CARETEAM_CONNECTIONS_FETCHED,
      CARE_NAVIGATOR_CONNECTIONS_FETCHED,
      ALL_CONNECTIONS_FETCHED,
      FETCH_USER_GROUPS_SUCCESSFUL,
      SEARCH_CHATS_SUCCESS,
    ],
    fetchSbChannelsByUrl,
  );
  yield takeLatest(CREATE_TODO, createToDoHandler);
  yield takeLatest(FETCH_ALL_TODO, fetchAllToDoHandler);
  yield takeLatest(FETCH_AI_JOBS, fetchAIJobsHandler);
  yield takeLatest(ADD_CONTENT_BLOCK, addContentBlockHandler);
  yield takeLatest(FETCH_LIBRARY_CONTENT_BLOCKS, getLibraryContentBlockHandler);
  yield takeLatest(UPDATE_LIBRARY_CONTENT_BLOCKS, updateLibraryContentBlockHandler);
  yield takeLatest(DELETE_LIBRARY_CONTENT_BLOCKS, deleteLibraryContentBlockHandler);
  yield takeLatest(DELETE_EVALUATION, deleteEvaluationHandler);
  yield takeEvery(callbackChannel, dispatchToStore);
  yield takeEvery(DELETE_MEMBER_CONNECTION, deleteMemberConnection);
  yield takeEvery(LOAD_MORE_MESSAGES, loadMoreMessagesHandler);
}
