import { FC } from 'react';
import { Box } from '@mui/material';

import { fontWeight, Heading, headingLevel, Text } from '../../../../../../../packages/ui/atoms/typography';
import { iconBtnType, IconButton } from '../../../../../../../packages/ui/molecules/icon-button';
import { Icons } from '../../../../../../../packages/icons';
import { useStyles } from './EvaluationRejected.styles';

const EvaluationRejected = ({ onClickView, selectedEvaluation }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapIcon}>
        <Icons glyph="clear-circle" className={classes.icon} />
      </Box>
      <Heading level={headingLevel.S} className={classes.title} weight={fontWeight.BOLD}>
        {selectedEvaluation?.name} rejected!
      </Heading>
      <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
        {selectedEvaluation?.description}
      </Text>
      <IconButton onClick={onClickView} variant={iconBtnType.OUTLINE} className={classes.viewBtn}>
        View evaluation
      </IconButton>
    </Box>
  );
};

export { EvaluationRejected };
