// colors
import { colors } from '../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  btnBack: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 48,
    height: 48,
    padding: 0,
    minWidth: 0,
    marginRight: 16,
  },
  filtersWrap: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.neutral100}`,
    maxHeight: '80px',
  },
  dflex: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  btn: {
    color: colors.white,
    height: 48,
    padding: '20px 22px',
    borderRadius: 6,
    fontSize: 16,
  },
  backBtn: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 48,
    height: 48,
    padding: 0,
    minWidth: 0,
    marginRight: 16,
  },
  endBtn: {
    backgroundColor: colors.destructive50,
    color: colors.destructive600,
    '&:hover': {
      backgroundColor: colors.destructive50,
      color: colors.destructive500,
    },
  },
  signOffBtn: {
    backgroundColor: colors.primary500,
    '&:hover': {
      backgroundColor: colors.primary600,
    },
    '& svg': {
      marginRight: 8,
      minWidth: 20,
      width: 20,
    },
  },
  time: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
    margin: 0,
    fontWeight: 600,
  },
  timeRed: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.textError,
    margin: 0,
    fontWeight: 1000,
  },
  btnGroupIcon: {
    color: colors.neutral700,
    backgroundColor: 'transparent',
    padding: '14px 18px',
    transition: '0s',
    fontSize: 14,
    height: 48,
    border: `1px solid ${colors.neutral200}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
      border: `1px solid ${colors.neutral900}`,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    border: `1px solid ${colors.neutral900}`,
    backgroundColor: colors.neutral900,
  },
  badge: {
    height: 18,
    width: 35,
    fontSize: 12,
    lineHeight: '16px',
    color: colors.secondary500,
    border: `1px solid ${colors.secondary300}`,
    borderRadius: '2px !important',
    marginLeft: 12,
  },
  badgeActive: {
    border: 'none',
  },
  colLabel2: {
    display: 'flex',
    flexDirection: 'column',
  },
});
