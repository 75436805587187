import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, Rating } from '@mui/material';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  Badge,
  badgeStyle,
  badgeType,
  btnSize,
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  iconBtnType,
  IconButton,
  Icons,
  Text,
  Textarea,
} from '../../../../../packages';
import { EVALUATION_QUESTION_TYPES } from '../../../../../constants/CommonConstants';
import { ContentfulClient } from '../../../../../config/contentful-config';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showSnackbar } from '../../../../../redux/modules/snackbar';
import EvaluationPrevHospitalization from '../../evaluations/evaluation-section-detail/components/evaluation-prev-hospitalization';
import EvaluationEducation from '../../evaluations/evaluation-section-detail/components/evaluation-education';
import { EvaluationEducationDetail } from '../../evaluations/evaluation-section-detail/components/evaluation-education/EvaluationEducationDetail';
import { useStyles } from './EvaluationRightSection.styles';
import { EvaluationRatingScale } from '../../evaluations/evaluation-section-detail/components/evaluation-rating-scale/EvaluationRatingScale';
import EvaluationTextInput from '../../evaluations/evaluation-section-detail/components/evaluation-text-input';
import { Prompt } from '../../evaluations/evaluation-section-detail/components/prompt-accordion/Prompt';
import { RelatedElementsSection } from '../../evaluations/evaluation-section-detail/components/related-elements/RelatedElementsSection';
import EvaluationExercise from '../../evaluations/evaluation-section-detail/components/evaluation-exercise';
import useEvaluationHead from '../../../../../hooks/useEvaluationHead';
import {
  applyPreviousDCTAnswers,
  fetchDctPreviousResponses,
  updateEvaluationContext,
} from '../../../../../services/conversation/conversation.service';
import { selectEvaluationContext } from '../../../../../redux/modules/conversation/selectors';
import { conversationActionCreators } from '../../../../../redux/modules/conversation';
import Typography from '../../../../../components/v2/Typography';
import { typography } from '../../../../../components/v2/Typography/index.constant';
import { MultiSelectOptions } from '../../in-person-session/MultiSelectOptions';

const EvaluationRightSection = ({
  currentBlock,
  setCurrentBlock,
  isDisablePrevious,
  onClickNext,
  onClickPrevious,
  skipNonRequiredCB,
  appointment,
  currentEvaluation,
  listHelper,
  setShowEvaluationReview,
  setInputFocused,
  selectedIndex,
  rightTrigger,
  leftTrigger,
  isSignOffFlow,
  setIsRead,
  isRead,
  childIndex,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { evaluationId, appointmentId } = useParams();
  const bodyRef = useRef(null);
  const blockRef = useRef([]);
  const [fullPageDctCBs, setFullPageDctCBs] = useState(null);
  const [showEducation, setShowEducation] = useState(false);
  const [showAddText, setShowAddText] = useState(false);
  const [educationalContent, setEducationalContent] = useState();
  const [educationalContentTextOnly, setEducationalContentTextOnly] = useState('');
  const [title, setTitle] = useState('');
  const [multiSelectChange, setMultiSelectChange] = useState(false);
  const [duration, setDuration] = useState(null);
  const { updateValues } = useEvaluationHead();
  const [footerHeight, setFooterHeight] = useState(0);
  const [prevResponsesHeight, setPrevResponsesHeight] = useState(0);
  const [isReview, setIsReview] = useState(true);
  const [isChangeAnswer, setIsChangeAnswer] = useState(false);
  const [changedAnswer, setChangedAnswer] = useState(null);
  const [allRiskFactorsFalse, setAllRiskFactorsFalse] = useState(false);
  const [dctPreviousResponses, setDctPreviousResponses] = useState([]);
  const [previousResponses, setPreviousResponses] = useState([]);
  const [narrativeVisibility, setNarrativeVisibility] = useState({});
  const evaluationContext = useSelector(selectEvaluationContext);

  useEffect(() => {
    if (currentBlock?.fullPage) {
      const newData = currentBlock?.children.map(item => {
        const filteredCBs = fullPageDctCBs?.filter(cb => cb.cbId === item.cbId);
        let isReviewValue = true;
        if (filteredCBs) {
          isReviewValue = filteredCBs[0]?.isReview;
        }
        return { ...item, isReview: isReviewValue };
      });
      setFullPageDctCBs(newData);
    }
  }, [currentBlock]);

  const isRiskFactorInvolved = (question, responses) => {
    if (question.cbType !== 'single-select' && question.cbType !== 'multi-select') {
      return false;
    }
    const riskFactorChoices = question?.choices?.filter(choice => choice.riskFactor);
    const matchingResponse = responses?.find(res => res.cbId === question.cbId);
    if (matchingResponse && matchingResponse?.answers.length > 0) {
      if (riskFactorChoices.length === 0) {
        return false;
      }
      return matchingResponse?.answers.some(answer => {
        const found = riskFactorChoices?.find(
          choice =>
            choice?.choice?.toLowerCase() === answer?.value?.toLowerCase() ||
            choice?.value?.toLowerCase() === answer?.value?.toLowerCase(),
        );
        return !!found;
      });
    }
    return true;
  };

  const getPreviousResponses = async () => {
    // if (currentBlock.cbType === 'dct' && currentBlock.children.length > 0) {
    const queryParams = {
      evaluationId: evaluationContext?.evaluationId,
      userId: appointment?.participantId,
      practitionerId: appointment?.practitionerId,
      appointmentId: appointment?.appointmentId,
      dctId: currentBlock?.dctId,
    };

    if (currentBlock.cbType !== 'dct') {
      delete queryParams.dctId;
      queryParams.cbId = currentBlock?.cbId;
    }

    try {
      const response = await fetchDctPreviousResponses(queryParams);
      const cbResponseList = response?.data?.cbResponseList || [];

      if (currentBlock.cbType === 'dct') {
        setDctPreviousResponses(cbResponseList);
        const hasRiskFactor = currentBlock.children?.some(child =>
          isRiskFactorInvolved(child, cbResponseList),
        );
        setAllRiskFactorsFalse(!hasRiskFactor);
      } else setPreviousResponses(cbResponseList);
    } catch (error) {
      console.log({ error });
    }
    // }
  };

  const usePreviousResponse = () => {
    if (
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.TEXT_INPUT ||
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.RATING_SCALE
    ) {
      dispatchUserResponse(previousResponses[0]?.answers[0]?.value);
      currentBlock.evaluationResponses = [previousResponses[0]?.answers[0]?.value];
      setMultiSelectChange(!multiSelectChange);
    } else if (
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT ||
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT
    ) {
      const choices = currentBlock?.choices
        .filter(ch => {
          return multiSelectElementValues?.find(el => el.value === ch?.choice);
        })
        .map(ch => ch.choice);
      if (choices.length > 0) {
        if (currentBlock.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT) {
          dispatchUserResponse(choices[0]);
          currentBlock.evaluationResponses = [choices[0]];
        } else {
          choices.forEach(dispatchUserResponse);
          currentBlock.evaluationResponses = choices;
        }
        setMultiSelectChange(!multiSelectChange);
      }
    }
  };

  const useAllPreviousResponses = async () => {
    if (allRiskFactorsFalse && currentBlock.cbType === 'dct') {
      const requestBody = {
        appointmentId: appointment?.appointmentId,
        evaluationId: currentEvaluation?.id,
        userId: appointment?.participantId,
        dctId: currentBlock.dctId,
        practitionerId: appointment?.practitionerId,
      };

      try {
        const response = await applyPreviousDCTAnswers(requestBody);
        if (response?.status === 200) {
          const nextDCT = listHelper.getNextDct(currentBlock.cbId);
          if (nextDCT) {
            setCurrentBlock(nextDCT);
          } else {
            setShowEvaluationReview(true);
          }
          dispatch(
            conversationActionCreators.fetchEvaluationContext({
              appointmentId: appointment?.appointmentId,
              evaluationId: currentEvaluation?.id,
            }),
          );
        }
      } catch (error) {
        console.log({ error });
      }
    }
  };

  useEffect(() => {
    if (appointment) {
      void getPreviousResponses();
    }
  }, [currentBlock.cbId, appointment]);

  useEffect(() => {
    setIsChangeAnswer(false);
    if (currentBlock?.userResponse?.answeredBy === 'AI') {
      setIsReview(true);
    } else {
      setIsReview(false);
    }
  }, [currentBlock]);

  /**
   * @Name toggleShowEducation
   * @description This method is used to toggle between showing Education Component .
   */
  const toggleShowEducation = () => {
    setShowEducation(!showEducation);
  };

  /**
   * @Name toggleShowAddText
   * @description This method is used to toggle Add Text component .
   */
  const toggleShowAddText = () => {
    setShowAddText(!showAddText);
  };

  const dispatchUserResponse = (value, block = null) => {
    if (isChangeAnswer) {
      setChangedAnswer({ id: currentBlock.cbId, value, blockId: block ? block.cbId : null });
    } else {
      updateValues({ id: currentBlock.cbId, value, blockId: block ? block.cbId : null });
    }
  };

  const forceRender = () => {
    setMultiSelectChange(!multiSelectChange);
  };

  const getRatingValue = (block = null) => {
    if (block && block.userResponse && block.userResponse.userResponseList.length > 0) {
      return parseInt(block.userResponse.userResponseList[0], 10);
    }
    return null;
  };

  const handleKeyDown = e => {
    if (
      (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') &&
      currentBlock.cbType !== 'text-input'
    ) {
      e.preventDefault();
    }
  };

  const handleUserResponse = (selectedBlock, direction) => {
    const dispatchResponse = value => {
      if (currentBlock.fullPage) dispatchUserResponse(value, selectedBlock);
      else dispatchUserResponse(value);
    };

    if (selectedBlock.cbType === 'rating-scale') {
      if (selectedBlock.userResponse && selectedBlock.userResponse.userResponseList.length > 0) {
        const ratingValue = getRatingValue(selectedBlock);
        if (direction === 'right' && ratingValue < selectedBlock.ratingScale?.values?.length) {
          dispatchResponse(ratingValue + 1);
        } else if (direction === 'left' && ratingValue > 0) {
          dispatchResponse(ratingValue - 1);
        }
      } else {
        dispatchResponse(1);
      }
    } else if (selectedBlock.choices && selectedBlock.choices.length > 0 && selectedIndex >= 0) {
      const value = selectedBlock?.choices[selectedIndex]?.choice;
      if (
        (direction === 'right' &&
          (!selectedBlock.userResponse ||
            !selectedBlock.userResponse.userResponseList ||
            selectedBlock.userResponse.userResponseList.length === 0 ||
            !selectedBlock.userResponse.userResponseList.includes(value))) ||
        (direction === 'left' &&
          selectedBlock.userResponse &&
          selectedBlock.userResponse.userResponseList &&
          selectedBlock.userResponse.userResponseList.length > 0 &&
          selectedBlock.userResponse.userResponseList.includes(value))
      ) {
        dispatchResponse(value);
      }
    }
  };

  const selectedBlock = useMemo(() => {
    if (currentBlock.cbType === 'dct' && currentBlock.fullPage) {
      return currentBlock.children.filter(
        block => block.cbType !== 'education' && block.cbType !== 'exercise',
      )[childIndex];
    }
    return currentBlock;
  }, [currentBlock.cbId, childIndex]);

  useEffect(() => {
    if (selectedBlock) {
      handleUserResponse(selectedBlock, 'right');
    }
  }, [rightTrigger]);

  useEffect(() => {
    if (selectedBlock && selectedBlock.cbType !== 'single-select') {
      handleUserResponse(selectedBlock, 'left');
    }
  }, [leftTrigger]);

  useEffect(() => {
    scrollToSelectedBlock();
  }, [childIndex]);

  const toggleNarrativeVisibility = cbId => {
    setNarrativeVisibility(prevState => ({
      ...prevState,
      [cbId]: !prevState[cbId],
    }));
  };

  const scrollToSelectedBlock = () => {
    if (blockRef.current[childIndex]) {
      blockRef.current[childIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const renderDetail = (block = null, idx) => {
    const contentBlock = block ?? currentBlock;
    switch (contentBlock.cbType) {
      case EVALUATION_QUESTION_TYPES.SINGLE_SELECT:
        return (
          <EvaluationPrevHospitalization
            selectedSection={contentBlock}
            options={contentBlock?.choices?.map(item => ({
              label: item?.choice,
              value: item?.choice,
            }))}
            forceRender={forceRender}
            removeExtraHeight
            onChange={e => dispatchUserResponse(e, block)}
            scrollRef={bodyRef}
            selectedIndex={selectedIndex}
            isSelectedBlock={childIndex === idx}
            fullPageMode={currentBlock.fullPage}
          />
        );
      case EVALUATION_QUESTION_TYPES.MULTI_SELECT:
        return contentBlock.choices.map((item, index) => (
          <MultiSelectOptions
            answerTitle={item?.choice}
            value={item?.choice}
            key={index}
            scrollRef={bodyRef}
            currentBlock={contentBlock}
            onChange={e => dispatchUserResponse(e, block)}
            focusedChoice={
              typeof idx !== 'undefined'
                ? index === selectedIndex && idx === childIndex
                : index === selectedIndex
            }
            fullPageMode={currentBlock.fullPage}
          />
        ));
      case EVALUATION_QUESTION_TYPES.EXERCISE:
        return <EvaluationExercise selectedSection={contentBlock?.exerciseContent} />;
      case EVALUATION_QUESTION_TYPES.EDUCATION:
        return (
          <EvaluationEducation
            selectedSection={{ ...contentBlock, duration, title }}
            onClickDetail={toggleShowEducation}
            setIsRead={value => setIsRead(value)}
          />
        );
      case EVALUATION_QUESTION_TYPES.RATING_SCALE:
        return (
          <EvaluationRatingScale
            getRatingValue={e => getRatingValue(contentBlock)}
            selectedSection={contentBlock}
            forceRender={forceRender}
            onChange={e => dispatchUserResponse(e, block)}
            hotkeysEnabled
          />
        );
      case EVALUATION_QUESTION_TYPES.TEXT_INPUT:
        return (
          <EvaluationTextInput
            id={contentBlock.cbId}
            selectedSection={contentBlock}
            forceRender={forceRender}
            setInputFocused={setInputFocused}
            removeExtraHeight
            onChange={e => dispatchUserResponse(e, block)}
          />
        );
      default:
        return <></>;
    }
  };

  const updateAiAnswers = async (block, currentCB) => {
    const payload = {
      contentBlockId: block.cbId,
      dctId: block.dctId,
      evaluationResponses: block?.evaluationResponses ?? block?.userResponse?.userResponseList,
      providerNarrative: block?.userResponse?.providerNarrative || '',
    };
    try {
      const { data } = await updateEvaluationContext(payload, {
        appointmentId,
        evaluationId,
      });
      if (data.errors) {
        console.warn(data.errors);
      } else {
        if (!currentCB?.isReview) updateIsReview(block?.cbId);
        listHelper.transformEvaluation(data);
        dispatch(
          conversationActionCreators.evaluationContextUpdated({
            evaluationContext: data,
            linkedEvaluationNodes: listHelper.getHead(),
          }),
        );
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: `${currentCB?.isReview ? 'Approved' : 'Saved'} successfully.`,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateIsReview = id => {
    setFullPageDctCBs(prevState =>
      prevState.map(block => {
        if (block.cbId === id) {
          return { ...block, isReview: !block.isReview };
        }
        return block;
      }),
    );
  };

  const renderFullPageDCT = () => {
    return currentBlock.children
      .filter(block => block.cbType !== 'education' && block.cbType !== 'exercise')
      .map((block, idx) => {
        const { hasNarrative, hasRelatedElements, hasRationale, hasQuote } = getContentBlockElements(block);

        if (hasNarrative && !narrativeVisibility[block.cbId]) toggleNarrativeVisibility(block.cbId);
        let fullPageCurrentCB = fullPageDctCBs?.filter(i => i?.cbId === block?.cbId);
        fullPageCurrentCB = fullPageCurrentCB ?? [{}];
        const isAnsweredByAi = block?.userResponse?.answeredBy === 'AI';

        return (
          <Box key={idx} display="flex" flexDirection="column" gap={2} className={classes.dctBlock}>
            <Box className={classes.header}>
              {renderBadges(block)}
              <Box display="flex" flexDirection="column" gap={1}>
                <Heading weight={fontWeight.BOLD} className={classes.title}>
                  {block?.text}
                </Heading>
                {block?.subtitle?.length > 0 && (
                  <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
                    {block?.subtitle}
                  </Text>
                )}
              </Box>
            </Box>
            {(block?.prompt?.length > 0 || hasRelatedElements) && (
              <Box display="flex" flexDirection="column" gap={2}>
                {block?.prompt?.length > 0 && <Prompt label="Prompt" text={block?.prompt} />}
                {hasRelatedElements && (
                  <RelatedElementsSection label="Related Profile Elements">
                    {block?.cbProfileElementDetails?.map(profileElementDetail => {
                      return (
                        <Box display="flex" flexDirection="column" gap={0.5} key={profileElementDetail.key}>
                          <Text weight={fontWeight.MEDIUM} className={classes.infoKey}>
                            {profileElementDetail.key}
                          </Text>
                          <Text className={classes.infoValue}>
                            {profileElementDetail.valuesList?.map(v => v.value).join(', ')}
                          </Text>
                        </Box>
                      );
                    })}
                  </RelatedElementsSection>
                )}
              </Box>
            )}
            <Box
              ref={el => {
                blockRef.current[idx] = el;
              }}
              display="flex"
              flexDirection="column"
              gap={block.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT ? 0 : 2}
            >
              {fullPageCurrentCB[0]?.isReview && isAnsweredByAi
                ? renderAiResponse(block)
                : renderDetail(block, idx)}
              {!narrativeVisibility[block.cbId] && !hasNarrative && (
                <Box
                  className={classes.addPersonalNote}
                  paddingBottom={hasRationale || hasQuote ? '12px' : '0px'}
                >
                  <IconButton icon="plus" onClick={e => toggleNarrativeVisibility(block.cbId)}>
                    Add narrative
                  </IconButton>
                </Box>
              )}
              {(hasNarrative || narrativeVisibility[block.cbId]) && (
                <Box className={classes.section}>
                  <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.SEMI_BOLD}>
                    Narrative
                  </Heading>
                  <Textarea
                    value={block?.userResponse?.providerNarrative ?? ''}
                    name="text"
                    onBlur={() => setInputFocused(false)}
                    onFocus={() => setInputFocused(true)}
                    onChange={e => {
                      const trimmedValue = e.target.value.trimStart();
                      updateValues({ id: currentBlock.cbId, narrative: trimmedValue, blockId: block.cbId });
                      setMultiSelectChange(!multiSelectChange);
                    }}
                    minRows={5}
                    placeholder="Enter text"
                  />
                  <Box className={classes.addPersonalNote}>
                    <IconButton
                      icon="minus"
                      onClick={() => {
                        updateValues({ id: currentBlock.cbId, narrative: '', blockId: block.cbId });
                        toggleNarrativeVisibility(block.cbId);
                      }}
                      className={classes.redIcon}
                    >
                      Remove narrative
                    </IconButton>
                  </Box>
                </Box>
              )}
              {(hasRationale || hasQuote) && (
                <Box paddingTop="12px" paddingBottom="12px">
                  {hasRationale && (
                    <Box display="flex" gap={2}>
                      <Box className={classes.iconContainer} sx={{ backgroundColor: colors.chipBackColor5 }}>
                        <Icons glyph="wand" color={colors.secondary} />
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Heading
                          level={headingLevel.S}
                          className={classes.label}
                          weight={fontWeight.SEMI_BOLD}
                        >
                          AI rationale
                        </Heading>
                        <Box>{block?.userResponse?.reason}</Box>
                      </Box>
                    </Box>
                  )}
                  {hasQuote && (
                    <Box display="flex" gap={2}>
                      <Box className={classes.iconContainer} sx={{ backgroundColor: colors.primary50 }}>
                        <Icons glyph="ai-quote" color={colors.primary} />
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Heading
                          level={headingLevel.S}
                          className={classes.label}
                          weight={fontWeight.SEMI_BOLD}
                        >
                          Quote
                        </Heading>
                        <Box>{block?.userResponse?.quote}</Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {isAnsweredByAi && (
                <Box sx={{ display: 'flex', gap: '24px', justifyContent: 'flex-end' }}>
                  {fullPageCurrentCB[0]?.isReview && (
                    <IconButton
                      variant={iconBtnType.SECONDARY}
                      onClick={() => updateIsReview(block?.cbId)}
                      className={classes.changeButton}
                    >
                      Change answer
                    </IconButton>
                  )}
                  {!fullPageCurrentCB[0]?.isReview && (
                    <IconButton
                      variant={iconBtnType.SECONDARY}
                      onClick={() => updateIsReview(block?.cbId)}
                      className={classes.changeButton}
                    >
                      Cancel
                    </IconButton>
                  )}
                  <IconButton
                    variant={iconBtnType.PRIMARY}
                    onClick={() => updateAiAnswers(block, fullPageCurrentCB[0])}
                    className={classes.nextBtn}
                    // onKeyPress={handleKeyPress}
                  >
                    {fullPageCurrentCB[0]?.isReview ? 'Approve' : 'Save'}
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        );
      });
  };

  const renderAiResponse = (block = null) => {
    const contentBlock = block ?? currentBlock;
    if (
      contentBlock.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT ||
      contentBlock.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT
    ) {
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          {contentBlock?.choices?.map((option, index) => {
            const isMatch = contentBlock?.userResponse?.userResponseList?.includes(option?.choice);
            return (
              <Box key={index} display="flex" gap={1} alignItems="center">
                <Box
                  sx={{
                    height: 24,
                    width: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {isMatch ? (
                    <Icons glyph="checkmark" color={colors.neutral700} />
                  ) : (
                    <Box
                      sx={{
                        height: 8,
                        width: 8,
                        borderRadius: '50%',
                        background: colors.neutral400,
                      }}
                    />
                  )}
                </Box>
                <Typography
                  {...typography.body.m.regular}
                  color={isMatch ? colors.neutral900 : colors.neutral500}
                >
                  {option?.choice}
                </Typography>
              </Box>
            );
          })}
        </Box>
      );
    }
    if (contentBlock.cbType === EVALUATION_QUESTION_TYPES.TEXT_INPUT) {
      return (
        <Textarea value={contentBlock?.userResponse?.userResponseList[0]} name="text" minRows={1} disabled />
      );
    }
    if (contentBlock.cbType === EVALUATION_QUESTION_TYPES.RATING_SCALE) {
      const checkAnswer = (() => {
        let ratingValue = 0;
        if (contentBlock.evaluationResponses) {
          if (contentBlock?.ratingScale?.values[0] === 0) {
            let value = parseInt(contentBlock.evaluationResponses[0], 10);
            ratingValue = value++;
          } else {
            ratingValue = parseInt(contentBlock.evaluationResponses[0], 10);
          }
        }
        if (contentBlock.userResponse && contentBlock.userResponse?.userResponseList.length > 0) {
          if (contentBlock?.ratingScale?.values[0] === 0) {
            let value = parseInt(contentBlock.userResponse.userResponseList[0], 10);
            ratingValue = value++;
          } else {
            ratingValue = parseInt(contentBlock.userResponse.userResponseList[0], 10);
          }
        }
        return ratingValue;
      })();
      return (
        <Rating
          value={checkAnswer}
          max={contentBlock?.ratingScale?.values?.length}
          defaultValue={0}
          disabled
        />
      );
    }
    return null;
  };
  /**
   * @Name onClickNextEvaluationResponseHandler
   * @description This method is used to move to next question by adding isDone flag .
   */
  const onClickNextEvaluationResponseHandler = () => {
    if (currentBlock.evaluationResponses?.length > 0) {
      setMultiSelectChange(!multiSelectChange);
    } else if (!currentBlock.required && currentBlock.cbType !== 'dct') {
      skipNonRequiredCB(currentBlock?.cbId);
    }
    if ((currentBlock.cbType === 'exercise' || currentBlock.cbType === 'education') && isRead) {
      updateValues({ id: currentBlock.cbId, narrative: '', value: ['true'] });
    }
    onClickNext();
  };

  const onClickApprove = () => {
    setIsChangeAnswer(false);
    onClickNext();
  };

  const onChangeAnswer = () => {
    setIsChangeAnswer(true);
    setIsReview(false);
  };

  const onCancelClick = () => {
    setIsChangeAnswer(false);
    setIsReview(true);
  };

  const onSaveClick = () => {
    if (changedAnswer) {
      updateValues(changedAnswer);
    }
    setIsChangeAnswer(false);
    onClickNext();
  };

  const handleKeyPress = event => {
    if ((event?.charCode === 13 || event?.keyCode === 13) && event?.ctrlKey) {
      onClickNextEvaluationResponseHandler();
    }
  };

  const onClickPreviousHandler = () => {
    setMultiSelectChange(!multiSelectChange);
    onClickPrevious();
  };

  const getEducationalContent = () => {
    ContentfulClient.getEntry(currentBlock?.educationContentSlug)
      .then(educationalContentRecord => {
        setTitle(educationalContentRecord?.fields?.title);
        setDuration(educationalContentRecord?.fields?.contentLengthduration);
        const formatedData = documentToReactComponents(educationalContentRecord?.fields?.content);
        setEducationalContent(formatedData);
      })
      .catch(err => {
        console.log('Error', err);
      });
  };

  const handleScroll = () => {
    const eRef = bodyRef?.current;
    const scrollPercentage = (eRef.scrollTop / (eRef.scrollHeight - eRef.clientHeight)) * 100;
    if (scrollPercentage >= 80) {
      setIsRead(true);
    }
  };

  useEffect(() => {
    if (currentBlock?.educationContentSlug) {
      getEducationalContent();
    }
  }, [currentBlock?.educationContentSlug]);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
      setIsRead(false);
    }
    setShowAddText(false);
    setNarrativeVisibility({});
  }, [currentBlock?.cbId]);

  useEffect(() => {
    const eRef = bodyRef?.current;
    const isScrollEnabled = eRef?.scrollHeight > eRef?.clientHeight;
    if (isScrollEnabled === false && currentBlock?.cbType === 'exercise') {
      setIsRead(true);
    }
    if (eRef && currentBlock?.cbType === 'exercise' && isScrollEnabled) {
      eRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (eRef && isScrollEnabled && currentBlock?.cbType === 'exercise') {
        eRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [bodyRef, currentBlock]);

  useEffect(() => {
    if (showEducation) setShowEducation(false);
  }, [currentBlock]);

  if (showEducation) {
    return (
      <EvaluationEducationDetail
        title={title}
        duration={duration}
        educationalContent={educationalContent}
        educationalContentTextOnly={educationalContentTextOnly}
        onClose={toggleShowEducation}
        selectedSection={currentBlock}
      />
    );
  }

  const getContentBlockElements = block => {
    const hasNarrative = block?.userResponse?.providerNarrative?.length > 0;
    const hasRationale = block?.userResponse?.reason?.length > 0;
    const hasQuote = block?.userResponse?.quote?.length > 0;
    const hasRelatedElements =
      block?.cbProfileElementDetails?.length > 0 &&
      block.cbProfileElementDetails.every(
        profileElementDetail => profileElementDetail.valuesList?.length > 0,
      );
    return { hasNarrative, hasRationale, hasQuote, hasRelatedElements };
  };

  const showUsePrevResponsesBtn =
    previousResponses &&
    previousResponses.length > 0 &&
    !isReview &&
    (currentBlock.cbType === EVALUATION_QUESTION_TYPES.TEXT_INPUT ||
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT ||
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT ||
      currentBlock.cbType === EVALUATION_QUESTION_TYPES.RATING_SCALE);

  const showUseAllPrevResponsesBtn =
    currentBlock.cbType === 'dct' &&
    allRiskFactorsFalse &&
    dctPreviousResponses.length > 0 &&
    currentBlock.usePreviousResponses;

  let multiSelectElementValues = [];
  if (
    currentBlock.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT ||
    currentBlock.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT
  ) {
    multiSelectElementValues = previousResponses[0]?.answers || [];
  }

  const renderBadges = (block = null) => {
    const contentBlock = block ?? currentBlock;

    if (contentBlock?.cbType === 'education' || contentBlock.cbType === 'exercise') {
      return null;
    }
    return (
      <Box display="flex" gap={1} onKeyDown={handleKeyDown}>
        {contentBlock?.cbType === 'dct' ? (
          <Badge variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE} className={classes.badge}>
            Category
          </Badge>
        ) : (
          <>
            {contentBlock?.required && (
              <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.REQUIRED}>
                Required
              </Badge>
            )}
            {contentBlock?.responderType === 'MEMBER' && !isSignOffFlow && (
              <Badge
                className={classes.badge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.MISREPORTED}
                icon="wand-outlined"
              >
                AI can complete for you
              </Badge>
            )}
            {contentBlock?.responderType === 'PROVIDER' && (
              <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.PROVIDER}>
                For provider
              </Badge>
            )}
          </>
        )}
      </Box>
    );
  };

  const { hasNarrative, hasRationale, hasQuote, hasRelatedElements } = getContentBlockElements(currentBlock);

  return (
    <>
      <div onKeyPress={handleKeyPress} className={clsx(classes.container)} onKeyDown={handleKeyDown}>
        <Box
          className={`${classes.body} ${currentBlock.cbType === 'exercise' ? classes.exBody : ''}`}
          ref={bodyRef}
          sx={{ height: `calc(100vh - ${footerHeight + prevResponsesHeight + 81}px)` }}
        >
          <Box className={classes.header}>
            {renderBadges()}
            <Box display="flex" flexDirection="column" gap={1}>
              <Heading weight={fontWeight.BOLD} className={classes.title}>
                {currentBlock?.text}
              </Heading>
              {currentBlock?.subtitle?.length > 0 && (
                <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
                  {currentBlock?.subtitle}
                </Text>
              )}
            </Box>
          </Box>
          {(currentBlock?.prompt?.length > 0 || hasRelatedElements) && (
            <Box display="flex" flexDirection="column" gap={2}>
              {currentBlock?.prompt?.length > 0 && <Prompt label="Prompt" text={currentBlock?.prompt} />}
              {hasRelatedElements && (
                <RelatedElementsSection label="Related Profile Elements">
                  {currentBlock?.cbProfileElementDetails?.map(profileElementDetail => {
                    return (
                      <Box display="flex" flexDirection="column" gap={0.5} key={profileElementDetail.key}>
                        <Text weight={fontWeight.MEDIUM} className={classes.infoKey}>
                          {profileElementDetail.key}
                        </Text>
                        <Text className={classes.infoValue}>
                          {profileElementDetail.valuesList?.map(v => v.value).join(', ')}
                        </Text>
                      </Box>
                    );
                  })}
                </RelatedElementsSection>
              )}
            </Box>
          )}
          {currentBlock?.cbType !== 'dct' && (
            <Box
              display="flex"
              flexDirection="column"
              gap={currentBlock.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT ? 0 : 2}
            >
              {isReview ? renderAiResponse() : renderDetail()}
              {currentBlock?.cbType !== 'exercise' &&
                currentBlock?.cbType !== 'education' &&
                !showAddText &&
                !hasNarrative && (
                  <Box className={classes.addPersonalNote}>
                    <IconButton icon="plus" onClick={toggleShowAddText}>
                      Add narrative
                    </IconButton>
                  </Box>
                )}
              {(currentBlock?.userResponse?.providerNarrative?.length > 0 || showAddText) && (
                <Box className={classes.section}>
                  <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.SEMI_BOLD}>
                    Narrative
                  </Heading>
                  <Textarea
                    value={currentBlock?.userResponse?.providerNarrative ?? ''}
                    name="text"
                    onBlur={() => setInputFocused(false)}
                    onFocus={() => setInputFocused(true)}
                    onChange={e => {
                      const trimmedValue = e.target.value.trimStart();
                      updateValues({ id: currentBlock.cbId, narrative: trimmedValue });
                      setMultiSelectChange(!multiSelectChange);
                    }}
                    minRows={5}
                    placeholder="Enter text"
                  />
                  <Box className={classes.addPersonalNote}>
                    <IconButton
                      icon="minus"
                      onClick={() => {
                        updateValues({ id: currentBlock.cbId, narrative: '' });
                        setShowAddText(false);
                      }}
                      className={classes.redIcon}
                    >
                      Remove narrative
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {currentBlock?.cbType !== 'dct' && isReview && (
            <Box display="flex" flexDirection="column" gap={2}>
              {hasNarrative && (
                <Box display="flex" gap={2}>
                  <Box className={classes.iconContainer} sx={{ backgroundColor: colors.chipBackColor2 }}>
                    <Icons glyph="file-list-line-fold-outlined" color={colors.orange500} />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.SEMI_BOLD}>
                      Narrative
                    </Heading>
                    <Box>{currentBlock?.userResponse?.providerNarrative}</Box>
                  </Box>
                </Box>
              )}
              {hasRationale && (
                <Box display="flex" gap={2}>
                  <Box className={classes.iconContainer} sx={{ backgroundColor: colors.chipBackColor5 }}>
                    <Icons glyph="wand" color={colors.secondary} />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.SEMI_BOLD}>
                      AI rationale
                    </Heading>
                    <Box>{currentBlock?.userResponse?.reason}</Box>
                  </Box>
                </Box>
              )}
              {hasQuote && (
                <Box display="flex" gap={2}>
                  <Box className={classes.iconContainer} sx={{ backgroundColor: colors.primary50 }}>
                    <Icons glyph="ai-quote" color={colors.primary} />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.SEMI_BOLD}>
                      Quote
                    </Heading>
                    <Box>{currentBlock?.userResponse?.quote}</Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {currentBlock?.cbType === 'dct' && !currentBlock?.fullPage && (
            <Box className={classes.questionsBox}>
              <Box className={classes.questionsList}>
                {currentBlock?.children.map(item => (
                  <Box key={item.cbId} display="flex" flexDirection="column">
                    <Text weight={fontWeight.MEDIUM} className={classes.questionsText}>
                      {item.text}
                    </Text>
                    {allRiskFactorsFalse &&
                      dctPreviousResponses.length > 0 &&
                      currentBlock.usePreviousResponses && (
                        <Text weight={fontWeight.REGULAR} className={classes.choicesText}>
                          {dctPreviousResponses
                            .find(response => item.cbId === response.cbId)
                            ?.answers?.map(answer => answer.value)
                            .join(', ')}
                        </Text>
                      )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {currentBlock?.cbType === 'dct' && currentBlock?.fullPage && renderFullPageDCT()}
        </Box>

        {(showUseAllPrevResponsesBtn || showUsePrevResponsesBtn) && (
          <Box
            className={classes.previousResponseBox}
            ref={r => {
              if (r) {
                setPrevResponsesHeight(r.clientHeight);
              }
            }}
          >
            <Box className={classes.prevResponseItem}>
              <Box flex={1} display="flex" flexDirection="column" gap={0.5}>
                <Text weight={fontWeight.SEMI_BOLD} className={classes.prevText}>
                  Previous response
                </Text>
                <Text
                  weight={fontWeight.MEDIUM}
                  className={classes.prevResponseValue}
                  color={showUsePrevResponsesBtn ? colors.neutral900 : colors.success700}
                >
                  {showUsePrevResponsesBtn
                    ? currentBlock.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT
                      ? multiSelectElementValues?.map(response => response.value).join(', ')
                      : previousResponses[0]?.answers[0]?.value
                    : 'All in the normal range'}
                </Text>
              </Box>
              <Button
                className={classes.useBtn}
                variant={btnType.OUTLINE}
                size={btnSize.SMALL}
                onClick={showUsePrevResponsesBtn ? usePreviousResponse : useAllPreviousResponses}
              >
                <Text weight={fontWeight.BOLD} className={classes.useText}>
                  {showUsePrevResponsesBtn ? 'Use' : 'Use all previous responses'}
                </Text>
              </Button>
            </Box>
          </Box>
        )}

        <Box
          className={classes.footer}
          ref={r => {
            if (r) {
              setFooterHeight(r.clientHeight);
            }
          }}
        >
          <IconButton
            icon="arrow-left"
            disabled={isDisablePrevious}
            onClick={onClickPreviousHandler}
            className={classes.previousBtn}
          >
            Previous
          </IconButton>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            {isReview && (
              <IconButton
                variant={iconBtnType.SECONDARY}
                onClick={onChangeAnswer}
                className={classes.changeButton}
              >
                Change answer
              </IconButton>
            )}
            {isChangeAnswer && (
              <IconButton
                variant={iconBtnType.SECONDARY}
                onClick={onCancelClick}
                className={classes.changeButton}
              >
                Cancel
              </IconButton>
            )}
            <IconButton
              variant={iconBtnType.PRIMARY}
              onClick={
                isReview && currentBlock?.cbType !== 'dct'
                  ? onClickApprove
                  : isChangeAnswer
                  ? onSaveClick
                  : onClickNextEvaluationResponseHandler
              }
              className={classes.nextBtn}
              onKeyPress={handleKeyPress}
            >
              {currentBlock?.cbType === 'dct' && !currentBlock?.fullPage
                ? 'Start'
                : isReview
                ? 'Approve'
                : isChangeAnswer
                ? 'Save'
                : 'Next'}
            </IconButton>
          </Box>
        </Box>
      </div>
    </>
  );
};

export { EvaluationRightSection };
