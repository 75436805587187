import { makeStyles } from '@mui/styles';

// colors
import { colors } from '../../../colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '16px !important',
    height: '3.5rem',
  },
  icon: {
    cursor: 'pointer',
    height: 20,
    width: 20,
  },
  focus: {
    borderColor: `${colors.primary300} !important`,
    backgroundColor: colors.white,
    // boxShadow: '-5px 5px 5px #e3f1ff, 5px 5px 5px #e3f1ff, 0 5px 5px #e3f1ff',
  },
  focus1: {
    boxShadow: '-5px 0px 5px #e3f1ff, 5px 5px 6px #e3f1ff, 0 5px 5px #e3f1ff',
  },
  focus2: {
    boxShadow: '-5px 0 5px #e3f1ff, 5px 0 5px #e3f1ff, 0 -5px 5px #e3f1ff',
  },
  isChat: {
    lineHeight: 20,
  },
  alfieChat: {
    fontWeight: '500',
    height: 108,
    borderRadius: '8px 8px 0px 0px',
    border: `1px solid #D4DDE5`,
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 24px 0px 19px!important',
    // padding: '13px 16px !important',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 40,
    height: 40,
  },
  chatbotContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  chatbotContainerHeading: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  chatbotContainerText: {
    fontSize: 16,
    lineHeight: '24px',
  },
  primary: {
    height: 40,
    padding: '8px 16px !important',
    fontSize: 14,
    lineHeight: '20px',
  },
  outline: {
    height: 40,
    color: '#0365C1',
    borderColor: '#8EC8FD',
    padding: '8px 16px !important',
    fontSize: 14,
    lineHeight: '20px',
  },
  btnContainer: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  btnText: {
    gap: 13,
  },
});
