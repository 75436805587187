import { Box } from '@mui/material';
import { IconButton } from '../../molecules/icon-button';
import { Input } from '../../atoms/input';
import { Icons } from '../../../icons';
import { fontWeight, Text } from '../../atoms/typography';
import { useStyles } from './AskMeAnything.styles';

const AskMeAnything = ({ isError, isLoading, response, question, setQuestion, onSubmit }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.header}>
        <Icons className={classes.icon} glyph="wand-outlined" />
        <Text className={classes.heading} weight={fontWeight.SEMI_BOLD}>
          Ask us anything
        </Text>
      </Box>
      <Box className={classes.body}>
        <Text className={classes.question} weight={fontWeight.SEMI_BOLD}>
          What would you like to know about this patient?
        </Text>
        <Input
          value={question}
          className={classes.input}
          variant="DEFAULT"
          placeholder="Enter your question here..."
          onChange={e => setQuestion(e.target.value)}
        />
        <Text className={classes.example} weight={fontWeight.MEDIUM} color="#4C5965">
          Ex. What's changed in their PHQ-9 since last time?
        </Text>
      </Box>
      <Box className={classes.footer}>
        <div className={classes.buttonRow}>
          <IconButton
            onClick={onSubmit}
            variant="OUTLINE"
            className={classes.button}
            disabled={isLoading || !question}
          >
            Ask it
          </IconButton>
        </div>
        {isError && !isLoading && (
          <Box className={classes.error}>
            <Text className={classes.errorHeading} weight={fontWeight.SEMI_BOLD}>
            An error occurred while processing your question.
            </Text>
          </Box>
        )}
        {isLoading && (
          <Box className={classes.loaderContainer}>
            <Text className={classes.loaderText} weight={fontWeight.SEMI_BOLD}>
              Give us a few moments, we’re working our magic
            </Text>
            <Icons className={classes.loaderIcon} glyph="magic-wand" />
          </Box>
        )}
        {!isError && !isLoading && response && (
          <Box className={classes.response}>
            <Text className={classes.responseHeading} weight={fontWeight.SEMI_BOLD}>
              Here's the response
            </Text>
            <Text className={classes.responseBody} weight={fontWeight.MEDIUM}>
              {response}
            </Text>
          </Box>
        )}
      </Box>
    </div>
  );
};

export { AskMeAnything };
