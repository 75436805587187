import cx from 'clsx';

// mui
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
// icons
import { TableContainer, useMediaQuery } from '@mui/material';
import { colors } from '../../../../colors';
import { Icons } from '../../../../icons';

// colors
// styles
import { useStyles } from './TableGrid.styles';

// types
import { Checkbox } from '../../../atoms/checkbox';
import { theme } from '../../../../theme/index';

const TableHead = ({ columns = [], sorter, onRequestSort, isCheckAll, onSelectChange }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  const onSortColumn = column => {
    const direction = sorter?.column === column && sorter?.direction === 'desc' ? 'asc' : 'desc';
    onRequestSort({ column, direction });
  };

  const handleChange = event => {
    isCheckAll = event.target.checked;
    onSelectChange(event.target.checked);
  };

  return (
    <MuiTableHead>
      <MuiTableRow className={classes.headRow}>
        {columns.map(({ id, label, textAlign = 'left', sortable }, index) => {
          const isActive = sortable && sorter?.column === id;
          const isCheck = id === 'selected';
          const isLast = index + 1 === columns?.length;

          return (
            <MuiTableCell
              className={cx({
                [classes.headCell]: true,
                [classes.isLast]: isDesktop && isLast,
                [classes.isLastHeadCell]: isDesktop && isLast,
              })}
              key={id}
              align={textAlign}
              sortDirection={isActive ? sorter?.direction : false}
            >
              {isCheck ? <Checkbox checked={isCheckAll} onChange={handleChange} /> : ''}
              {sortable ? (
                <MuiTableSortLabel
                  active
                  direction={isActive ? sorter?.direction : 'asc'}
                  IconComponent={iconProps => (
                    <Icons
                      className={cx({
                        [classes.arrowDropdownIcon]: true,
                        [iconProps?.className]: true,
                      })}
                      glyph="caret-down"
                      color={colors.neutral600}
                    />
                  )}
                  onClick={() => onSortColumn(id)}
                >
                  {label}
                </MuiTableSortLabel>
              ) : (
                label
              )}
            </MuiTableCell>
          );
        })}
      </MuiTableRow>
    </MuiTableHead>
  );
};

const TableGrid = ({
  className = '',
  columns = [],
  sorter,
  data = [],
  onRowClick,
  onChange,
  isLoading,
  onSelectAllClick,
  isCheckAll,
  emptyState = 'No Record Found',
  ...rest
}) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  const onClickItem = item => () => {
    onRowClick(item);
  };

  const gridSorter = sorter || { direction: 'asc', column: columns[0]?.id };

  return (
    <TableContainer sx={{ overflowX: 'auto' }}>
      <MuiTable
        className={cx({
          [classes.root]: true,
          [className || '']: className,
        })}
        {...rest}
      >
        {/* Table Head */}
        <TableHead
          columns={columns}
          sorter={gridSorter}
          onRequestSort={onChange}
          isCheckAll={isCheckAll}
          onSelectChange={onSelectAllClick}
        />

        {/* Table Body */}
        <MuiTableBody>
          {isLoading ? (
            <MuiTableRow className={classes.bodyRow}>
              <MuiTableCell className={classes.bodyCell} sx={{ height: 200 }} colSpan={columns.length}>
                <div className={classes.loading}>
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />{' '}
                  Loading
                </div>
              </MuiTableCell>
            </MuiTableRow>
          ) : (
            <>
              {data.length > 0 ? (
                <>
                  {data.map((row, idx) => {
                    return (
                      <MuiTableRow key={idx} className={classes.bodyRow} hover>
                        {columns.map(({ id, className: clx, textAlign = 'left', renderCell }, index) => (
                          <MuiTableCell
                            onClick={
                              id !== 'actions' &&
                              id !== 'patient' &&
                              id !== 'patientName' &&
                              id !== 'provider' &&
                              id !== 'renderingProvider' &&
                              id !== 'member' &&
                              id !== 'selected' &&
                              onRowClick
                                ? onClickItem(row)
                                : undefined
                            }
                            className={cx(classes.bodyCell, clx, {
                              [classes.isLast]: isDesktop && index + 1 === columns.length,
                              [classes.isLastBodyCell]: isDesktop && index + 1 === columns.length,
                            })}
                            key={id}
                            align={textAlign}
                          >
                            {renderCell ? renderCell(row[id]) : row[id] || '—'}
                          </MuiTableCell>
                        ))}
                      </MuiTableRow>
                    );
                  })}
                </>
              ) : (
                <MuiTableRow className={classes.bodyRow}>
                  <MuiTableCell className={classes.bodyCell} sx={{ height: 200 }} colSpan={columns.length}>
                    <div className={classes.loading}>{emptyState}</div>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </>
          )}
        </MuiTableBody>
      </MuiTable>
    </TableContainer>
  );
};

export { TableGrid };
