import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { conversationActionCreators } from '../../../../../../redux/modules/conversation';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';
import * as MemberService from '../../../../../../services/member/member.service';
import Loader, { LoaderEnums } from '../../../../../../packages/ui/templates/loader';

import { uuid4 } from '../../../../../../lib/sendbird';
import { ChatUser } from './ChatUser';
import { useStyles } from './Chats.styles';

const Chats = ({
  isWaiting = false,
  careTeamList,
  appointment,
  onClose,
  sessionStarted = true,
  isEndedSession = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { profile, isLoading } = useSelector(getProfile);

  const [careTeamChannel, setCareTeamChannel] = useState(null);
  const [chatListItemList, setChatListItemList] = useState(null);
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const populateChatListItems = () => {
    if (careTeamList) {
      const updatedChatListItemList = careTeamList?.map(item => {
        return {
          connectionId: item?.connectionId,
          name: item.name || `${item?.firstName || ''} ${item?.lastName || ''}`,
          avatar: item?.profilePicture || '',
          role: item?.designation,
          colorCode: item?.colorCode,
        };
      });
      setChatListItemList(updatedChatListItemList);
    }
  };

  const populateChats = () => {
    if (!isLoading) {
      const queryParams = {
        userId: appointment?.participantId,
        pageNumber: 0,
        type: 'care_team',
      };
      MemberService.getPaginatedConnections(queryParams)
        .then(res => {
          if (res.data?.results?.length > 0) {
            const careTeamConnection = res.data?.results?.find(item => item?.type === 'CARE_TEAM');
            setCareTeamChannel(careTeamConnection);
            populateChatListItems();
          }
          setIsLoadingChat(false);
        })
        .catch(err => {
          console.log({ err });
          setIsLoadingChat(false);
        });
    }
  };

  /**
   * @Name useEffect
   * @description This method is used to fetch connections.
   */

  useEffect(() => {
    setIsLoadingChat(true);
    populateChats();
    populateChatListItems();
  }, []);

  /**
   * @Name handleAddMessage
   * @param msg
   * @description This method is used to handle add message functionality through redux call .
   */
  const handleAddMessage = msg => {
    const newMessage = !msg.hasFile
      ? {
          _id: uuid4(),
          message: {
            text: msg,
          },
          user: {
            userId: profile?.providerId,
            name: profile?.fullName,
            avatar: profile?.profileImage,
          },
          nickName: profile.fullName ?? 'no name',
          createdAt: new Date().getTime(),
          type: 'user',
          system: false,
        }
      : msg;
    dispatch(
      conversationActionCreators.sendMessage({
        payload: {
          message: newMessage,
        },
      }),
    );
  };

  /**
   * @Name useEffect
   * @description This method is used to init chat based on selected User
   */
  useEffect(() => {
    if (careTeamChannel) {
      dispatch(
        conversationActionCreators.initChat({
          payload: {
            channelUrl: careTeamChannel?.channelUrl,
            connection: {
              ...careTeamChannel,
              messages: careTeamChannel?.messages || [],
              nickname:
                careTeamChannel?.name ||
                `${careTeamChannel?.firstName || ''} ${careTeamChannel?.lastName || ''}`,
            },
            currentUser: {
              userId: profile?.providerId,
              nickname: profile?.fullName,
            },
          },
        }),
      );
    }
  }, [careTeamChannel]);

  if (isLoading || isLoadingChat) {
    return (
      <Box padding="16px">
        <Loader type={LoaderEnums.CHAT_USER_CARD} numItems={12} />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.container}>
        <ChatUser
          isWaiting={isWaiting}
          chatListItemList={chatListItemList}
          appointment={appointment}
          onClose={onClose}
          handleAddMessage={handleAddMessage}
          sessionStarted={sessionStarted}
          isEndedSession={isEndedSession}
          showInputHeader={false}
        />
      </Box>
    </>
  );
};

export { Chats };
