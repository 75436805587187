// colors
import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: 32,
    zIndex: 2,
  },
  avatar: {
    minWidth: 56,
    '& img': {
      border: `2px solid ${colors.white}`,
    },
  },
  username: {
    fontSize: 18,
    lineHeight: '28px',
    color: colors.white,
    fontWeight: 600,
    margin: 0,
  },
  fullname: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral200,
    fontWeight: 500,
  },
  actions: {
    position: 'relative',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  iconBtn: {
    width: 56,
    height: 56,
    minWidth: 56,
    borderRadius: '50%',
    backgroundColor: colors.white,
    border: `2px solid ${colors.neutral200}`,
    '& svg path': {
      color: colors.neutral900,
    },
    '&:hover': {
      backgroundColor: colors.white,
      border: `2px solid ${colors.neutral900}`,
    },
  },
  videoActive: {
    backgroundColor: colors.primary500,
    '& svg path': {
      color: colors.white,
    },
    '&:hover': {
      '& svg path': {
        color: colors.neutral900,
      },
    },
  },
  AudioActive: {
    backgroundColor: colors.primary500,
    '& svg path': {
      color: colors.white,
    },
    '&:hover': {
      '& svg path': {
        color: colors.neutral900,
      },
    },
  },
  iconBtnActive: {
    backgroundColor: colors.destructive500,
    border: 'none',
    '& svg path': {
      fill: colors.white,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: colors.destructive600,
    },
  },
  shareActive: {
    backgroundColor: colors.primary500,
    border: 'none',
    '& svg path': {
      fill: colors.white,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: colors.primary600,
    },
  },
  dropdown: {
    backgroundColor: colors.white,
    borderRadius: 8,
    position: 'absolute',
    top: 68,
    right: 200,
    width: 321,
    '&::after': {
      content: '""',
      borderLeft: '22px solid transparent',
      borderRight: '22px solid transparent',
      borderBottom: '14px solid white',
      position: 'absolute',
      top: -6,
      borderRadius: 8,
      right: 10,
    },
  },
  wrapTitle: {
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
  },
  wrapContent: {
    padding: 24,
  },
  btn: {
    height: 64,
    borderRadius: 8,
    width: '100%',
  },
  stopShareBtn: {
    backgroundColor: colors.destructive500,
    color: colors.white,
    marginBottom: 16,
    '&:hover': {
      backgroundColor: colors.destructive600,
    },
  },
  changeShareBtn: {
    backgroundColor: colors.primary50,
    color: colors.primary500,
    '&:hover': {
      backgroundColor: colors.primary100,
    },
  },
});
