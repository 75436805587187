import React from 'react';

const linkStyle = {
  fontFamily: 'Manrope',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '19px',
  color: '#0365C1',
  transition: '0.4s',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    opacity: 0.8,
  },
};

export const convertToLinks = (text = '') => {
  if (!text) return text;

  const urlRegex = /((?:https?:\/\/)?(?:www\.[^\s]+)|(?:www\.[^\s]+)|[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})[^\s]*)/g;

  // Split by both spaces and `<br/>` using a regex
  const parts = text.split(/(<br\/?>|\s+)/g);

  const convertedParts = parts.map((part, index) => {
    if (part.match(urlRegex)) {
      const url = part.startsWith('https') || part.startsWith('http') ? part : `https://${part}`;
      return (
        <span key={index}>
          <a href={url} target="_blank" className={linkStyle} rel="noreferrer">
            {part}
          </a>
        </span>
      );
    }
    if (part === '<br/>' || part === '<br>') {
      return <br key={index} />;
    }
    return <span key={index}>{part}</span>;
  });

  return convertedParts;
};

export const highlightMentionedUsers = (sentence = '', users = []) => {
  if (!sentence) return null;

  const mentionUsers = users.map(provider => provider.nickname);

  const processWord = (word, index) => {
    if (word.startsWith('@') && mentionUsers?.length !== 0) {
      return (
        <span key={`mention-${index}`} className="highlightMentionedUser">
          {word}
        </span>
      );
    }
    if (word.includes('<br/>')) {
      // Handle <br/> tags
      return word.split('<br/>').map((segment, i, array) => (
        <React.Fragment key={`br-${index}-${i}`}>
          {processWord(segment, `${index}-${i}`)}
          {i < array.length - 1 && <br />}
        </React.Fragment>
      ));
    }
    // Default: process as link or plain text
    return convertToLinks(word);
  };

  const words = sentence.split(' ');

  return (
    <>
      {words.map((word, index) => (
        <React.Fragment key={index}>
          {processWord(word, index)}
          {index < words.length - 1 && ' '}
        </React.Fragment>
      ))}
    </>
  );
};
