import { Box } from '@mui/material';
import {
  Badge,
  badgeType,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Text,
} from '../../../../../../../packages';
import { EVALUATION_STATUS, evaluationStatusStyle } from '../../../../evaluations-v2/Evaluations.constants';
import { useStyles } from './MedicationCard.styles';

const MedicationCard = ({ status, domainElements, onClick, isLoading }) => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box className={classes.cardContent} onClick={onClick}>
        <Box className={classes.iconWrapper}>
          <Icons color={colors.primary500} glyph="medication-2" />
        </Box>
        <Box>
          <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
            Medication reconciliation
          </Heading>
          {domainElements?.map(element => (
            <Box key={element.id} display="flex" alignItems="center" gap={1}>
              <Text className={classes.subtitle}>{element?.name}</Text>
              <Text className={classes.status}>{element?.priority?.name}</Text>
              <Text className={classes.subtitle}>
                {element?.tagMetaData?.rxDrugInfo?.dosage?.toString() ||
                  element?.tagMetaData?.rxDrugInfo?.dose?.toString() ||
                  'N/A'}
              </Text>
              <Text className={classes.subtitle}>
                {element.tagMetaData?.rxDrugInfo?.frequency?.toString() ||
                  element.tagMetaData?.rxDrugInfo?.doseFrequency?.toString() ||
                  'N/A'}
              </Text>
            </Box>
          ))}
        </Box>
        {!domainElements?.length && (
          <Box className={classes.statusBadge}>
            <Badge
              className={classes.badge}
              variant={badgeType.OUTLINED}
              style={evaluationStatusStyle[status]}
            >
              {EVALUATION_STATUS[status]}
            </Badge>
          </Box>
        )}
        {isLoading && (
          <div className={classes.loader}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </div>
        )}
      </Box>
      {/* <Menu
        icon="more"
        className={classes.menu}
        // itemsWrapperClassName={classes.menuItemsWrapper}
        // items={items}
      /> */}
    </Box>
  );
};

export { MedicationCard };
