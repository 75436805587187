import { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import debounce from 'debounce';
import { useParams } from 'react-router-dom';

import { fontWeight, Text, TextError } from '../../../../../../packages/ui/atoms/typography';
import { Icons } from '../../../../../../packages/icons';
import { colors } from '../../../../../../packages/colors';
import { Drawer, drawerType } from '../../../../../../packages/ui/organisms/drawer';
import { Input, inputType } from '../../../../../../packages/ui/atoms/input';

import { assignChatbot, getConversations } from '../../../../../../services/member/member.service';
import { useStyles } from './AssignChatbot.styles';

const AssignChatbot = ({ open, onClose, fetchChatbots }) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');
  const [error, setError] = useState('');
  const { memberId } = useParams();
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [isRequesting, setIsRequesting] = useState(false);

  const onCloseDrawer = () => {
    setSelectedChatbot(null);
    setSearchKey('');
    onClose();
  };

  const searchConversations = useCallback(
    debounce(key => {
      const newItems = conversations.filter(item => item.name.toLowerCase().includes(key));
      setItemsFiltered(newItems);
    }, 300),
    [conversations],
  );

  const onClickItem = item => () => {
    setSelectedChatbot(item);
  };

  const onChangeSearchKey = val => {
    setSearchKey(val);
  };

  const fetchConversations = useCallback(async () => {
    try {
      const { data } = await getConversations();
      setConversations(data);
    } catch (err) {
      setError(err.data.errors?.[0]?.endUserMessage || 'Something went wrong!');
    }
  }, []);

  const onSubmit = async () => {
    if (!selectedChatbot) {
      setError('Please select an chatbot');
      return;
    }
    try {
      setIsRequesting(true);
      await assignChatbot({
        conversationId: selectedChatbot.id,
        organizationId: selectedChatbot.organizationId,
        patientUserId: memberId,
      });
      fetchChatbots();
      onCloseDrawer();
    } catch (err) {
      setError(err.data.errors?.[0]?.endUserMessage || 'Something went wrong!');
    }
    setIsRequesting(false);
  };

  useEffect(() => {
    searchConversations(searchKey);
  }, [searchKey, conversations]);

  useEffect(() => {
    void fetchConversations();
  }, []);

  const renderItem = item => {
    return (
      <Box
        className={clsx(classes.chatbot, { [classes.selected]: selectedChatbot?.id === item.id })}
        key={item.id}
        onClick={onClickItem(item)}
      >
        <Box>
          <Text weight={fontWeight.SEMI_BOLD} className={classes.name}>
            {item.name}
          </Text>
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.name, classes.description)}>
            {item.description}
          </Text>
        </Box>
        <Box>{selectedChatbot?.id === item.id && <Icons glyph="checkmark" color={colors.success500} />}</Box>
      </Box>
    );
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onCloseDrawer}
        variant={drawerType.FORM}
        title="Assign chatbot"
        submitBtnTitle={isRequesting ? 'Submitting...' : 'Assign chatbot'}
        onSubmit={onSubmit}
        disableSubmit={isRequesting}
        className={classes.drawer}
      >
        <Box>
          <Box className={classes.searchBox}>
            <Input
              className={classes.searchInput}
              variant={inputType.SEARCH}
              placeholder="Search"
              value={searchKey}
              onChange={onChangeSearchKey}
            />
            {error && <TextError errorMsg={error} className={clsx(classes.text14, classes.error)} />}
          </Box>
          <Box className="list-item">
            {itemsFiltered.length > 0 ? (
              itemsFiltered.map(renderItem)
            ) : (
              <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.emptyList)}>
                There is no item
              </Text>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export { AssignChatbot };
