import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Avatar, avatarType, IconButton, Icons, Text } from '../../../../../packages';
import { ProgressBar } from '../../../../../packages/ui/atoms/ProgressBar';

import { convertByteToString } from '../../../../../utils';
import { useStyles } from './UploadFileProgress.styles';

export const UploadFileProgress = ({ file, progress = 100, isFailed = false, onRemove, onUploaded }) => {
  const classes = useStyles();
  const [fileUrl, setFileUrl] = useState('');

  const progressVal = Math.round((progress / (file.size || 1)) * 100);
  const actionIcon = isFailed ? 'rotate' : 'delete-outlined-2';
  const uploadMsg = isFailed ? 'Oops! Upload failed' : convertByteToString(file.size);

  useEffect(() => {
    setFileUrl(URL.createObjectURL(file));
  }, [file]);

  return (
    <Box className={classes.card}>
      <Box display="flex" gap={1.5} alignItems="center">
        <Box
          className={clsx(classes.imageWrap, {
            [classes.imageWrapFailed]: isFailed,
          })}
        >
          {progress !== 100 || isFailed || fileUrl === '' ? (
            <Icons glyph="image" />
          ) : (
            <Avatar src={fileUrl} variant={avatarType.SQUARE} size={64} />
          )}
        </Box>
        <Box className={classes.info}>
          <Text className={classes.filename}>{file.name}</Text>
          {progress !== 100 && <ProgressBar value={progressVal} />}
          <Text className={clsx(classes.progressText, { [classes.failedText]: isFailed })}>
            {progress === 100 ? uploadMsg : `${convertByteToString(progress)} • ${progressVal}% uploaded`}
          </Text>
        </Box>
      </Box>
      <IconButton
        icon={progress === 100 ? actionIcon : 'close'}
        onClick={isFailed ? () => onUploaded() : () => onRemove()}
        className={clsx(classes.removeBtn, {
          [classes.recycleBtn]: progress === 100,
          [classes.reloadBtn]: isFailed,
        })}
      />
    </Box>
  );
};
