import { makeStyles } from '@mui/styles';
import { colors } from '../../../../packages/colors';

export const useStyles = makeStyles({
  radioGroupRoot: ({ currentIndex, choices }) => {
    const choicesChilds = choices.reduce((acc, cur, index) => {
      return {
        ...acc,
        [`&:nth-child(${index + 1})`]: {
          backgroundColor: index === currentIndex ? colors.neutral50 : colors.white,
        },
      };
    }, {});
    return {
      display: 'flex',
      '& .MuiFormGroup-root': {
        gap: '0px',
        flex: 1,
        '& .MuiFormControlLabel-root': {
          paddingTop: '18px',
          paddingBottom: '18px',
          paddingRight: '24px',
          paddingLeft: '24px',
          ...choicesChilds,
          '&:hover': {
            backgroundColor: colors.neutral50,
          },
        },
      },
    };
  },
});
