import { Box, ButtonGroup, Grid, Menu, Paper } from '@mui/material';
import { btnSize, btnType, Button } from '../../../../packages/ui/atoms/button';
import {
  AppointmentStatus,
  AppointmentStatusPending,
  CalendarView,
  SignOffStatus,
} from '../../../../packages/constants/CommonConstants';
import {
  CheckboxGroup,
  Collapsable,
  colors,
  IconButton,
  IiconPosition,
  RadioGroup,
} from '../../../../packages';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import dayjs from 'dayjs';
import cx from 'clsx';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { conversationActionCreators } from '../../../../redux/modules/conversation';
import { scheduleActionCreators } from '../../../../redux/modules/schedule';
import { appointmentActionCreators } from '../../../../redux/modules/appointment';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { selectProviderSchedule } from '../../../../redux/modules/schedule/selectors';
import { getAppointment } from '../../../../redux/modules/appointment/selectors';
import {
  selectCalendarsState,
  selectGoogleCalendarsEventState,
} from '../../../../redux/modules/profile/selectors';

import { getAppointmentById } from '../../../../services/appointment/appointment.service';

import { getUserTimeZone } from '../../../../utils/dayjs';
import { insertIfObj } from '../../../../utils/InsertIf';

import googleMeetImg from '../../../../assets/images/google-meet.png';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';

import { useStyles } from './Calendar.styles';
import SettingsDrawer from './SettingsDrawer';

const statusOptions = [
  {
    label: (
      <Box className="status-check-item">
        <span className="status-scheduled" /> Scheduled
      </Box>
    ),
    value: 'BOOKED',
  },
  {
    label: (
      <Box className="status-check-item">
        <span className="status-requested" /> Requested
      </Box>
    ),
    value: 'REQUESTED',
  },
  {
    label: (
      <Box className="status-check-item">
        <span className="status-completed" /> Completed
      </Box>
    ),
    value: 'COMPLETED',
  },
  {
    label: (
      <Box className="status-check-item">
        <span className="status-cancelled" /> Canceled
      </Box>
    ),
    value: 'CANCELED',
  },
  {
    label: (
      <Box className="status-check-item">
        <span className="status-no_show" /> No show
      </Box>
    ),
    value: 'NO_SHOW',
  },
  {
    label: (
      <Box className="status-check-item">
        <span className="status-blocked_slots" /> Blocked slots
      </Box>
    ),
    value: 'BLOCKED',
  },
];

const appointmentTypeOptions = [
  { label: 'All', value: 'ALL' },
  { label: 'Current', value: 'CURRENT' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Past', value: 'PAST' },
];

const RenderCalendar = memo(
  ({
    currentDate,
    calendarKey,
    setSchedulesDrawerOpen,
    setCurrentDate,
    setSelectedAppointment,
    setShowDayAvailability,
    setOpenBlockedWholeDay,
    setViewAppointmentDetail,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { meta } = useSelector(getAuth);
    const providerSchedule = useSelector(selectProviderSchedule);
    const { appointments: defaultAppointments } = useSelector(getAppointment);
    const { events: googleCalendarEvents } = useSelector(selectGoogleCalendarsEventState);
    const { calendars } = useSelector(selectCalendarsState);

    const calendarRef = useRef(null);
    const [currentView, setCurrentView] = useState(CalendarView.Month);
    const [showCalendarSettings, setShowCalendarSettings] = useState(false);
    const [filters, setFilters] = useState({
      status: ['BOOKED', 'REQUESTED', 'COMPLETED', 'CANCELED', 'NO_SHOW', 'BLOCKED'],
      apptTypes: 'ALL',
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkedStatus, setCheckedStatus] = useState(filters?.status);
    const [checkedApptTypes, setCheckedApptTypes] = useState('ALL');

    const open = Boolean(anchorEl);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      if (currentView === CalendarView.Day && meta?.userId && currentDate) {
        const endOfDay = currentDate.endOf('day');
        const startOfDay = currentDate.startOf('day');
        dispatch(
          scheduleActionCreators.fetchProviderSchedule({
            userId: meta.userId,
            queryParams: {
              startTime: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              endTime: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            },
          }),
        );
      }
      if (currentView === CalendarView.Month && meta?.userId && currentDate) {
        dispatch(
          scheduleActionCreators.fetchProviderSchedule({
            userId: meta.userId,
          }),
        );
      }
    }, [currentDate, currentView]);

    const filterStatus = useMemo(() => {
      let result = '';
      if (filters?.status?.length === 0) {
        return 'NONE';
      }
      if (filters?.status?.includes('BOOKED')) {
        result = result ? `${result},${AppointmentStatus.BOOKED}` : AppointmentStatus.BOOKED;
      }
      if (filters?.status?.includes('COMPLETED')) {
        result = result ? `${result},${AppointmentStatus.FULFILLED}` : AppointmentStatus.FULFILLED;
      }
      if (filters?.status?.includes('REQUESTED')) {
        result = result ? `${result},${AppointmentStatus.PROPOSED}` : AppointmentStatus.PROPOSED;
      }
      if (filters?.status?.includes('PENDING')) {
        result = result
          ? `${result},${AppointmentStatus.NEEDS_ACTION},${AppointmentStatus.PROPOSED}`
          : `${AppointmentStatus.NEEDS_ACTION},${AppointmentStatus.PROPOSED}`;
      }
      if (filters?.status?.includes('CANCELED')) {
        result = result ? `${result},${AppointmentStatus.CANCELLED}` : AppointmentStatus.CANCELLED;
      }
      if (filters?.status?.includes('NO_SHOW')) {
        result = result ? `${result},${AppointmentStatus.NO_SHOW}` : AppointmentStatus.NO_SHOW;
      }
      if (filters?.status?.includes('BLOCKED')) {
        result = result ? `${result},BLOCKED` : 'BLOCKED';
      }
      return result;
    }, [filters?.status]);

    useEffect(() => {
      const params = {
        page: 0,
        size: 1000,
        type: filters?.apptTypes,
        participantIds: meta?.userId,
        statuses: filterStatus,
        ...insertIfObj(filters?.status?.includes('BOOKED') && filters?.status?.length === 1, {
          type: 'CURRENT',
        }),
      };
      if (currentDate) {
        const startDayOfMonth = currentDate.startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const endDayOfMonth = currentDate.endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSSZ');

        dispatch(
          appointmentActionCreators.fetchAppointments({
            queryParams: { ...params, startTime: startDayOfMonth, endTime: endDayOfMonth },
          }),
        );
      }
    }, [currentDate, filterStatus, filters?.apptTypes]);

    useEffect(() => {
      if (calendars?.length > 0 && currentDate) {
        const calenderEmail = calendars.map(obj => obj.email);
        if (calenderEmail?.length > 0) {
          dispatch(
            profileActionCreators.fetchAllGoogleCalendarEvents({
              from: dayjs(currentDate).subtract(1, 'month').format('DD-MM-YYYY'),
              to: dayjs(currentDate).add(1, 'month').format('DD-MM-YYYY'),
              calenderEmail,
            }),
          );
        }
      }
    }, [calendars, currentDate]);

    const dayTimeSlots = useMemo(() => {
      const appointments = defaultAppointments?.map(appt => ({
        ...appt,
        start: appt.startTime,
        end: appt.endTime,
      }));

      const transformedArray =
        googleCalendarEvents.flatMap(calendar =>
          calendar.events.map(event => ({
            ...event,
            start: event.utc.start,
            end: event.utc.end,
            serviceName: event.type,
            memberName: calendar.calenderId,
            status: 'BUSY',
          })),
        ) || [];

      let appts = appointments;

      const dayTimeSchedules =
        providerSchedule?.availability?.flatMap(schedule =>
          schedule.slots.map(slot => ({
            ...slot,
            serviceName: 'Available',
            memberName: '',
            status: 'AVAILABLE',
          })),
        ) || [];

      const blockedSlots =
        providerSchedule?.blockedSlots?.map(slot => ({
          ...slot,
          serviceName: 'Blocked',
          memberName: '',
          status: 'BLOCKED',
          startTime: slot.start,
          endTime: slot.end,
        })) || [];

      if (transformedArray?.length > 0) {
        appts = [...appts, ...transformedArray];
      }

      if (dayTimeSchedules?.length > 0) {
        appts = [...appts, ...dayTimeSchedules];
      }

      if (blockedSlots?.length > 0 && filters.status.includes('BLOCKED')) {
        appts = [...appts, ...blockedSlots];
      }

      return appts;
    }, [
      defaultAppointments,
      filters,
      googleCalendarEvents,
      providerSchedule?.availability,
      providerSchedule?.blockedSlots,
    ]);

    const monthDaySlots = useMemo(() => {
      const transformed = dayTimeSlots.reduce((prev, curr) => {
        const dayString = dayjs(curr.start)
          .tz(providerSchedule?.timezone || getUserTimeZone())
          .format('DD-MM-YYYY');
        if (prev[dayString]) {
          prev[dayString].push(curr);
        } else {
          prev[dayString] = [curr];
        }
        return prev;
      }, {});

      const slots = [];

      Object.keys(transformed).map(key => {
        const [dd, mm, yyyy] = key.split('-');
        return slots.push({
          start: new Date(Number(yyyy), Number(mm) - 1, Number(dd), 6),
          end: new Date(Number(yyyy), Number(mm) - 1, Number(dd), 6),
          items: transformed[key],
        });
      });

      return slots;
    }, [dayTimeSlots, googleCalendarEvents]);

    const fetchCurrentAppointment = useCallback(async (status, apptId, startTime) => {
      // setIsFetchLoading(true);
      try {
        const allAppts = await getAppointmentById({ appointmentId: apptId });

        const currentAppt = allAppts?.data || null;

        setSelectedAppointment(
          currentAppt
            ? {
                ...currentAppt,
                member: {
                  id: currentAppt.participantId,
                  appointmentId: currentAppt.appointmentId,
                  nickName: currentAppt.participantName,
                  fullName: `${currentAppt.patientFirstName || ''} ${currentAppt.patientLastName || ''}`,
                  photo: currentAppt.participantImage,
                },
              }
            : null,
        );
        setViewAppointmentDetail(true);
      } catch (error) {
        console.log({ error });
      } finally {
        // setIsFetchLoading(false);
      }
    }, []);

    const events = useMemo(
      () => (currentView === CalendarView.Month ? monthDaySlots : dayTimeSlots),
      [currentView, dayTimeSlots, monthDaySlots],
    );

    const syncedCalendars = useMemo(
      () =>
        calendars?.map(item => {
          const { calendar, email } = item;
          return {
            ...calendar,
            calendar: {
              calenderName: calendar?.email || '',
              photo: '', // decodedToken.photo,
              email: calendar?.email || '',
            },
            'pull-event': {
              syncGoogleToConfidantSystem: calendar?.calendarSettings?.syncGoogleToConfidantSystem,
            },
            'push-event': {
              syncConfidantSystemToGoogle: calendar?.calendarSettings?.syncConfidantSystemToGoogle,
            },
            'last-sync': {
              lastSyncAt: calendar?.lastSyncAt || '',
            },
            actions: {
              ...calendar,
              email,
            },
            email,
          };
        }),
      [calendars],
    );

    const onChangeView = view => () => {
      calendarRef.current?.getApi().changeView(view);
      setCurrentView(view);
    };

    const isAvailableDate = date => {
      const isPast = dayjs(date).isBefore(dayjs(), 'day');

      if (isPast) {
        return false;
      }

      return true;
    };

    const generateDayCellClassNames = arg => {
      const classNames = [classes.dayCell];

      if (isAvailableDate(arg.date)) {
        classNames.push('day-available');
      } else {
        classNames.push('day-unavailable');
      }

      return classNames;
    };

    const renderDayHeader = ({ date }) => {
      const day = dayjs(date);
      return (
        <div className={classes.dayHeadText}>
          <div className={classes.dayHeadDay}>{day.format('DD')}</div>
          <div className={classes.dayHeadMonth}>{day.format('MMMM')}</div>
        </div>
      );
    };

    const renderDayCell = ({ date, dayNumberText }) => {
      const isFirstMonthDay = new Date(date).getDate() === 1;
      const isToday = dayjs(date).isSame(dayjs(), 'day');
      return (
        <div className={classes.dayCellText}>
          {isFirstMonthDay ? dayjs(date).format('MMM D') : isToday ? 'Today' : dayNumberText}
        </div>
      );
    };

    const getBorderColor = status => {
      switch (status) {
        case 'BOOKED':
          return colors.primary300;
        case 'PENDING':
          return colors.secondary500;
        case AppointmentStatus.PROPOSED:
          return colors.secondary500;
        case AppointmentStatus.NEEDS_ACTION:
          return colors.secondary500;
        case 'REQUESTED':
          return colors.orange500;
        case 'COMPLETED':
          return colors.success500;
        case AppointmentStatus.FULFILLED:
          return colors.success500;
        case 'CANCELLED':
          return colors.neutral500;
        case 'BUSY':
          return colors.primary600;
        case 'AVAILABLE':
          return colors.success400;
        case 'BLOCKED':
          return colors.dark;
        case 'NO_SHOW':
          return colors.destructive500;

        default:
          return '';
      }
    };

    const getApptDetails = items => {
      if (!items) return {};
      const booked = items?.filter(item => item.status === 'BOOKED') || [];
      const pending =
        items?.filter(
          item =>
            AppointmentStatusPending.includes(item.status) || item.signOffStatus === SignOffStatus.DRAFTED,
        ) || [];
      const requested = items?.filter(item => item.status === 'REQUESTED') || [];
      const completed =
        items?.filter(
          item =>
            item.status === 'COMPLETED' ||
            (item.status === AppointmentStatus.FULFILLED && item.signOffStatus !== SignOffStatus.DRAFTED),
        ) || [];
      const noShow = items?.filter(item => item.status === 'NO_SHOW') || [];
      const canceled = items?.filter(item => item.status === 'CANCELLED') || [];
      const busy = items?.filter(item => item?.calenderId || item?.originalTime) || [];
      const available = items?.filter(item => item.status === 'AVAILABLE') || [];
      const blocked = items?.filter(item => item.status === 'BLOCKED') || [];

      return {
        booked: { length: booked.length, status: 'sessions', color: getBorderColor('BOOKED') },
        pending: { length: pending.length, status: 'pending', color: getBorderColor('PENDING') },
        requested: { length: requested.length, status: 'requested', color: getBorderColor('REQUESTED') },
        completed: { length: completed.length, status: 'completed', color: getBorderColor('COMPLETED') },
        noShow: { length: noShow.length, status: 'no show', color: getBorderColor('NO_SHOW') },
        canceled: { length: canceled.length, status: 'canceled', color: getBorderColor('CANCELLED') },
        busy: { length: busy.length, status: 'busy', color: getBorderColor('BUSY') },
        available: { length: available.length, status: 'available', color: getBorderColor('AVAILABLE') },
        blocked: { length: blocked.length, status: 'blocked', color: getBorderColor('BLOCKED') },
      };
    };

    const renderTimeEventContent = event => {
      const {
        event: {
          start,
          end,
          extendedProps: { status, serviceName, memberName, appointmentId },
          startStr,
        },
      } = event;
      const diff = dayjs(end).diff(dayjs(start), 'minute');
      const borderColor = getBorderColor(status);
      const boxShadow = `1px 1px 6px 2px ${borderColor}`;

      return (
        <Grid
          container
          sx={{
            height: diff > 0 ? (diff / 60) * 128 : 'auto',
            backgroundColor: colors.white,
            border: `1px solid ${borderColor}`,
            borderRadius: '6px',
            overflow: 'hidden',
            alignItems: 'center',
            cursor: 'pointer',
            boxShadow,
          }}
          onClick={async () => {
            if (appointmentId && status !== 'AVAILABLE' && status !== 'BLOCKED') {
              dispatch(conversationActionCreators.fetchAssignedEvaluations({ appointmentId }));
              await fetchCurrentAppointment(status, appointmentId, startStr);
            }
            if (status === 'AVAILABLE') {
              setSchedulesDrawerOpen(true);
            }
          }}
        >
          <Grid item xs={4} display="flex" alignItems="center" gap={1} paddingX={2}>
            <Box sx={{ width: 12, height: 12, backgroundColor: borderColor }} />
            <Box sx={{ color: colors.neutral900 }}>{serviceName}</Box>
          </Grid>
          <Grid item xs={2} display="flex" alignItems="center">
            <Box color={colors.neutral700}>{dayjs(startStr).format('h:mm a')}</Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box color={colors.neutral700}>{memberName}</Box>
          </Grid>
          <Grid item xs={2} display="flex" alignItems="center" justifyContent="space-around">
            {/* {isAfter && isAvailableMenuClick && <Menu icon="more" items={getItems(status, appointmentId)} />} */}
          </Grid>
        </Grid>
      );
    };

    const renderDayEventContent = event => {
      const {
        event: {
          extendedProps: { items },
        },
      } = event;

      const appts = getApptDetails(items);

      if (appts && Object.keys(appts)?.length > 0) {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {Object.keys(appts).map(
              (appt, index) =>
                appts[appt] &&
                appts[appt]?.length > 0 && (
                  <Box key={index} display="flex" gap={0.75} alignItems="center" ml={1.25}>
                    <Box sx={{ width: 10, height: 10, backgroundColor: appts[appt]?.color }} />
                    <Box color={colors.neutral900} sx={{ fontWeight: 600, fontSize: 12 }}>
                      {appts[appt]?.length} {appts[appt]?.status}
                    </Box>
                  </Box>
                ),
            )}
          </Box>
        );
      }

      return <></>;
    };

    const renderBtn = (label, view) => {
      return (
        <Button
          className={cx(classes.btn, { [classes.btnActive]: currentView === view })}
          onClick={onChangeView(view)}
        >
          {label}
        </Button>
      );
    };

    const handleDateClick = info => {
      setCurrentDate(currentDate);
    };

    const onChangeCheckStatus = val => {
      setCheckedStatus([...val]);
      setFilters({
        ...filters,
        status: [...val],
      });
    };

    const onChangeCheckApptTypes = e => {
      setCheckedApptTypes(e.target.value);
      setFilters({
        ...filters,
        apptTypes: e.target.value,
      });
    };

    return (
      <>
        <SettingsDrawer
          isOpen={showCalendarSettings}
          onClose={() => setShowCalendarSettings(false)}
          currentDate={dayjs()}
        />
        <Paper className={classes.calendarView}>
          <ButtonGroup className={classes.btnGroup}>
            {renderBtn('Month', CalendarView.Month)}
            {renderBtn('Day', CalendarView.Day)}
          </ButtonGroup>
          {currentView === CalendarView.Day &&
            (dayjs(currentDate).isSame(dayjs(), 'day') || dayjs(currentDate).isAfter(dayjs(), 'day')) && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  alignItems: 'center',
                  position: 'absolute',
                  zIndex: 100,
                  top: 16,
                  left: 229,
                }}
              >
                <Button
                  variant={btnType.TEXT}
                  size={btnSize.SMALL}
                  onClick={() => setShowDayAvailability(true)}
                  className={classes.calendarBtn}
                >
                  Edit availability
                </Button>
                <Button
                  variant={btnType.TEXT}
                  size={btnSize.SMALL}
                  className={cx(classes.calendarBtn, classes.destructive)}
                  onClick={() => setOpenBlockedWholeDay(true)}
                >
                  Block the whole day
                </Button>
              </Box>
            )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              zIndex: 100,
              top: 16,
              right: 16,
            }}
          >
            <Box display="flex" alignItems="center" gap={5}>
              <Box>
                {syncedCalendars?.length > 0 ? (
                  <Box sx={{ display: 'flex', gap: 1.5 }}>
                    <img src={googleMeetImg} alt="google meet icon" className={classes.googleMeetIcon} />
                    <Button className={classes.googleBtn} onClick={() => setShowCalendarSettings(true)}>
                      <Typography {...typography.body.s.medium} color={colors.neutral700}>
                        {syncedCalendars.length === 1
                          ? syncedCalendars[0].calendar.email
                          : `${syncedCalendars.length} calendars connected`}
                      </Typography>
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography {...typography.body.s.medium} color={colors.neutral700}>
                      No calendars connected
                    </Typography>
                    <Button className={classes.googleBtn} onClick={() => setShowCalendarSettings(true)}>
                      <Typography {...typography.body.s.bold}>Connect calendar</Typography>
                    </Button>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  '& svg': {
                    width: 15,
                    height: 12,
                  },
                  position: 'relative',
                }}
              >
                <IconButton
                  icon="filter"
                  className={classes.connectButton}
                  variant={btnType.OUTLINE}
                  onClick={handleClick}
                >
                  <Typography {...typography.body.s.bold}>Filter</Typography>
                </IconButton>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Box sx={{ width: 304, py: 3, pl: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Collapsable
                      label="Status"
                      iconPosition={IiconPosition.RIGHT}
                      className={classes.statusCollapse}
                      open
                    >
                      <CheckboxGroup
                        options={statusOptions}
                        onChange={onChangeCheckStatus}
                        value={checkedStatus}
                        checkAllOption={{ label: 'All statuses' }}
                      />
                    </Collapsable>
                    <Collapsable
                      label="Appointment types"
                      iconPosition={IiconPosition.RIGHT}
                      className={classes.statusCollapse}
                      open
                    >
                      <RadioGroup
                        name="radio-buttons-group"
                        options={appointmentTypeOptions}
                        value={checkedApptTypes}
                        onChange={onChangeCheckApptTypes}
                      />
                    </Collapsable>
                  </Box>
                </Menu>
              </Box>
            </Box>
          </Box>
          <FullCalendar
            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, momentTimezonePlugin]}
            key={calendarKey}
            viewClassNames={classes.calendarInnerView}
            initialView={currentView}
            ref={calendarRef}
            height="calc(100vh - 187px)"
            dayHeaderClassNames={classes.dayHead}
            dayHeaders={currentView !== CalendarView.Day}
            dayCellClassNames={arg => generateDayCellClassNames(arg)}
            dayHeaderContent={currentView === CalendarView.Week ? renderDayHeader : undefined}
            headerToolbar={{
              left: '',
              right: '',
            }}
            events={events}
            timeZone={getUserTimeZone()}
            dayCellContent={renderDayCell}
            eventContent={currentView === CalendarView.Day ? renderTimeEventContent : renderDayEventContent}
            slotDuration="00:30:00"
            slotLabelInterval={{ hours: 1 }}
            allDaySlot={false}
            businessHours={false}
            dateClick={props => {
              if (props?.date && currentView === CalendarView.Month) {
                onChangeView(CalendarView.Day)();
                setCurrentDate(dayjs(new Date(props?.date)));
                calendarRef?.current?.getApi()?.gotoDate(dayjs(new Date(props?.date)).format('YYYY-MM-DD'));
              }
            }}
            datesSet={handleDateClick}
            eventClick={props => {
              // eslint-disable-next-line no-underscore-dangle
              const eventDate = props?.event?._instance?.range?.start;
              if (eventDate && currentView === CalendarView.Month) {
                onChangeView(CalendarView.Day)();
                setCurrentDate(dayjs(new Date(eventDate)));
                calendarRef?.current?.getApi()?.gotoDate(dayjs(new Date(eventDate)).format('YYYY-MM-DD'));
              }
            }}
            initialDate={new Date(currentDate.toString())}
          />
        </Paper>
      </>
    );
  },
);

RenderCalendar.displayName = 'Calendar';

export default RenderCalendar;
