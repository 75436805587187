import { TOGGLE_SIDEBAR } from './actions';

export const DEFAULT = {
  showSidebar: true,
};

export default function commonReducer(state = DEFAULT, action) {
  const { type } = action;

  switch (type) {
    case TOGGLE_SIDEBAR: {
      return {
        showSidebar: !state.showSidebar,
      };
    }
    default: {
      return state;
    }
  }
}
