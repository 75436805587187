// const warning = {
//   warning50: '#FFFBEB',
//   warning100: '#FEF3C7',
//   warning200: '#FDE68A',
//   warning300: '#FCD34D',
//   warning400: '#FBBF24',
//   warning500: '#F59E0B',
//   warning600: '#D97706',
//   warning700: '#B45309',
// };

// const primary = {
//   primary: '#0374dd',
//   primary25: '#F5FAFF',
//   primary50: '#F1F8FF',
//   primary100: '#E3F1FF',
//   primary200: '#B8DDFE',
//   primary300: '#8EC8FD',
//   primary400: '#64B3FD',
//   primary500: '#0374DD',
//   primary600: '#0365C1',
// };

// const secondary = {
//   secondary: '#dd0374',
//   secondary50: '#FFF1F8',
//   secondary100: '#FFE3F1',
//   secondary200: '#FEB8DD',
//   secondary300: '#FD8EC8',
//   secondary500: '#DD0374',
//   secondary600: '#B80261',
// };

// const success = {
//   success: '#2da834',
//   success50: '#F3FCF4',
//   success100: '#E8F9E9',
//   success200: '#C4EFC7',
//   success300: '#A1E6A5',
//   success400: '#7EDC84',
//   success500: '#2DA834',
//   success600: '#268C2B',
//   success700: '#1E7023',
// };

// const neutral = {
//   neutral25: '#FBFCFE',
//   neutral50: '#F5F8FB',
//   neutral100: '#EDF1F5',
//   neutral200: '#D4DDE5',
//   neutral300: '#BEC9D3',
//   neutral400: '#A4B4C3',
//   neutral500: '#667786',
//   neutral600: '#4C5965',
//   neutral700: '#333C43',
//   neutral800: '#1A1E22',
//   neutral900: '#0D0F11',
// };

// const destructive = {
//   destructive50: '#FEF2F2',
//   destructive200: '#FECACA',
//   destructive300: '#FCA5A5',
//   destructive400: '#F87171',
//   destructive500: '#EF4444',
//   destructive600: '#DC2626',
//   destructive700: '#B91C1C',
// };

// const purple = {
//   purple50: '#F4F3FF',
//   purple100: '#EBE9FE',
//   purple200: '#D9D6FE',
//   purple300: '#BDB4FE',
//   purple500: '#7A5AF8',
//   purple600: '#6938EF',
//   purple700: '#6925DC',
// };

// const orange = {
//   orange50: '#FEF6EE',
//   orange100: '#FDEAD7',
//   orange200: '#F9DBAF',
//   orange300: '#F7B27A',
//   orange400: '#F38744',
//   orange500: '#EF6820',
//   orange600: '#E04F16',
// };

// const green = {
//   green50: '#ECFCF2',
//   green200: '#AAF0C4',
//   green300: '#73E2A3',
//   green500: '#16B364',
//   green600: '#099250',
// };

// const indigo = {
//   indigo50: '#EEF4FF',
//   indigo100: '#E0EAFF',
//   indigo200: '#C6D7FE',
//   indigo300: '#A4BCFD',
//   indigo500: '#6172F3',
//   indigo600: '#444CE7',
//   indigo700: '#3538CD',
// };
// const cyan = {
//   cyan50: '#ECFDFF',
//   cyan100: '#CFF8FE',
//   cyan200: '#A5F0FC',
//   cyan500: '#06AED4',
//   cyan600: '#088AB2',
//   cyan700: '#0E6F90',
// };
// const rose = {
//   rose50: '#FFF1F3',
//   rose100: '#FFE4E8',
//   rose200: '#FECCD6',
//   rose500: '#F63D68',
//   rose600: '#E31B53',
//   rose700: '#C01048',
// };

// const chipBackgroundColors = {
//   chipBackColor1: '#FEF2F2',
//   chipBackColor2: '#FEF6EE',
//   chipBackColor3: '#FFFBEB',
//   chipBackColor4: '#ECFDFF',
//   chipBackColor5: '#FFF1F8',
// };

// const chipFontColors = {
//   chipFontColor1: '#DC2626',
//   chipFontColor2: '#E04F16',
//   chipFontColor3: '#D97706',
//   chipFontColor4: '#088AB2',
//   chipFontColor5: '#DD0374',
// };

// const coolGray = {
//   coolGray100: '#EFF1F5',
//   coolGray700: '#404968',
// };
// const colors = {
//   ...warning,
//   ...primary,
//   ...secondary,
//   ...success,
//   ...neutral,
//   ...destructive,
//   ...purple,
//   ...orange,
//   ...green,
//   ...indigo,
//   ...cyan,
//   ...rose,
//   ...chipBackgroundColors,
//   ...chipFontColors,
//   ...coolGray,
//   error: '#c94c43',
//   textPrimary: '#005ebe',
//   textSecondary: '#dd0374',
//   textSuccess: '#16782e',
//   textError: '#c94c43',
//   textHighContrast: '#111c24',
//   textMediumContrast: '#414e58',
//   textLowContrast: '#637888',
//   textHigh: '#c94c43',
//   textMedium: '#ad5400',
//   textLow: '#0099a3',
//   textResolved: '#16782e',
//   textMisreported: '#dd0374',
//   textCritical: '#ae1824',
//   bgPrimary: '#e5e8ea',
//   bgSecondary: '#fcebf4',
//   bgTertiary: '#ebf4fc',
//   bgSuccess: '#ebfce4',
//   bgHigh: '#ffebeb',
//   bgMedium: '#fff3dc',
//   bgLow: '#eafeff',
//   bgResolved: '#ebfce4',
//   bgUnrelated: '#eaf1f5',
//   bgMisreported: '#fcebf4',
//   bgCritical: '#ae1824',
//   white: '#ffffff',
//   white15: 'rgba(255, 255, 255, 0.15)',
//   white35: 'rgba(255, 255, 255, 0.35)',
//   white75: 'rgba(255, 255, 255, 0.75)',
//   primary15: 'rgba(3, 116, 221, 0.15)',
//   dark60: 'rgba(17, 28, 36, 0.6)',
//   dividerPrimary: '#e5e8ea',
//   dividerSecondary: '#efefef',
//   scrollBar: '#d9dfe4',
//   overlay: '#111c24',
//   inputBorder: '#D4DDE5',
//   checkboxBorder: '#b9c4ca',
//   cyan50: '#ECFDFF',
//   cyan600: '#088AB2',
//   redPill: '#DD0374',
//   dark: '#0D0F11',
// };

// export interface IColors {
//   color: keyof typeof colors;
// }

// export { colors };

const warning = {
  warning50: '#FFFBEB',
  warning100: '#FEF3C7',
  warning200: '#FDE68A',
  warning300: '#FCD34D',
  warning400: '#FBBF24',
  warning500: '#F59E0B',
  warning600: '#D97706',
  warning700: '#B45309',
};

const primary = {
  primary: '#0374dd',
  primary25: '#F5FAFF',
  primary50: '#F1F8FF',
  primary100: '#E3F1FF',
  primary200: '#B8DDFE',
  primary300: '#8EC8FD',
  primary400: '#64B3FD',
  primary500: '#0374DD',
  primary600: '#0365C1',
};

const secondary = {
  secondary: '#dd0374',
  secondary50: '#FFF1F8',
  secondary100: '#FFE3F1',
  secondary200: '#FEB8DD',
  secondary300: '#FD8EC8',
  secondary500: '#DD0374',
  secondary600: '#B80261',
};

const success = {
  success: '#2da834',
  success50: '#F3FCF4',
  success100: '#E8F9E9',
  success200: '#C4EFC7',
  success300: '#A1E6A5',
  success400: '#7EDC84',
  success500: '#2DA834',
  success600: '#268C2B',
  success700: '#1E7023',
};

const neutral = {
  neutral25: '#FBFCFE',
  neutral50: '#F5F8FB',
  neutral100: '#EDF1F5',
  neutral200: '#D4DDE5',
  neutral300: '#BEC9D3',
  neutral400: '#A4B4C3',
  neutral500: '#667786',
  neutral600: '#4C5965',
  neutral700: '#333C43',
  neutral800: '#1A1E22',
  neutral900: '#0D0F11',
};

const destructive = {
  destructive50: '#FEF2F2',
  destructive200: '#FECACA',
  destructive300: '#FCA5A5',
  destructive400: '#F87171',
  destructive500: '#EF4444',
  destructive600: '#DC2626',
  destructive700: '#B91C1C',
};

const purple = {
  purple50: '#F4F3FF',
  purple100: '#EBE9FE',
  purple200: '#D9D6FE',
  purple300: '#BDB4FE',
  purple500: '#7A5AF8',
  purple600: '#6938EF',
  purple700: '#6925DC',
};

const orange = {
  orange50: '#FEF6EE',
  orange100: '#FDEAD7',
  orange200: '#F9DBAF',
  orange300: '#F7B27A',
  orange400: '#F38744',
  orange500: '#EF6820',
  orange600: '#E04F16',
};

const green = {
  green50: '#ECFCF2',
  green200: '#AAF0C4',
  green300: '#73E2A3',
  green500: '#16B364',
  green600: '#099250',
};

const indigo = {
  indigo50: '#EEF4FF',
  indigo100: '#E0EAFF',
  indigo200: '#C6D7FE',
  indigo300: '#A4BCFD',
  indigo500: '#6172F3',
  indigo600: '#444CE7',
  indigo700: '#3538CD',
};

const cyan = {
  cyan50: '#ECFDFF',
  cyan100: '#CFF8FE',
  cyan200: '#A5F0FC',
  cyan500: '#06AED4',
  cyan600: '#088AB2',
  cyan700: '#0E6F90',
};

const rose = {
  rose50: '#FFF1F3',
  rose100: '#FFE4E8',
  rose200: '#FECCD6',
  rose500: '#F63D68',
  rose600: '#E31B53',
  rose700: '#C01048',
};

const chipBackgroundColors = {
  chipBackColor1: '#FEF2F2',
  chipBackColor2: '#FEF6EE',
  chipBackColor3: '#FFFBEB',
  chipBackColor4: '#ECFDFF',
  chipBackColor5: '#FFF1F8',
};

const chipFontColors = {
  chipFontColor1: '#DC2626',
  chipFontColor2: '#E04F16',
  chipFontColor3: '#D97706',
  chipFontColor4: '#088AB2',
  chipFontColor5: '#DD0374',
};

const coolGray = {
  coolGray100: '#EFF1F5',
  coolGray700: '#404968',
};

const colors = {
  ...warning,
  ...primary,
  ...secondary,
  ...success,
  ...neutral,
  ...destructive,
  ...purple,
  ...orange,
  ...green,
  ...indigo,
  ...cyan,
  ...rose,
  ...chipBackgroundColors,
  ...chipFontColors,
  ...coolGray,
  error: '#c94c43',
  textPrimary: '#005ebe',
  textSecondary: '#dd0374',
  textSuccess: '#16782e',
  textError: '#c94c43',
  textHighContrast: '#111c24',
  textMediumContrast: '#414e58',
  textLowContrast: '#637888',
  textHigh: '#c94c43',
  textMedium: '#ad5400',
  textLow: '#0099a3',
  textResolved: '#16782e',
  textMisreported: '#dd0374',
  textCritical: '#ae1824',
  bgPrimary: '#e5e8ea',
  bgSecondary: '#fcebf4',
  bgTertiary: '#ebf4fc',
  bgSuccess: '#ebfce4',
  bgHigh: '#ffebeb',
  bgMedium: '#fff3dc',
  bgLow: '#eafeff',
  bgResolved: '#ebfce4',
  bgUnrelated: '#eaf1f5',
  bgMisreported: '#fcebf4',
  bgCritical: '#ae1824',
  white: '#ffffff',
  white15: 'rgba(255, 255, 255, 0.15)',
  white35: 'rgba(255, 255, 255, 0.35)',
  white75: 'rgba(255, 255, 255, 0.75)',
  primary15: 'rgba(3, 116, 221, 0.15)',
  dark60: 'rgba(17, 28, 36, 0.6)',
  dividerPrimary: '#e5e8ea',
  dividerSecondary: '#efefef',
  scrollBar: '#d9dfe4',
  overlay: '#111c24',
  inputBorder: '#D4DDE5',
  checkboxBorder: '#b9c4ca',
  cyan50: '#ECFDFF',
  cyan600: '#088AB2',
  redPill: '#DD0374',
  dark: '#0D0F11',
};

export { colors };
