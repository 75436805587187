import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, ButtonGroup } from '@mui/material';
import cx from 'clsx';

import {
  Badge,
  badgeStyle,
  badgeType,
  btnType,
  Button,
  colors,
  fontWeight,
  iconBtnStyle,
  IconButton,
  Icons,
  Text,
  textLevel,
} from '../../../../packages';

import { conversationActionCreators } from '../../../../redux/modules/conversation';
import { convertSecondToTime } from '../../../../utils/CommonUtils';
import history from '../../../../utils/history';

import { FilterSessions, FilterSessionsMock } from './SessionFilters.constants';
import { useStyles } from './SessionFilters.styles';

const SessionFilters = ({
  selectedSession,
  setSelectedSession,
  onEndSession,
  appointment,
  isEndedSession,
  isSessionStarted,
  sessionFilterList,
  inPersonSession,
}) => {
  const classes = useStyles();
  const [serviceDuration, setServiceDuration] = useState(0);
  const [tabsList, setTabsList] = useState(FilterSessions);
  const serviceCountdownRef = useRef(null);
  const dispatch = useDispatch();

  const onSubmitSignOffClick = () => {
    history.push(`/provider/appointments/past/${appointment.appointmentId}/session-signoff/v2`);
  };

  const onChangeSession = session => () => {
    setSelectedSession(session);
    if (session === FilterSessionsMock[1].type) {
      dispatch(conversationActionCreators.toggleEvaluationStatus());
    }
  };

  useEffect(() => {
    let duration = serviceDuration;
    setServiceDuration(0);
    if (isSessionStarted) {
      serviceCountdownRef.current = setInterval(() => {
        duration++;
        setServiceDuration(duration);

        if (isEndedSession) {
          clearInterval(serviceCountdownRef.current);
        }
      }, 1000);
    }
    return () => clearInterval(serviceCountdownRef.current);
  }, [isSessionStarted]);

  useEffect(() => {
    if (isEndedSession) {
      setTabsList(FilterSessions.filter(s => s.type !== 'priorities'));
    }
    if (inPersonSession) {
      setTabsList(FilterSessions.filter(s => s.type !== 'all' && s.type !== 'chat'));
    }
  }, [isEndedSession, inPersonSession]);

  return (
    <>
      <Box className={classes.filtersWrap}>
        <Box className={classes.dflex}>
          {isEndedSession && (
            <Button
              variant={btnType.OUTLINE}
              className={classes.backBtn}
              // onClick={() => {
              //   if (state?.isBackFrom) {
              //     history.push('/provider/appointments/pending');
              //   } else {
              //     history.back();
              //   }
              // }}
              onClick={() => {
                history.back();
              }}
            >
              <Icons color={colors.primary500} glyph="arrow-left" />
            </Button>
          )}
          {/* <Button
            variant={btnType.OUTLINE}
            className={classes.btnBack}
            onClick={() => {
              navigate('/provider/appointments/current');
            }}
          >
            <Icons color={colors.primary500} glyph="arrow-left" />
          </Button> */}
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {tabsList.map(({ type, label }) => (
              <Button
                key={type}
                className={cx(classes.btnGroupIcon, {
                  [classes.btnGroupIconActive]: type === selectedSession,
                })}
                onClick={onChangeSession(type)}
              >
                {label}
              </Button>
            ))}
            {sessionFilterList?.map(({ type, label, total, amount }) => (
              <Button
                key={type}
                className={cx(classes.btnGroupIcon, {
                  [classes.btnGroupIconActive]: type === selectedSession,
                })}
                onClick={onChangeSession(type)}
              >
                {label}
                <Badge
                  variant={badgeType.OUTLINED}
                  style={badgeStyle.MEDIUM}
                  className={cx(classes.badge, {
                    [classes.badgeActive]: type === selectedSession,
                  })}
                >
                  {`${amount}/${total}`}
                </Badge>
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box className={classes.dflex}>
          {!isEndedSession && (
            <Box className={classes.colLabel2}>
              <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} color={colors.neutral500}>
                Phone Number
              </Text>
              <Text className="fullName" weight={fontWeight.MEDIUM} level={textLevel.S}>
                {appointment?.patientPhoneNumber || '-'}
              </Text>
            </Box>
          )}
          {!isEndedSession && (
            <Text className={classes.time}>
              <span>
                <Text
                  className={cx(
                    serviceDuration > appointment?.serviceDuration * 60 ? classes.timeRed : classes.time,
                  )}
                >
                  {convertSecondToTime(serviceDuration)}
                </Text>
              </span>
              {` / ${appointment?.serviceDuration || 0} mins`}
            </Text>
          )}
          <IconButton
            onClick={isEndedSession ? onSubmitSignOffClick : onEndSession}
            icon={isEndedSession ? 'pencil' : undefined}
            style={iconBtnStyle.PRIMARY}
            className={cx(classes.btn, isEndedSession ? classes.signOffBtn : classes.endBtn)}
          >
            {isEndedSession ? 'Sign off' : 'End session'}
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export { SessionFilters };
