import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { btnType, Button } from '../../../../packages/ui/atoms/button';
import { Icons } from '../../../../packages/icons';
import { colors } from '../../../../packages/colors';
import { fontWeight, Heading, headingLevel, Text, textLevel } from '../../../../packages/ui/atoms/typography';
import cx from 'clsx';
import { useStyles } from './InPersonSession.styles';
import EvaluationCard from '../evaluations-v2/evaluation-card';
import { TOAST_TYPES } from '../../../../constants/CommonConstants';
import {
  getAppointmentEvaluationProgress,
  getEvaluationList,
  updateAppointmentAddMultipleEvaluations,
  updateAppointmentEvaluationStatus,
} from '../../../../services/conversation/conversation.service';
import { EVALUATION_STATES, RouteState } from '../evaluations-v2/Evaluations.constants';
import PriorityEmpty from '../../member-detail/components/PriorityEmpty';
import AddEvaluation from '../evaluations-v2/add-evaluation';

export const EvaluationBox = ({
  onBack,
  appointmentId,
  onSelectEvaluation,
  showAddEvaluation,
  setShowAddEvaluation,
  updateEvaluationProgress,
  setToastProperties,
  routeState,
}) => {
  const [evaluationsProgressList, setEvaluationsProgressList] = useState([]);
  const [allEvaluations, setAllEvaluations] = useState([]);

  const classes = useStyles();

  const getEvaluationProgressList = () => {
    getAppointmentEvaluationProgress({ appointmentId })
      .then(evaluationProgressDetailList => {
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          setEvaluationsProgressList(responseList);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };
  const getEvaluationsList = async () => {
    try {
      const evaluationsProgressListIds = evaluationsProgressList.map(
        progressEvaluationItem => progressEvaluationItem?.id,
      );
      const evaluationsListResponse = await getEvaluationList({ status: EVALUATION_STATES.ACTIVE });
      if (evaluationsListResponse?.status !== 200) {
        throw new Error('Whoops ! Something went wrong . Please try later');
      }
      let allEvaluationsList = evaluationsListResponse?.data?.EvaluationSummaryList || [];
      allEvaluationsList = allEvaluationsList.filter(
        evaluation => !evaluationsProgressListIds?.includes(evaluation?.evaluationId),
      );
      setAllEvaluations(allEvaluationsList);
    } catch (error) {
      console.log('Whoops ! Something went wrong . Please try later', error);
      setAllEvaluations([]);
    }
  };

  useEffect(() => {
    getEvaluationProgressList();
  }, []);

  useEffect(() => {
    if (showAddEvaluation) {
      void getEvaluationsList();
    }
  }, [showAddEvaluation]);

  const onBackPress = () => {
    if (showAddEvaluation) {
      setShowAddEvaluation(false);
    } else {
      onBack();
    }
  };

  const onAdd = () => {
    setShowAddEvaluation(true);
  };

  const onCloseAddEvaluation = () => {
    setShowAddEvaluation(false);
  };

  const onAddEvaluationClick = selectedEvaluationIds => {
    updateAppointmentAddMultipleEvaluations({ evaluationIds: selectedEvaluationIds }, { appointmentId })
      .then(() => {
        getEvaluationProgressList();
        setShowAddEvaluation(false);
        updateEvaluationProgress();
      })
      .catch(error => {
        console.log({ error });
        setShowAddEvaluation(false);
      });
  };

  const updateEvaluationStatus = evaluationUpdateRequest => {
    if (evaluationUpdateRequest) {
      updateAppointmentEvaluationStatus(evaluationUpdateRequest, { appointmentId })
        .then(res => {
          if (!res.data?.errors) {
            getEvaluationProgressList();
          } else {
            throw new Error(res?.data?.errors[0]?.endUserMessage);
          }
        })
        .catch(error => {
          setToastProperties({
            message: error?.data?.errors?.[0]?.endUserMessage || 'Something went wrong',
            type: TOAST_TYPES.ERROR,
            status: true,
          });
        });
    } else {
      throw new Error('Whoops ! Something went wrong . Please try later');
    }
  };

  return (
    <Box
      className={cx(classes.evaluationContainer, {
        [classes.flexibleEvaluationContainer]: routeState === RouteState.INITIAL && !showAddEvaluation,
      })}
    >
      <Box className={classes.evaluationHeader}>
        <Box className={classes.headerLeftSide}>
          <Box>
            <Button variant={btnType.TEXT} className={classes.sideBarToggler} onClick={onBackPress}>
              <Icons className={classes.togglerIcon} glyph="caret-left" color={colors.neutral700} />
            </Button>
          </Box>
          <Box className={classes.titleContainer}>
            <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.heading}>
              {showAddEvaluation ? 'Add Evaluations' : 'Evaluations'}
            </Heading>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
              {showAddEvaluation ? '' : `${evaluationsProgressList.length} total`}
            </Text>
          </Box>
        </Box>
        {!showAddEvaluation && (
          <Box className={classes.iconPlus}>
            <Icons glyph="plus" color={colors.primary500} onClick={onAdd} />
          </Box>
        )}
      </Box>
      <Box className={classes.evaluationsContainer}>
        {showAddEvaluation ? (
          <AddEvaluation
            onClose={onCloseAddEvaluation}
            evaluationsList={allEvaluations}
            onAddEvaluationClick={onAddEvaluationClick}
            inPersonSession
          />
        ) : (
          <>
            {evaluationsProgressList.length === 0 ? (
              <PriorityEmpty
                title="No Assigned Evaluations"
                description={`Evaluations collect the information
            we need to power the system.
            Please assign the appropriate Eval for this appointment.
            `}
                onAdd={onAdd}
                addText="Add evaluation"
                className="session-evaluations"
                glyph="face-talking-amazed"
              />
            ) : (
              <Box className={classes.allEvalsBox}>
                {evaluationsProgressList.map(evaluationProgressItem => (
                  <EvaluationCard
                    evaluation={evaluationProgressItem}
                    key={evaluationProgressItem?.id}
                    onClick={onSelectEvaluation}
                    updateEvaluationStatus={updateEvaluationStatus}
                    setToastProperties={setToastProperties}
                  />
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
