import { colors } from '../../../../../../packages/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 96,
    padding: 24,
    background: colors.neutral25,
    boxShadow: `inset 0px -1px 0px ${colors.neutral100}`,
  },
  cardTitle: {
    display: 'block',
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  cardSubTitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  card: {
    width: '100%',
    backgroundColor: colors.white,
    cursor: 'pointer',
    boxShadow: `0px 0px 6px ${colors.neutral100}`,
    borderRadius: 8,

    '&:hover $action': {
      display: 'flex',
    },
  },
  action: {
    display: 'none',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
    padding: '32px 32px 24px 32px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  topContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  title: {
    margin: 0,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: colors.neutral900,
  },
  badge: {
    borderRadius: 100,
    height: 28,
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  subTitle: {
    display: 'block',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  description: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 24,
    padding: 24,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    height: 'calc(100vh - 256px)',
    flexDirection: 'column',
    gap: 16,
    padding: 24,
    overflowY: 'auto',
    backgroundColor: colors.neutral50,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  noGap: {
    gap: 'unset',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  addBtn: {
    height: 48,
    color: colors.white,
    whiteSpace: 'nowrap',
    padding: '12px 20px',
    borderRadius: 6,
  },
  removeIconBtn: {
    width: 40,
    height: 40,
    minWidth: 'unset',
    border: `1px solid ${colors.destructive300}`,
    borderRadius: 4,

    '& svg': {
      width: 20,
      height: 20,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
});
