import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  Badge,
  badgeStyle,
  badgeType,
  Heading,
  iconBtnType,
  IconButton,
  Text,
} from '../../../../../../packages';
import { ProfileInfo } from '../../../../../../packages/ui/templates/profile-info';
import { profileActionCreators } from '../../../../../../redux/modules/profile';
import { priorityActionCreators } from '../../../../../../redux/modules/priority';
import { getPriority } from '../../../../../../redux/modules/priority/selectors';

import { AddMedication } from '../add-medication';

// styles
import { useStyles } from './MedicationReconciliation.styles';

const MedicationReconciliation = ({ appointment, relatedElements, domainOptions, onClose, symptoms }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAddMedication, setOpenAddMedication] = useState(false);
  const [selectedDomainElement, setSelectedDomainElement] = useState();

  const {
    typesPayload: { domainTypes },
    elementsPayload: { domainElements },
  } = useSelector(getPriority);

  const domainType = useMemo(() => domainTypes?.find(type => type.name === 'Diagnoses'), [domainTypes]);
  const domainTypeId = domainType?.Id;

  const onClickAdd = () => {
    setSelectedDomainElement(null);
    setOpenAddMedication(true);
  };

  const onClickUpdate = item => {
    setSelectedDomainElement(item);
    setOpenAddMedication(true);
  };

  const onSubmit = payload => {
    const data = {
      patientId: appointment.participantId,
      tagItems: [
        {
          assignedBy: appointment?.participantName,
          domainElementId: payload?.medication,
          metaData: {
            notes: payload?.additionalNotes,
            selectedImportance: payload?.status,
            rxDrugInfo: {
              confidantProviderId: payload?.confidantProvider,
              confidantProviderName: payload?.confidantProviderName,
              dosage: payload?.dosage,
              frequency: payload?.frequency,
              dose: 0,
              doseFrequency: 0,
              doseUnit: '',
              prescribingProvider: payload?.provider === 'Confidant Provider' ? 'Internal' : 'External',
              providerName: payload?.providerName,
              refillable: true,
              supply: 0,
              supplyUnit: '',
              symptomsBeingTreated: payload?.symptoms,
            },
            // rxDrugInfo: {
            // dose: rest.dose.amount,
            // doseUnit: rest.dose.unit,
            // doseFrequency: payload.doseFrequency,
            // refillable: rest.refillable,
            // supply: rest.duration.amount,
            // supplyUnit: rest.duration.unit,
            // },
          },
        },
      ],
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: errorMsg => {
          if (!errorMsg) {
            setOpenAddMedication(false);
          }
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(priorityActionCreators.fetchPriorityDomainTypes());
  }, []);

  useEffect(() => {
    dispatch(priorityActionCreators.fetchDomainElementsByTypeId(domainTypeId));
  }, [domainTypeId]);

  const renderCard = item => (
    <Box key={item.id} className={classes.card}>
      <Box className={classes.topSection}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Box className={classes.topContentWrapper}>
            <Heading className={classes.title}>{item.name}</Heading>
            <Badge style={badgeStyle.HIGH} variant={badgeType.FILLED} className={classes.badge}>
              {item.priority.name}
            </Badge>
          </Box>
          <Text className={classes.subtitle}>
            {item.tagMetaData?.rxDrugInfo?.dosage} {item.tagMetaData?.rxDrugInfo?.frequency}
          </Text>
        </Box>
        <IconButton
          variant={iconBtnType.SECONDARY}
          className={classes.action}
          onClick={() => onClickUpdate(item)}
        >
          Update
        </IconButton>
      </Box>
      <Box className={classes.bottomSection}>
        <ProfileInfo
          type="member"
          photo={item.assignedBy}
          nickName={item.assignedBy}
          fullName={dayjs(item.assignedAt).format('MMMM D, YYYY')}
          isProvider
        />
        <Text className={classes.description}>{item.notes}</Text>
      </Box>
    </Box>
  );

  return (
    <>
      {openAddMedication ? (
        <AddMedication
          selectedDomainElement={selectedDomainElement}
          domainElements={domainElements}
          domainOptions={domainOptions}
          onSubmit={onSubmit}
          symptoms={symptoms}
          onClose={() => setOpenAddMedication(false)}
        />
      ) : (
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Box>
              <Text className={classes.cardTitle}>Medication Reconciliation</Text>
              <Text className={classes.cardSubTitle}>{relatedElements?.length || 0} medications total</Text>
            </Box>
            <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
          </Box>
          <Box className={classes.subHeader}>
            <ProfileInfo
              type="member"
              photo={appointment.participantImage}
              nickName={appointment.participantName}
              fullName={`${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`}
              isProvider
            />
            <IconButton
              className={classes.addBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onClickAdd}
            >
              Add medication
            </IconButton>
          </Box>
          <Box className={classes.formContent}>{relatedElements?.map(item => renderCard(item))}</Box>
        </Box>
      )}
    </>
  );
};

export { MedicationReconciliation };
