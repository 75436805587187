export const FilterSessions = [
  { type: 'all', label: 'Fullscreen' },
  { type: 'priorities', label: 'Dashboard' },
  { type: 'chat', label: 'Chat' },
  { type: 'schedule', label: 'Schedule' },
  { type: 'ama', label: 'AMA' },
];

export const FilterSessionsMock = [
  { type: 'checklist', label: 'Checklist', total: 0, amount: 0 },
  { type: 'evaluation', label: 'Evaluation', total: 0, amount: 0 },
];
