import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../../packages/hooks';
import { SignOffStatus } from '../../../packages/constants/CommonConstants';
import { appointmentActionCreators } from '../../../redux/modules/appointment';
import { profileActionCreators } from '../../../redux/modules/profile';
import { getAppointment } from '../../../redux/modules/appointment/selectors';
import history from '../../../utils/history';
import { getAppointmentEvaluationProgress } from '../../../services/conversation/conversation.service';
import { getCareTeamMember } from '../../../services/member/member.service';
import SessionFilters from './session-filters';
import VideoCallScreen from './video-call-screen';
import { EVALUATION_STATUS } from './evaluations-v2/Evaluations.constants';

import { FilterSessions } from './session-filters/SessionFilters.constants';
import { useStyles } from './Sessions.styles';
import { ProvideEvaluationHead } from '../../../hooks/useEvaluationHead';
import { getAppointmentById } from '../../../services/appointment/appointment.service';

const SessionSignOff = () => {
  const classes = useStyles();
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [selectedSession, setSelectedSession] = useState(FilterSessions[0].type);
  const [appointment, setAppointment] = useState(null);
  const [showSignOff, setShowSignOff] = useState(false);
  const { appointments: appointmentsState = [] } = useSelector(getAppointment);
  const { appointmentId } = useParams();
  const [isSubmittedFeedback] = useState(true);
  const [sessionFilterList, setSessionFilterList] = useState([]);
  const [careTeamList, setCareTeamList] = useState([]);
  const [chatsDrawerOpen, setChatsDrawerOpen] = useState(false);
  const [selectedEvaluationId, setSelectedEvaluationId] = useState(null);

  useEffect(() => {
    const name = query.get('name');
    if (name) {
      setSelectedSession(name);
    }
  }, []);

  /**
   * @Name getEvaluationProgressList
   * @description This method is used to get Evaluation Progress List
   */
  const getEvaluationProgressList = async () => {
    await getAppointmentEvaluationProgress({ appointmentId })
      .then(evaluationProgressDetailList => {
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong. Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          const amount = responseList?.filter(
            evaluation => evaluation?.status === EVALUATION_STATUS.COMPLETED.toLocaleUpperCase(),
          )?.length;
          const sessionFilterObject = {
            type: 'evaluation',
            label: 'Evaluation',
            total: responseList?.length,
            amount,
          };
          // TODO : Please add Checklist count details here
          setSessionFilterList([
            { type: 'checklist', label: 'Checklist', total: responseList?.length, amount },
            sessionFilterObject,
          ]);
          if (state?.selectedEvaluationId) {
            setSelectedEvaluationId(state.selectedEvaluationId);
          }
          if (state?.sessionType) {
            setSelectedSession(state?.sessionType);
          } else if (state?.isInPersonSession) {
            setSelectedSession('evaluation');
          } else {
            setSelectedSession('checklist');
          }
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
        history.back();
      });
  };

  /**
   * @Name useEffect
   * @description This method is used to get Evaluation List with progress detail of each evaluation
   */
  useEffect(() => {
    if (!appointmentId) {
      throw new Error('Please provide appointment Id');
    }
    if (appointmentId) {
      dispatch(
        appointmentActionCreators.fetchAppointments({
          queryParams: {
            orderBy: 'desc',
            pageNumber: 1,
            pageSize: 1000,
            sortBy: 'startTime',
            type: 'PENDING',
          },
        }),
      );
      void getEvaluationProgressList();
    }
  }, []);

  /**
   * @Name getCareTeamList
   * @description This method is used to get care team list for the patient
   */
  const getCareTeamList = appt => {
    if (!appt?.participantId) {
      throw new Error('Please provider Member Id');
    } else {
      getCareTeamMember({ userId: appt?.participantId }, { careNavigator: true })
        .then(careTeamListResponse => {
          if (careTeamListResponse?.status !== 200) {
            throw new Error('Whoops ! Something went wrong . Please try later');
          } else {
            setCareTeamList(careTeamListResponse?.data || []);
          }
        })
        .catch(error => {
          console.log('Whoops ! Something went wrong . Please try later', error);
        });
    }
  };

  /**
   * @Name useEffect
   * @description This method is used to get session details and set tele session token value
   *
   */
  useEffect(() => {
    if (!appointment) {
      const item = appointmentsState.find(app => app.appointmentId === appointmentId);
      if (item) {
        if (item.signOffStatus === SignOffStatus.APPROVED) {
          navigate('/provider/appointments/past');
        }
        setAppointment(item);
        dispatch(profileActionCreators.fetchDomainTypes(item.participantId));
        getCareTeamList(item);
      } else {
        void getAppointmentById({ appointmentId }).then(response => {
          const appointmentById = response?.data;
          if (appointmentById) {
            if (appointmentById.signOffStatus === SignOffStatus.APPROVED) {
              navigate('/provider/appointments/past');
            }
            setAppointment(appointmentById);
            dispatch(profileActionCreators.fetchDomainTypes(appointmentById.participantId));
            getCareTeamList(appointmentById);
          }
        });
      }
    }
  }, [appointmentsState, appointment, appointmentId]);

  if (!appointment) {
    return null;
  }
  return (
    <>
      <Box className={classes.container2}>
        <SessionFilters
          appointment={appointment}
          isSubmittedFeedback={isSubmittedFeedback}
          isEndedSession
          onEndSession={null}
          showSignOff={showSignOff}
          setShowSignOff={setShowSignOff}
          isSessionStarted={false}
          selectedSession={selectedSession}
          setSelectedSession={setSelectedSession}
          sessionFilterList={sessionFilterList}
        />
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          // className={state?.isInPersonSession === false && classes.mainContent}
        >
          <ProvideEvaluationHead>
            <VideoCallScreen
              isWaiting={false}
              setIsWaiting={null}
              onEndSession={null}
              setShowSignOff={() => setShowSignOff(true)}
              isEndedSession
              externalSelectedEvaluationId={selectedEvaluationId}
              setIsEndedSession={null}
              chatsDrawerOpen={chatsDrawerOpen}
              setChatsDrawerOpen={setChatsDrawerOpen}
              initialEvaluation={null}
              selectedSession={selectedSession}
              onCloseSession={null}
              onChangeView={setSelectedSession}
              appointment={appointment}
              telesessionToken=""
              telesessionId=""
              onStartSession={null}
              sessionFilterList={sessionFilterList}
              careTeamList={careTeamList}
              getEvaluationProgressList={getEvaluationProgressList}
              isSignOff
              // isInPersonSessionCall={state?.isInPersonSession}
            />
          </ProvideEvaluationHead>
        </Box>
      </Box>
    </>
  );
};

export { SessionSignOff };
