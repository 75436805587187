// provider pages
import Chats from './pages/provider/chats';
import Schedule from './pages/provider/schedule';
import MemberAppointmentEvaluation from './pages/provider/member-appointment-evaluation';
import MemberAppointmentNotes from './pages/provider/member-appointment-notes';
import MemberDetail from './pages/provider/member-detail';
import MemberList from './pages/provider/member-list';
import Notifications from './pages/provider/notifications';
import Profile from './pages/provider/profile';
import ProfileV2 from './pages/provider/profile-v2';
import Sessions from './pages/provider/sessions';
import { SessionSignOff } from './pages/provider/sessions/SessionSignOff';
import Support from './pages/provider/support';
import VideoChats from './pages/provider/chats/video-chats';
import { Todos } from './pages/provider/todos/Todos';
import Practice from './pages/provider/practice';

// admin pages
import AdminDashboard from './pages/admin/dashboard';
import DataCollectionTemplates from './pages/admin/data-collection-templates';
import DctDetail from './pages/admin/data-collection-template-detail';
import Conversations from './pages/admin/conversations';
import ConversationDetail from './pages/admin/conversation';
import ProfileElementList from './pages/admin/profile-element-list';
import ProfileElementDetail from './pages/admin/profile-element-detail';
import TagsList from './pages/admin/tags';
import TagDetail from './pages/admin/tag-detail';
import PlanItems from './pages/admin/plan-items';
import PlanItemDetail from './pages/admin/plan-items/components/plan-item-detail';
import ReferralPartners from './pages/admin/referral-partners';
import ReferralPartner from './pages/admin/referral-partner';
import Automations from './pages/admin/automations';
import AutomationDetailPage from './pages/admin/automations/automation-detail-page';
import Reports from './pages/admin/reports';
import ReportDetail from './pages/admin/report-detail';
import PriorityTypes from './pages/admin/priority-types';
import PriorityElements from './pages/admin/priority-elements';
import PriorityGroupings from './pages/admin/priority-groupings';
import AdminMembers from './pages/admin/member-list';
import AdminMemberDetail from './pages/admin/member-detail';
import ProviderList from './pages/admin/provider-list';
import ProviderDetail from './pages/admin/provider-detail';
import AdminAppointments from './pages/admin/appointments';
import SessionTypes from './pages/admin/session-types';
import MasterSchedule from './pages/admin/master-schedule';
import PaymentList from './pages/admin/payments';
import InvoiceList from './pages/admin/invoices';
import ProviderFee from './pages/admin/provider-fee';
import ClaimList from './pages/admin/claims';
import ClaimDetails from './pages/admin/claim-detail';
import Evaluations from './pages/admin/evaluations';
import LevelOfEngagement from './pages/admin/level-of-engagement';
import EvaluationDcts from './pages/admin/evaluation-dcts';
import ContentBlockLibrary from './pages/admin/content-block-library';
import States from './pages/admin/state-management';
import StateDetail from './pages/admin/state-detail';
import PayerDetail from './pages/admin/state-detail/components/payers/payer-detail';
import { AlfieValidationPlayground } from './pages/admin/alfie-evaluation-validation/AlfieValidationPlayground';
import { AlfieAnalysisPlayground } from './pages/admin/alfie-evaluation-analysis/AlfieAnalysisPlayground';
import NotesList from './pages/admin/notes';
import SessionSignOffV2 from './pages/provider/sessions/sign-off-v2/SessionSignOffV2';
import ReferralDashboard from './pages/provider/member-detail/components/Dashboard';
import GeneralAvailability from './pages/provider/general-availability/GeneralAvailability';
import AdminNotifications from './pages/admin/notifications';
import { SUPPORTED_AUTHORITIES } from './constants/CommonConstants';
import EvaluationStates from './pages/admin/evaluation-states/EvaluationStates';
import EvaluationDrawer from './pages/provider/sessions/video-call-screen/components/checklist/evaluation-drawer';

export const providerRoutes = [
  {
    path: '/provider/:providerId/day-schedule',
    name: 'Schedule',
    component: Schedule,
  },
  {
    path: '/provider/todos',
    name: 'todos',
    component: Todos,
  },
  {
    path: '/provider/members',
    name: 'Members',
    component: MemberList,
  },
  {
    path: '/provider/practice',
    name: 'Practice',
    component: Practice,
  },
  {
    path: '/provider/members/:memberId',
    name: 'Member detail',
    component: MemberDetail,
  },
  {
    path: '/provider/members/:memberId/appointments/:appointmentId',
    name: 'Appointment detail',
    component: MemberAppointmentNotes,
  },
  {
    path: '/provider/members/:memberId/appointments/:appointmentId/evaluation',
    name: 'Evaluation detail',
    component: MemberAppointmentEvaluation,
  },
  {
    path: '/provider/members/:memberId/appointments/:appointmentId/documentation',
    name: 'Evaluation detail',
    component: MemberAppointmentEvaluation,
  },
  {
    path: '/provider/chats',
    name: 'Chats',
    component: Chats,
  },
  {
    path: '/provider/chats/chats-with-members/member/:id',
    name: 'Chats',
    component: Chats,
  },
  {
    path: '/provider/chats/chats-with-providers/provider/:id',
    name: 'Chats',
    component: Chats,
  },
  {
    path: '/provider/chats/chats-with-groups/group/:id',
    name: 'Chats',
    component: Chats,
  },
  {
    path: '/provider/chats/video/:channelUrl',
    name: 'Video Chat',
    component: VideoChats,
  },
  {
    path: '/provider/appointments/:appointmentType/:appointmentId/session',
    name: 'Appointments',
    component: Sessions,
  },
  {
    path: '/provider/appointments/:appointmentType/:appointmentId/session-signoff',
    name: 'Appointments',
    component: SessionSignOff,
  },
  {
    path: '/provider/appointments/:appointmentType/:appointmentId/session-signoff/v2',
    name: 'Appointments',
    component: SessionSignOffV2,
  },
  {
    path: '/provider/appointments/:appointmentType/:appointmentId/session-signoff/v2/:evaluationId',
    name: 'evaluations',
    component: EvaluationDrawer,
  },
  {
    path: '/provider/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/provider/contact-support',
    name: 'Contact support',
    component: Support,
  },
  {
    path: '/provider/profile',
    name: 'Profile',
    component: ProfileV2,
  },
  {
    path: '/provider/alfie/validation/:memberId/:appointmentId/:evaluationId',
    name: 'Appointment Evaluation',
    component: AlfieValidationPlayground,
  },
  {
    path: '/provider/:providerId/general-availability',
    name: 'Provider General Availability',
    component: GeneralAvailability,
  },
  {
    path: '/provider/:providerId/day-availability',
    name: 'Provider Specific Day Availability',
    component: Schedule,
  },
];

export const adminRoutes = [
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: AdminDashboard,
  },
  {
    path: '/admin/collection-templates',
    name: 'Data Collection Templates',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: DataCollectionTemplates,
  },
  {
    path: '/admin/collection-templates/:templateId/:version',
    name: 'Template detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: DctDetail,
  },
  {
    path: '/admin/collection-conversations',
    name: 'Conversations',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: Conversations,
  },
  {
    path: '/admin/collection-conversations/:conversationId/:version',
    name: 'Conversation Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ConversationDetail,
  },
  {
    path: '/admin/collection-evaluations',
    name: 'Evaluations',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: Evaluations,
  },
  {
    path: '/admin/collection-evaluations/:conversationId/:version',
    name: 'Conversation Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ConversationDetail,
  },
  {
    path: '/admin/collection-evaluation-dcts',
    name: 'Evaluation Dcts',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: EvaluationDcts,
  },
  {
    path: '/admin/content-block-library',
    name: 'Content Block Library',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ContentBlockLibrary,
  },
  {
    path: '/admin/collection-evaluation-dcts/:templateId/:version',
    name: 'Template detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: DctDetail,
  },
  {
    path: '/admin/data-management-elements',
    name: 'Profile Element List',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ProfileElementList,
  },
  {
    path: '/admin/data-management-elements/:profileElementId',
    name: 'Profile Element Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ProfileElementDetail,
  },
  {
    path: '/admin/collection-evaluations-states/:evaluationId/:appointmentId',
    name: 'Evaluation States',
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
    ],
    component: EvaluationStates,
  },
  {
    path: '/admin/data-management-tags',
    name: 'Tag List',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: TagsList,
  },
  {
    path: '/admin/data-management-tags/:tagId',
    name: 'Tag Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: TagDetail,
  },
  {
    path: '/admin/data-management-plan-items',
    name: 'Plan Items',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: PlanItems,
  },
  {
    path: '/admin/data-management-plan-items/:planId',
    name: 'Plan Item details',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: PlanItemDetail,
  },
  {
    path: '/admin/data-management-level-of-engagement',
    name: 'Level of Engagement',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: LevelOfEngagement,
  },
  {
    path: '/admin/referral-partners',
    name: 'Referral partners',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ReferralPartners,
  },
  {
    path: '/admin/referral-partners/:partnerId',
    name: 'Referral partner',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ReferralPartner,
  },
  {
    path: '/admin/automations',
    name: 'Automations',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: Automations,
  },
  {
    path: '/admin/automation-detail/:automationId/:active',
    name: 'Automations Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: AutomationDetailPage,
  },
  {
    path: '/admin/reports',
    name: 'Reports',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: Reports,
  },
  {
    path: '/admin/reports/:reportId',
    name: 'Report Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: ReportDetail,
  },
  {
    path: '/admin/priority-types',
    name: 'Priority types',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: PriorityTypes,
  },
  {
    path: '/admin/priority-elements',
    name: 'Priority elements',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: PriorityElements,
  },
  {
    path: '/admin/priority-groupings',
    name: 'Priority groupings',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: PriorityGroupings,
  },
  {
    path: '/admin/members',
    name: 'Members',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: AdminMembers,
  },
  {
    path: '/admin/alfie/validation/:memberId/:appointmentId/:evaluationId',
    name: 'Appointment Evaluation',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: AlfieValidationPlayground,
  },
  {
    path: '/admin/alfie/analysis/:memberId/:appointmentId/:evaluationId',
    name: 'Appointment Evaluation',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: AlfieAnalysisPlayground,
  },
  {
    path: '/admin/alfie/analysis/:appointmentId/:evaluationId',
    name: 'Appointment Analysis',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: AlfieAnalysisPlayground,
  },
  {
    path: '/admin/members/:memberId',
    name: 'Member detail',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: AdminMemberDetail,
  },
  {
    path: '/admin/members/:memberId/appointments/:appointmentId',
    name: 'Appointment Detail',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: MemberAppointmentNotes,
  },
  {
    path: '/admin/members/:memberId/appointments/:appointmentId/evaluation',
    name: 'Evaluation detail',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: MemberAppointmentEvaluation,
  },
  {
    path: '/admin/members/:memberId/appointments/:appointmentId/documentation',
    name: 'Evaluation detail',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: MemberAppointmentEvaluation,
  },
  {
    path: '/admin/providers',
    name: 'Providers',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: ProviderList,
  },
  {
    path: '/admin/providers/:providerId',
    name: 'Providers detail',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: ProviderDetail,
  },
  {
    path: '/admin/appointments/:appointmentType',
    name: 'Appointments',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: AdminAppointments,
  },
  {
    path: '/admin/todos',
    name: 'todos',
    authority: [SUPPORTED_AUTHORITIES.CARE_NAVIGATOR],
    component: Todos,
  },
  {
    path: '/admin/session-types',
    name: 'Session types',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: SessionTypes,
  },
  {
    path: '/admin/master-schedule',
    name: 'Master schedule',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: MasterSchedule,
  },
  {
    path: '/admin/payments',
    name: 'Payments',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: PaymentList,
  },
  {
    path: '/admin/invoices',
    name: 'Invoices',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: InvoiceList,
  },
  {
    path: '/admin/provider-fee',
    name: 'Provider Fee',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: ProviderFee,
  },
  {
    path: '/admin/claims',
    name: 'Claims',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: ClaimList,
  },
  {
    path: '/admin/claims/:claimId',
    name: 'Claim Detail',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: ClaimDetails,
  },
  {
    path: '/admin/chats',
    name: 'Chats',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: Chats,
  },
  {
    path: '/admin/chats/video/:channelUrl',
    name: 'Video Chat',
    authority: [SUPPORTED_AUTHORITIES.CARE_NAVIGATOR, SUPPORTED_AUTHORITIES.ADMIN],
    component: VideoChats,
  },
  {
    path: '/admin/profile',
    name: 'Admin profile',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: Profile,
  },
  {
    path: '/admin/states',
    name: 'State management',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: States,
  },
  {
    path: '/admin/states/:stateId',
    name: 'State Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: StateDetail,
  },
  {
    path: '/admin/states/:stateId/:payerId',
    name: 'Payer Detail',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    component: PayerDetail,
  },
  {
    path: '/admin/notes/:type',
    name: 'Notes',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: NotesList,
  },
  {
    path: '/admin/chats',
    name: 'Chats',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: Chats,
  },
  {
    path: '/admin/notifications',
    name: 'Notifications',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: AdminNotifications,
  },
  {
    path: '/admin/members/dashboard/:memberId/:referralPartnerId',
    name: 'Dashboard',
    authority: [
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.ADMIN,
    ],
    component: ReferralDashboard,
  },
];
