import React, { useEffect, useRef, useState } from 'react';
import { Box, Rating } from '@mui/material';
import cx from 'clsx';
import {
  btnType,
  Button,
  colors,
  fontWeight,
  Heading,
  headingLevel,
  Icons,
  Text,
  Textarea,
  textLevel,
} from '../../../../packages';
import { useStyles } from './InPersonSession.styles';
import { MultiSelectOptions } from './MultiSelectOptions';
import { SingleSelectOptions } from './SingleSelectOptions';
import useEvaluationHead from '../../../../hooks/useEvaluationHead';

export const RightSection = ({
  activeBox,
  setActiveBox,
  currentBlock,
  setInputFocused,
  selectedIndex,
  rightTrigger,
  leftTrigger,
}) => {
  const classes = useStyles();
  const [flagActive, setFlagActive] = useState(false);
  const bodyRef = useRef(null);
  const { updateValues } = useEvaluationHead();

  const handleProviderNarrative = () => {
    setFlagActive(!flagActive);
  };

  const dispatchUserResponse = value => {
    updateValues({ id: currentBlock.cbId, value });
  };

  const handleTextInputChange = e => {
    const newValue = e.target.value.trimStart();
    dispatchUserResponse(newValue);
  };

  const handleRatingInputChange = e => {
    const newValue = e.target.value;
    dispatchUserResponse(newValue);
  };

  const handleNarrative = e => {
    const newValue = e.target.value.trimStart();
    updateValues({ id: currentBlock.cbId, narrative: newValue });
  };

  const handleSelectChange = selectedValue => {
    dispatchUserResponse(selectedValue);
  };

  const getRatingValue = () => {
    if (currentBlock && currentBlock.userResponse && currentBlock.userResponse?.userResponseList.length > 0) {
      return parseInt(currentBlock.userResponse.userResponseList[0], 10);
    }
    return null;
  };

  const textInputValue =
    currentBlock &&
    currentBlock.cbType === 'text-input' &&
    currentBlock.userResponse &&
    currentBlock.userResponse.userResponseList.length > 0 &&
    currentBlock.userResponse.userResponseList[0];

  const handleKeyDown = e => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (currentBlock && currentBlock.cbType !== 'single-select') {
      if (currentBlock.cbType === 'rating-scale') {
        if (currentBlock.userResponse && currentBlock.userResponse.userResponseList.length > 0) {
          const ratingValue = getRatingValue();
          if (ratingValue > 0) {
            dispatchUserResponse(ratingValue - 1);
          }
        } else {
          dispatchUserResponse(1);
        }
      } else if (currentBlock.choices && currentBlock.choices.length > 0 && selectedIndex >= 0) {
        const value = currentBlock.choices[selectedIndex].choice;
        if (
          currentBlock.userResponse &&
          currentBlock.userResponse.userResponseList &&
          currentBlock.userResponse.userResponseList.length > 0 &&
          currentBlock.userResponse.userResponseList.includes(value)
        ) {
          dispatchUserResponse(value);
        }
      }
    }
  }, [leftTrigger]);

  useEffect(() => {
    if (currentBlock) {
      if (currentBlock.cbType === 'rating-scale') {
        if (currentBlock.userResponse && currentBlock.userResponse.userResponseList.length > 0) {
          const ratingValue = getRatingValue();
          if (ratingValue < currentBlock.ratingScale?.values?.length) {
            dispatchUserResponse(ratingValue + 1);
          }
        } else {
          dispatchUserResponse(1);
        }
      } else if (currentBlock.choices && currentBlock.choices.length > 0 && selectedIndex >= 0) {
        const value = currentBlock.choices[selectedIndex].choice;
        if (
          !currentBlock.userResponse ||
          !currentBlock.userResponse.userResponseList ||
          currentBlock.userResponse.userResponseList.length === 0 ||
          !currentBlock.userResponse.userResponseList.includes(value)
        ) {
          dispatchUserResponse(value);
        }
      }
    }
  }, [rightTrigger]);

  return (
    <Box
      className={cx({
        [classes.questionBoxRoot]: activeBox,
        [classes.questionBoxRootInactive]: !activeBox,
      })}
    >
      <Box className={classes.rightSectionHeaderRoot} onKeyDown={handleKeyDown}>
        <Box className={classes.rightSectionHeaderSubRoot}>
          <Box
            className={cx({
              [classes.toggleIconWrapper]: !activeBox,
            })}
            onClick={() => {
              setActiveBox(!activeBox);
              setFlagActive(false);
            }}
          >
            <Button variant={btnType.TEXT} className={classes.sideBarToggler}>
              <Icons
                className={cx({
                  [classes.togglerIcon]: !activeBox,
                  [classes.togglerIconRight]: activeBox,
                })}
                glyph="caret-left"
                color={colors.neutral700}
              />
            </Button>
          </Box>
          <Box className={activeBox ? classes.rightSectionTitleContainer : classes.hiddenContainer}>
            <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD} className={classes.heading}>
              {currentBlock.cbType === 'multi-select' || currentBlock.cbType === 'single-select'
                ? 'Answer options'
                : currentBlock.cbType === 'text-input'
                ? 'Answer'
                : currentBlock.cbType === 'rating-Scale' || currentBlock.cbType === 'rating-scale'
                ? 'Rating'
                : currentBlock.cbType === 'education'
                ? 'Education'
                : 'Exercise'}
            </Heading>
            {(currentBlock.cbType === 'multi-select' || currentBlock.cbType === 'single-select') && (
              <Text className={classes.rightSectionHeaderTextRight}>{currentBlock.choices.length}</Text>
            )}
          </Box>
        </Box>
        <Box className={activeBox ? classes.answerContainer : ''} ref={bodyRef}>
          {activeBox ? (
            currentBlock.cbType === 'multi-select' ? (
              currentBlock.choices.map((item: any, index) => (
                <MultiSelectOptions
                  answerTitle={item.choice}
                  value={item.choice}
                  key={index}
                  currentBlock={currentBlock}
                  onChange={handleSelectChange}
                  scrollRef={bodyRef}
                  focusedChoice={index === selectedIndex}
                  inPersonSessionFlow
                />
              ))
            ) : currentBlock.cbType === 'single-select' ? (
              <SingleSelectOptions
                options={currentBlock.choices.map((item: any) => ({
                  label: item.choice,
                  value: item.choice,
                }))}
                currentBlock={currentBlock}
                onChange={handleSelectChange}
                scrollRef={bodyRef}
                selectedIndex={selectedIndex}
              />
            ) : currentBlock.cbType === 'text-input' ? (
              <Box className={classes.textAreaBox}>
                <Textarea
                  placeholder="Enter your answer"
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  onChange={handleTextInputChange}
                  value={textInputValue || ''}
                />
              </Box>
            ) : currentBlock.cbType === 'rating-scale' ? (
              <Box className={classes.ratingScaleBox}>
                <Rating
                  size="medium"
                  name="rating"
                  value={getRatingValue()}
                  onChange={handleRatingInputChange}
                  max={currentBlock.ratingScale?.values?.length}
                />
              </Box>
            ) : null
          ) : null}
        </Box>
      </Box>
      <Box
        className={cx({
          [classes.questionBoxFooterContainer]: activeBox,
          [classes.hiddenContainer]: !activeBox,
        })}
      >
        {flagActive && (
          <Box className={classes.textAreaBox}>
            <Textarea
              placeholder="Provider narrative"
              minRows={1}
              maxRows={2}
              value={currentBlock?.userResponse?.providerNarrative || ''}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={handleNarrative}
            />
          </Box>
        )}
        {activeBox && (
          <Box className={classes.activeBoxFlagIcon}>
            <Icons
              glyph="flag-outlined"
              color={colors.primary500}
              onClick={handleProviderNarrative}
              className={classes.pointerCursor}
            />
          </Box>
        )}
        <Box className={classes.answerBoxFooter}>
          <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.footerText}>
            Navigate
          </Text>
          <Box className={classes.footerBtn}>
            <Icons glyph="arrow-up" color={colors.neutral700} />
          </Box>
          <Text className={classes.footerText2}>or</Text>
          <Box className={classes.footerBtn}>
            <Icons glyph="arrow-down" color={colors.neutral700} />
          </Box>
        </Box>
      </Box>
      {!activeBox && (
        <Box className={classes.countOptions}>
          {(currentBlock.cbType === 'multi-select' || currentBlock.cbType === 'single-select') && (
            <Text className={classes.verticalText}>{currentBlock.choices.length} options</Text>
          )}
        </Box>
      )}
      {!activeBox && (
        <Box className={classes.inActiveRightSectionFooterIcon}>
          <Icons
            glyph="flag-outlined"
            color={colors.primary500}
            onClick={() => {
              setActiveBox(true);
              handleProviderNarrative();
            }}
            className={classes.pointerCursor}
          />
        </Box>
      )}
    </Box>
  );
};
