import React, { FC, useEffect, useState } from 'react';
import { Text } from '../../../../packages/ui/atoms/typography';
import { btnSize, btnType, Button } from '../../../../packages/ui/atoms/button';
import { Icons } from '../../../../packages/icons';
import { Box } from '@mui/material';
import { useStyles } from './InPersonSession.styles';
import { getAppointmentEvaluationProgress } from '../../../../services/conversation/conversation.service';

export const CompletedEvaluation = ({
  appointmentId,
  onSelectEvaluation,
  onAddEvaluation,
  updateEvaluationProgress,
}) => {
  const [evaluationsProgressList, setEvaluationsProgressList] = useState([]);
  const getEvaluationProgressList = () => {
    getAppointmentEvaluationProgress({ appointmentId })
      .then(evaluationProgressDetailList => {
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          setEvaluationsProgressList(responseList);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };

  useEffect(() => {
    getEvaluationProgressList();
    updateEvaluationProgress();
  }, []);

  const classes = useStyles();
  return (
    <Box className={classes.completedRoot}>
      <Box className={classes.completedContainer}>
        <Box className={classes.alfieBox}>
          <Icons glyph="alfie-outlined" className={classes.alfie} />
        </Box>
        <Text className={classes.headerText}>Evaluation Completed</Text>
        <Box className={classes.remainingEvalsContainer}>
          {evaluationsProgressList.map((evaluationProgressItem, index) => {
            return (
              <Box className={classes.remainingEvalItem} key={`${index}-remaining`}>
                <Box className={classes.remainingEvalDescBox}>
                  <Text className={classes.remainingEvalTitle}>{evaluationProgressItem.name}</Text>
                  <Text className={classes.remainingEvalText}>{evaluationProgressItem.description}</Text>
                </Box>
                <Button
                  variant={btnType.OUTLINE}
                  size={btnSize.SMALL}
                  className={classes.startEvalBtn}
                  onClick={() => {
                    onSelectEvaluation(evaluationProgressItem);
                  }}
                >
                  {evaluationProgressItem.status === 'IN_PROGRESS' ||
                  evaluationProgressItem.status === 'COMPLETED'
                    ? 'Reopen Evaluation'
                    : 'Start Evaluation'}
                </Button>
              </Box>
            );
          })}
        </Box>
        <Box>
          <Button size={btnSize.SMALL} onClick={onAddEvaluation} className={classes.addEvalBtn}>
            Add Evaluation
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
